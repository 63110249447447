import { InputLabel, Skeleton, Typography, useTheme } from "@mui/material";
import { CardUI } from "components/card";
import { ITransactionDetails } from "hooks/transactionDetailsHook";
import moment from "moment";
import { currencyFormatter } from "utils/currency_fromatter";

type DetailsCardProps = {
  isLoading: boolean;
  details?: ITransactionDetails | null;
};

const DetailsCard = ({ isLoading, details }: DetailsCardProps) => {
  const theme = useTheme();

  return (
    <CardUI className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        {/* {params} */}
        <div>
          <InputLabel>Timestamp</InputLabel>
          <Typography variant="subtitle1">
            {isLoading ? (
              <Skeleton />
            ) : (
              moment.utc(details?.createdAt).format("MM-DD-YYYY HH : mm : ss")
            )}
          </Typography>
        </div>
        <div>
          <InputLabel>Amount</InputLabel>
          <Typography variant="subtitle1">
            {isLoading ? (
              <Skeleton />
            ) : (
              currencyFormatter({ value: details?.amount, decimalPlaces: 2 }) +
              " " +
              details?.currencyCode
            )}
          </Typography>
        </div>
        <div>
          <InputLabel>TxID</InputLabel>
          <Typography variant="subtitle1">
            {isLoading ? <Skeleton /> : details?.txid}
          </Typography>
        </div>
        <div>
          <InputLabel>Username</InputLabel>
          <Typography variant="subtitle1">
            {isLoading ? <Skeleton /> : details?.user.username}
          </Typography>
        </div>
      </div>

      <div>
        <InputLabel>Payment Method</InputLabel>
        <Typography variant="subtitle1">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {details?.paymentMethod.type === "crypto" && (
                <>
                  <span style={{ fontSize: "20px" }}>
                    {details?.paymentMethod.currency.toUpperCase()}
                  </span>
                  <span>
                    {" "}
                    /{details?.paymentMethod?.cryptoNetwork?.toUpperCase()}
                  </span>
                  <br />
                  <span style={{ color: theme.palette.primary.main }}>
                    Wallet Address:
                  </span>
                  <span> {details.address}</span>
                </>
              )}
              {details?.paymentMethod.type === "fiat" && (
                <>
                  <span style={{ color: theme.palette.primary.main }}>
                    {details?.paymentMethod.title}
                  </span>{" "}
                  - {details?.paymentMethod.description}
                </>
              )}
            </>
          )}
        </Typography>
      </div>
    </CardUI>
  );
};

export default DetailsCard;
