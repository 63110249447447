import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import _ from "lodash";
import { useGameReportQuery } from "queries/agent/game_reports_query";
import queryString from "query-string";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";

type Props = {};

const GameReportsPage = (props: Props) => {
  const [query, setQuery] = useState<string>("");

  const [search, setSearch] = useState("");

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    else setQuery("");

    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search]);

  const { data, isLoading, isError, error } = useGameReportQuery({
    query: query,
  });

  const hasNoData = data && data?.docs.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Games Reports">
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Games</TableCell>
              <TableCell align="right">Gross Turnover</TableCell>
              <TableCell align="right">Active Players</TableCell>
              <TableCell align="right">PT Win/Loss</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={4} rows={10} />}
            {data && (
              <>
                {data.docs.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        {item.grossTurnOver.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">{item.userCount}</TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: item.pnl < 0 ? "error.main" : "success.main",
                        }}
                      >
                        {item.pnl > 0 && "+"}
                        {item.pnl.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  sx={{
                    bgcolor: "background.default",
                    td: { fontWeight: 600 },
                  }}
                >
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {data.totalTurnOver.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">{data.totalUserCount}</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: data.totalPnl < 0 ? "error.main" : "success.main",
                    }}
                  >
                    {data.totalPnl > 0 && "+"}
                    {data.totalPnl.toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            )}
            {hasNoData && <NoTransactions colSpan={4} />}
            {isError && <TableError colSpan={4} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      /> */}
    </>
  );
};

export default GameReportsPage;
