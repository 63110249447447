import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_USER } from "constants/api_endpoints";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface IWallet {
  name: string;
  currencyCode: string;
  total: string;
  freeze: string;
  available: number;
  equivalentAmount: string;
  scopes: {
    withdraw: {
      enabled: boolean;
    };
    deposit: {
      enabled: boolean;
    };
    swap: {
      enabled: boolean;
    };
  };
}

export const useWalletInformation = () => {
  return useQuery<IWallet[], Error>("fetch-wallet-information", async () => {
    try {
      const resp = await privateRequest.get("wallet/information");
      return resp?.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};

export interface IWalletBalance {
  _id: string;
  currencyCode: string;
  total: number;
  freeze: number;
  available: number;
  equivalentAmount: number;
}

export const useWalletBalance = (currencyCode: string) => {
  return useQuery<IWalletBalance, Error>(
    ["fetch-wallet-information", currencyCode],
    async () => {
      try {
        const resp = await privateRequest.get(
          "wallet/information?currencyCode=" + currencyCode
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!currencyCode,
      retry: 0,
    }
  );
};

interface Issuer {
  _id: string;
  name: string;
  username: string;
  photo: string;
  rank: string;
  email: string;
}

export type TransactionStatusType = "pending" | "confirmed" | "declined";
export type TransactionType = "debit" | "credit";
export interface ITransaction {
  _id: string;
  txid: string;
  hash: string;
  address: string;
  currencyCode: string;
  pairUser: null;
  user: string;
  issuer: Issuer;
  platform: string;
  note: string;
  paymentMethod: {
    description: string;
    timeWindow: number;
    title: string;
    type: PaymentMethodType;
    currency: string;
  };
  paymentDetails: string;
  transactionMedia: string;
  criteria: string;
  flag: string;
  transactionType: TransactionType;
  amount: number;
  equivalentAmount: number;
  status: TransactionStatusType;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  reason: string;
  id: string;
}

interface ITransactionResp {
  docs: ITransaction[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
}

export const useTransactionHistory = (query: string, page?: number) => {
  return useInfiniteQuery<ITransactionResp, Error>(
    ["fetch-transaction-history", query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `wallet/transactionHistory?page=${page ?? pageParam}` + query ?? ""
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!query,
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

export interface WithdrawRequestCryptoPayload {
  currencyCode: string;
  amount: string;
  address: string;
  paymentMethod: string;
}
export interface WithdrawRequestFiatPayload {
  currencyCode: string;
  amount: string;
  paymentDetails: string;
  paymentMethod: string;
}

interface WithdrawRequestResp {
  message: string;
}

export const useWithdrawRequestMutation = (withdrway_history_query: string) => {
  return useMutation<
    WithdrawRequestResp,
    Error,
    WithdrawRequestFiatPayload | WithdrawRequestCryptoPayload
  >(
    async (payload) => {
      try {
        const resp = await privateRequest.post("wallet/withdraw", payload);
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetch-transaction-history");
        queryClient.invalidateQueries("fetch-wallet-information");
      },
    }
  );
};

export interface IPaymentMethod {
  title: null | string;
  slug: string;
  description: string;
  user: string;
  type: string;
  timeWindow: number;
  currency: string;
  cryptoNetwork: null | string;
  status: string;
  _id: string;
}
export interface IPaymentMethodList {
  name: string;
  methods: IPaymentMethod[];
}

export const useAvailablePaymentMethods = (type: PaymentMethodType) => {
  return useQuery<IPaymentMethodList[], Error>(
    ["fetch-availablePaymentMethods", type],
    async () => {
      try {
        const resp = await privateRequest.get(
          "wallet/availablePaymentMethods?type=" + type
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!type,
    }
  );
};

interface IReleaseWithdrawalPaylod {
  id: string;
}

interface IReleaseWithdrawalResp {
  status: boolean;
  message: string;
}

export const useReleaseWithdrawalMutation = () => {
  return useMutation<IReleaseWithdrawalResp, Error, IReleaseWithdrawalPaylod>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_USER.wallet.releaseWithdrawal + paylaod.id
          // paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
      },
    }
  );
};
interface IDisputeWithdrawalPaylod {
  id: string;
}

interface IDisputeWithdrawalResp {
  status: boolean;
  message: string;
}

export const useDisputeWithdrawalMutation = () => {
  return useMutation<IDisputeWithdrawalResp, Error, IDisputeWithdrawalPaylod>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_USER.wallet.disputeWithdrawal + paylaod.id
          // paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
      },
    }
  );
};
