import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import {
  DownlineAgent,
  useUnblockAgentMutation,
} from "queries/agent/agent_management_query";
import React from "react";

export default NiceModal.create(({ agent }: { agent: DownlineAgent }) => {
  const modal = useModal();

  const { isLoading, mutateAsync } = useUnblockAgentMutation();

  const onConfirm = async () => {
    await mutateAsync({ userId: agent._id });
    modal.remove();
  };

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle color="success.main">
        Do you want to unblock this agent?
      </DialogTitle>

      <DialogActions>
        <Button
          disabled={isLoading}
          variant="contained"
          color="warning"
          onClick={modal.remove}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onConfirm}
          variant="contained"
          color="success"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
