import SignupForm from "components/form/signupForm";
import AuthLayout from "components/layout/authLayout";

interface Props {}

const Register = (props: Props) => {
  return (
    <AuthLayout title="Sign up to KryptoBet">
      <SignupForm />
    </AuthLayout>
  );
};

export default Register;
