import { Pagination, PaginationItem } from "@mui/material";
import React, { FC } from "react";

type TablePaginationProps = {
  count: number | undefined;
  page: number | undefined;
  onChange?: ((page: number) => void) | undefined;
};

const TablePagination: FC<TablePaginationProps> = ({
  count,
  page,
  onChange,
}) => {
  return (
    <Pagination
      variant="outlined"
      sx={{ marginY: "8px", display: "flex", justifyContent: "end" }}
      count={count}
      page={page}
      onChange={(event, page) => {
        if (onChange) onChange(page);
      }}
      renderItem={(item) => <PaginationItem {...item} />}
    />
  );
};

export default TablePagination;
