import { Card, Grow, Skeleton, Typography } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { FC } from "react";
import { Link } from "react-router-dom";

type OverviewGcPurchaseRequestProps = {
  isLoading?: boolean;
  requests: number;
};

const OverviewGcPurchaseRequest: FC<OverviewGcPurchaseRequestProps> = ({
  isLoading,
  requests,
}) => {
  return (
    <Grow in>
      <Card
        elevation={10}
        className="max-w-sm md:max-w-full flex flex-col gap-4 !bg-gradient-to-br !from-[#CE921F] !to-[#17661F]"
        sx={{ color: "white", p: 3 }}
      >
        <div className="flex justify-between items-center">
          <Typography variant="subtitle1">GC Purchase Requests</Typography>
          <Link to={"/agent/" + RouteLinks.GC_MANAGEMENT.link}>
            Transaction History
          </Link>
        </div>

        {isLoading ? (
          <Typography variant="h4">
            <Skeleton variant="text" />
          </Typography>
        ) : (
          <Typography variant="h4" fontWeight={500}>
            {requests}
          </Typography>
        )}
      </Card>
    </Grow>
  );
};

export default OverviewGcPurchaseRequest;
