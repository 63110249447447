import { Avatar, Chip } from "@mui/material";
import { RankSymbol } from "interfaces/agent/rank";
import React, { FC } from "react";
import { iconByRank } from "utils/rank_utils";

type RankChipProps = {
  rank?: RankSymbol | undefined;
};

const RankChip: FC<RankChipProps> = ({ rank }) => {
  return (
    <Chip
      avatar={
        <Avatar>
          <img src={iconByRank(rank)} alt={rank} />
        </Avatar>
      }
      label={
        rank === null || rank === undefined ? "PLAYER" : rank.toUpperCase()
      }
    />
  );
};

export default RankChip;
