import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import logo from "assets/icons/logo.png";
import { AuthContext } from "context/AuthContext";
import { useDashboardAnalytics } from "queries/dashboard";
import { useLogoutGamePlayer } from "queries/games";
import { useWalletInformation } from "queries/wallets";
import { useContext } from "react";
import { VscClose } from "react-icons/vsc";
import { gameCurrency } from "utils/urls";

type Props = {
  gameUrl: string;
  navigate: Function;
};

export default NiceModal.create(({ gameUrl, navigate }: Props) => {
  const modal = useModal();
  const { user } = useContext(AuthContext);

  const { refetch: refetchDashboardAnalytics } = useDashboardAnalytics();
  const { refetch: refetchWalletInformation } = useWalletInformation();
  const useLogoutGamePlayerMutation = useLogoutGamePlayer();

  const closeTheGame = () => {
    refetchDashboardAnalytics();
    refetchWalletInformation();
    useLogoutGamePlayerMutation.mutate({
      currency: gameCurrency,
      user_id: user?.data?._id ?? "",
      method: "logoutPlayer",
      user_username: user?.data?.gamingUsername ?? "",
      user_password: user?.data?.gamingPassword ?? "",
    });
    modal.remove();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={closeTheGame}
      TransitionProps={{
        onExited: closeTheGame,
      }}
      fullScreen
    >
      <AppBar sx={{ position: "relative", background: "#233", p: 0 }}>
        <Toolbar>
          <img
            className="cursor-pointer"
            onClick={() => {
              closeTheGame();
              navigate("/");
            }}
            width={150}
            src={logo}
            alt="logo"
          />
          <Typography sx={{ mx: 3, color: "#fff" }} fontWeight={600}>
            1 GC = {""}
            {user?.data?.country === "PH"
              ? user?.data?.usdToPhp + " PHP"
              : "1 $"}
          </Typography>
          <div className="ml-auto">
            <IconButton
              edge="start"
              color="primary"
              onClick={closeTheGame}
              aria-label="close"
              sx={{ background: "#444" }}
            >
              <VscClose className="!text-white" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <iframe
        style={{ width: "100%", height: "100%" }}
        src={gameUrl}
        title="game"
      />
    </Dialog>
  );
});
