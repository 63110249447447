import { CardUI } from "components/card";
import { BsPlusSquareDotted } from "react-icons/bs";
import { Button } from "@mui/material";
import { FiTrash2 } from "react-icons/fi";
import { HiPencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { RouteLinks } from "../../constants/routeLinks";
import defaultDateFormat from "../../utils/date_formatter";

type TruncateProps = {
  data: IRToolListResponse
  onDelete: (id: string) => void
}

export const ToolsCard = (props: TruncateProps) => {
  let navigate = useNavigate()

  return (
    <CardUI className="group min-h-[300px] overflow-hidden relative">
      <div className="rounded-md overflow-hidden h-[200px] mb-3">
        <img
          src={props.data?.banner}
          alt=""
          className="w-full min-h-[200px]"
        />
      </div>
      <p className="text-md font-bold">
        {props.data?.title && props.data?.title.length > 30 ? props.data?.title.slice(0, 30) + "..." : props.data?.title}
      </p>
      <small className="mb-2">Posted on: {defaultDateFormat(props.data.createdAt)}</small>
      <p className="font-normal">
        {props.data?.description && props.data?.description.length > 90 ? props.data?.description.slice(0, 90) + "..." : props.data?.description}
      </p>

      {/* Custom overlay */}
      <div className="absolute -bottom-[100%] group-hover:bottom-0 left-0 w-full h-full flex flex-col justify-center items-center px-6 bg-[#000000c7] transition-all duration-220">
        <div className="w-full mb-3">
          <Button
            type="button"
            className="w-full !text-white !bg-primary"
            onClick={() => navigate(`/agent/tools/show/${props.data?._id}`, { replace: true })}
          >View</Button>
        </div>
        <div className="w-full mb-3">
          <Button
            type="button"
            className="w-full !text-white !bg-primary"
            onClick={() => navigate(`/agent/tools/edit/${props.data?._id}`, { replace: true })}
          > <HiPencil size={20} />
            Edit</Button>
        </div>
        <Button
          type="button"
          className="w-full !text-white !bg-red-500"
          onClick={() => props.onDelete(props.data?._id)}
        >
          <FiTrash2 size={20} />
          Delete</Button>
      </div>
    </CardUI>
  )
}


export const AddCard = () => {
  let navigate = useNavigate()

  return (
    <CardUI
      className="min-h-[300px] flex flex-col justify-center items-center cursor-pointer"
      onClick={() => navigate(`/agent/${RouteLinks.TOOLS_CREATE.link}`, { replace: true })}
    >
      <BsPlusSquareDotted
        size={40}
        className="mb-4 mx-auto text-primary"
      />
      <p className="font-medium text-primary">Add New Tools</p>
    </CardUI>
  )
}