import {
  Button,
  Chip,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionDescriptionCell from "components/table/TransactionDescriptionCell";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { RouteGroups, RouteLinks } from "constants/routeLinks";
import { TransactionStatusType, useTransactionHistory } from "queries/wallets";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getColorSchema } from "utils";
import defaultDateFormat from "utils/date_formatter";

interface IState {
  status: TransactionStatusType | "";
  criteria: string;
}

const UserWithdrawalsHistory: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [state, setState] = useState<IState>({
    criteria: "withdraw",
    status: "pending",
  });

  const changeHandler = ({ target: { value, name } }: any) => {
    setState({ ...state, [name]: value });
  };

  const updateQuery = () => {
    const parsed = queryString.parse(query);
    if (state.status) {
      parsed.status = state.status;
    } else {
      delete parsed.status;
    }

    parsed.criteria = state.criteria;
    const uri = queryString.stringify(parsed);
    if (uri) {
      return setQuery("&" + uri);
    }
    setQuery("");
  };

  useEffect(() => {
    updateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const { data, isLoading, error, isError } = useTransactionHistory(
    query,
    page
  );
  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Withdrawal History">
        <Tabs
          value={state.status}
          onChange={(event, value) =>
            changeHandler({
              target: { name: "status", value: value },
            })
          }
          aria-label="icon tabs example"
        >
          <Tab value="pending" label="Pending" aria-label="Pending" />
          <Tab value="inEscrow" label="In Progress" aria-label="In Progress" />
          <Tab value="declined" label="Declined" aria-label="Declined" />
          <Tab value="disputed" label="Disputed" aria-label="Disputed" />
          <Tab value="confirmed" label="Confirmed" aria-label="Confirmed" />
        </Tabs>
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Criteria</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...Array(8)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={300} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                </TableRow>
              ))}
            {data?.pages?.map((page) =>
              page?.docs.map((transaction) => (
                <TableRow key={transaction?._id}>
                  <TableCell>
                    {defaultDateFormat(transaction?.createdAt)}
                  </TableCell>
                  <TableCell>{transaction?.txid}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.criteria?.toUpperCase()}
                      color={
                        transaction?.criteria?.toUpperCase() === "WITHDRAW"
                          ? "secondary"
                          : "info"
                      }
                    />
                  </TableCell>
                  <TransactionValueCell
                    type={transaction?.transactionType}
                    data={transaction}
                  />
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.status?.toUpperCase()}
                      color={getColorSchema(transaction?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <TransactionDescriptionCell transaction={transaction} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      sx={{ borderRadius: 50 }}
                      size="small"
                      color="primary"
                      disabled={transaction?.status === "declined"}
                      component={Link}
                      to={`/${RouteGroups.USER.link}/${RouteLinks.TRANSACTION_HISTORY_DETAILS.link}/${transaction?._id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}

            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasNoData && (
        <TablePagination
          count={data?.pages[0].totalPages}
          page={page}
          onChange={(newPage) => setPage(newPage)}
        />
      )}
    </>
  );
};

export default UserWithdrawalsHistory;
