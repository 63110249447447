import AuthLayout from "components/layout/authLayout";
import { Outlet } from "react-router-dom";

type Props = {};

const ResetPassword = (props: Props) => {
  return (
    <AuthLayout title="Reset Password">
      <Outlet />
    </AuthLayout>
  );
};

export default ResetPassword;
