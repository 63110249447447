import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation } from "react-router-dom";
import Routes from "routes";

function App() {
  let timeout: NodeJS.Timeout | null = null;
  const { logout } = useContext(AuthContext);
  const location = useLocation();

  // scroll to top at change route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const goBackToHome = () => {
    // code to reset the application
    logout();
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      goBackToHome();
    }, 1000 * 60 * 5);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };
  useEffect(() => {
    // ingore this inactivity-logout feature for development mode
    if (process.env.NODE_ENV === "development") return;
    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener("mousemove", onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", onMouseMove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes />
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
