import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { IRegisterPayload, useRegister } from "queries/auth";
import { useCountry } from "queries/country";
import queryString from "query-string";
import { FC, useContext, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { landingAppUrl } from "utils/urls";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  countryCode: yup.string().required(),
  username: yup.string().required(),
  name: yup.string().required(),
  sponsor: yup.string().required(),
});

interface Props {}

interface IParsedSearch {
  ref?: string;
}

interface ISelectCountry {
  value: string;
  label: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#f8f8f8",
    border: 0,
    boxShadow: "none",
    borderRadius: "8px",
    width: "100%",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "48px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
  }),
};

const SignupForm: FC<Props> = () => {
  const { search } = useLocation();
  const { loginFinish } = useContext(AuthContext);

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const parsed: IParsedSearch = queryString.parse(search);

  const { data: countries, isLoading: isLoadingCountries } = useCountry();
  const countryList: ISelectCountry[] = useMemo(() => {
    const data =
      countries &&
      countries.reduce((t: ISelectCountry[], c) => {
        t.push({ label: c.name, value: c.alpha2 });
        return t;
      }, []);
    return data || [];
  }, [countries]);

  const { handleSubmit, control } = useForm<IRegisterPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      sponsor: parsed.ref ?? "",
      confirm_password: "",
      password: "",
      countryCode: "",
      email: "",
      name: "",
      username: "",
    },
  });

  const useRegisterMudation = useRegister({
    onSuccess: loginFinish,
  });

  const [showPass, setShowPass] = useState(false);

  const onSubmit: SubmitHandler<IRegisterPayload> = (
    data: IRegisterPayload
  ) => {
    if (!acceptTerms) {
      return toast.error("Please accept the terms and conditions");
    }
    delete data.confirm_password;
    useRegisterMudation.mutate(data);
  };

  return (
    <form
      className="flex flex-col gap-3 lg:gap-6 w-full select-none"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            fullWidth
            // label="Name"
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            placeholder="Name"
            {...field}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="username"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            fullWidth
            // label="Username"
            placeholder="Username"
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            {...field}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            fullWidth
            type="email"
            // label="Email"
            placeholder="Email"
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            {...field}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <div className="relative z-10">
        <Controller
          name="countryCode"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <Select
              placeholder="Select Country"
              isLoading={isLoadingCountries}
              styles={customStyles}
              instanceId="country"
              options={countryList}
              onChange={(e) => field.onChange({ target: { value: e?.value } })}
            />
          )}
        />
      </div>
      <Controller
        name="sponsor"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            fullWidth
            // label="Sponsor"
            placeholder="Sponsor"
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            {...field}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            type={showPass ? "text" : "password"}
            fullWidth
            placeholder="Password"
            {...field}
            error={invalid}
            helperText={error?.message}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "gray" }}>
                  {showPass ? (
                    <AiFillEye
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Controller
        name="confirm_password"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            type={showPass ? "text" : "password"}
            fullWidth
            placeholder="Confirm Password"
            {...field}
            error={invalid}
            helperText={error?.message}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "gray" }}>
                  {showPass ? (
                    <AiFillEye
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <div className="flex items-center justify-between">
        <FormControlLabel
          className="mt-4"
          control={
            <Checkbox
              name="is_accept_terms"
              onChange={(e) => setAcceptTerms(e.target.checked)}
              checked={acceptTerms}
            />
          }
          sx={{ span: { fontSize: "14px", color: "#fff", fontWeight: 600 } }}
          label={
            <>
              I agree to{" "}
              <a
                className="text-primary"
                href={`${landingAppUrl}/terms-and-conditions`}
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              ,{" "}
              <a
                className="text-primary"
                href={`${landingAppUrl}/privacy-policy`}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              ,{" "}
              <a
                className="text-primary"
                href={`${landingAppUrl}/income-disclaimer`}
                target="_blank"
                rel="noreferrer"
              >
                Income Disclaimer
              </a>{" "}
              &{" "}
              <a
                className="text-primary"
                href={`${landingAppUrl}/general-disclaimer`}
                target="_blank"
                rel="noreferrer"
              >
                General Disclaimer
              </a>
            </>
          }
        />
      </div>

      <LoadingButton
        variant="contained"
        disableElevation
        color="primary"
        fullWidth
        className="!bg-primary"
        type="submit"
        loading={useRegisterMudation.isLoading}
      >
        Sign up
      </LoadingButton>
      {useRegisterMudation.error && (
        <Alert severity="error">
          {useRegisterMudation.error?.message ?? "Something went wrong!"}
        </Alert>
      )}

      <p className="text-center text-white font-bold text-sm">
        Already have an account?{" "}
        <Link className="text-primary" to={RouteLinks.LOGIN.link}>
          Sign in
        </Link>
      </p>
    </form>
  );
};

export default SignupForm;
