import { Theme, useMediaQuery } from "@mui/material";
import { PlayGameCategoryType } from "constants/routeLinks";
import GameSection from "./components/gameSection";

type Props = {
  type: PlayGameCategoryType | "";
  title: string;
  isHot?: boolean;
};

const PlayGamePage = ({ type, title, isHot }: Props) => {
  const isMobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <GameSection
      query={`&type=${type}${
        isMobileScreen ? "&agent=mobile" : "&agent=desktop"
      }${isHot ? "&isHot=yes" : ""}`}
      title={title}
    />
  );
};

export default PlayGamePage;
