import { useSockets } from "context/socketContext";
import { useGetMessages } from "queries/chat";
import { useCallback, useEffect, useState } from "react";

type ITransactionStatus =
  | "pending"
  | "inEscrow"
  | "declined"
  | "disputed"
  | "confirmed"
  | "paid";

type IExternalTransactionStatus = "unpaid" | "paid";
export interface ITransactionDetails {
  txid: string;
  hash: any;
  address: any;
  paymentDetails: string;
  currencyCode: string;
  pairUser: any;
  user: {
    name: string;
    username: string;
    photo: string;
    role: string;
    _id: string;
    email: string;
  };
  issuer: {
    name: string;
    username: string;
    photo: string;
    role: string;
    _id: string;
    email: string;
  };
  platform: string;
  transactableId: any;
  note: string;
  transactionMedia: string;
  criteria: string;
  flag: string;
  transactionType: string;
  amount: number;
  equivalentAmount: number;
  paymentMethod: {
    title: string;
    slug: string;
    description: string;
    user: string;
    type: string;
    timeWindow: number;
    fiatCurrency: any;
    cryptoCurrency: any;
    currency: string;
    cryptoNetwork: any;
    status: string;
    _id: string;
    __v: number;
  };
  isRolledBack: boolean;
  externalTransactionStatus: IExternalTransactionStatus;
  externalTransactionPaidAt: any;
  status: ITransactionStatus;
  _id: string;
  createdAt: string;
  withdrawApprovedAt: string;
  updatedAt: string;
  __v: number;
}

export interface IChat {
  attachment: [];
  content: string;
  conversation: string;
  createdAt: Date;
  ref: null;
  sender: {
    name: string;
    username: string;
    photo: string;
    _id: string;
    email: string;
  };
  updatedAt: Date;
  _id: string;
}

export interface ITransactionDetailsSocketState {
  isFetched: boolean;
  transactionDetails: ITransactionDetails | null;
  isLoading: boolean;
  chat: IChat[];
  chatsLoading: boolean;
  chatsError: Error | undefined;
  isNewChatRecieved: boolean;
}

export default function TransactionDetailsHook(id: string) {
  // initialize socket context
  const { socket } = useSockets();

  // socket state
  const [state, setState] = useState<ITransactionDetailsSocketState>({
    isFetched: false,
    transactionDetails: null,
    isLoading: true,
    chat: [],
    chatsLoading: true,
    chatsError: undefined,
    isNewChatRecieved: true,
  });

  // set old conversation message to socket state [chat]
  const { refetch: refetchOldconversation } = useGetMessages({
    id: id,
    onSuccess: (data) => {
      setState((prev): ITransactionDetailsSocketState => {
        return {
          ...prev,
          chat: [...[...data]?.reverse(), ...prev.chat],
          chatsLoading: false,
          isNewChatRecieved: prev.chat?.length <= 0,
          chatsError: undefined,
        };
      });
    },
    onFailed: (err) => {
      setState((prev) => {
        return {
          ...prev,
          chatsLoading: false,
          chatsError: err,
        };
      });
      throw new Error(err?.message);
    },
  });

  // updating new chat on new message event listener
  const handleChat = useCallback((chat: IChat) => {
    setState((prev) => {
      return {
        ...prev,
        chat: [...prev.chat, chat],
        chatsLoading: false,
        isNewChatRecieved: true,
        chatsError: undefined,
      };
    });
  }, []);

  // updating transaction details on withdrawal event listener
  const handleWithdrawDetails = useCallback((data: ITransactionDetails) => {
    setState((prev) => ({
      ...prev,
      transactionDetails: data,
      isLoading: false,
      isFetched: true,
    }));
  }, []);

  // updating socket boolean loading/error state on socket failed
  const handleSocketFailed = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isLoading: false,
      isFetched: true,
      chatsError: undefined,
      chatsLoading: false,
    }));
  }, []);

  useEffect(() => {
    // socket connecting...
    socket.connect();

    refetchOldconversation();

    // joining to room
    socket.emit("joinRoom", { chatroomId: id });

    // listening withdraw details event
    socket.on(`withdrawDetails-${id}`, handleWithdrawDetails);

    // listening chat event
    socket.on(`newMessage-${id}`, handleChat);

    // socket error handlers
    socket.on("connect_error", handleSocketFailed);
    socket.on("connect_", handleSocketFailed);
    socket.on("error", handleSocketFailed);

    return () => {
      socket.disconnect();
      socket.off(`withdrawDetails-${id}`, handleWithdrawDetails);
      socket.off(`newMessage-${id}`, handleChat);
      socket.off("connect_error", handleSocketFailed);
      socket.off("connect_", handleSocketFailed);
      socket.off("error", handleSocketFailed);
    };
  }, [
    handleChat,
    handleSocketFailed,
    handleWithdrawDetails,
    id,
    refetchOldconversation,
    socket,
  ]);

  return state as ITransactionDetailsSocketState;
}
