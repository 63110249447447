import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from "@mui/material";
import { IGcDeclinePayload } from "queries/agent/game_credit_query";
import { useDeclineWithdrawRequestMutation } from "queries/agent/withdraw_query";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

type ModalProps = {
  _id: string;
};

const schema = yup.object({
  id: yup.string().required(),
  rejection_note: yup.string().required("Note is Required"),
});

export default NiceModal.create<ModalProps>(({ _id }) => {
  const modal = useModal();

  const { isLoading, mutateAsync } = useDeclineWithdrawRequestMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IGcDeclinePayload>({
    defaultValues: { id: _id, rejection_note: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IGcDeclinePayload) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
    modal.remove();
  };

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle color="error.main">
        Do you want to decline this request?
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="flex flex-col gap-2">
          <InputLabel required>Note</InputLabel>
          <Controller
            control={control}
            name="rejection_note"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Write your note"
                error={!!errors.rejection_note}
                helperText={errors.rejection_note?.message}
                multiline
                rows={3}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={modal.remove}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            color="error"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});
