import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_USER } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

interface ICountry {
  name: string;
  alpha2: string;
  alpha3: string;
  currencies: string[];
  languages: string[];
  dialCode: string;
  status: string;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export const useCountry = () => {
  return useQuery<ICountry[], Error>(QUERY_KEYS.countryList, async () => {
    try {
      const resp = await privateRequest.get(API_ENDPOINTS_USER.countryList);
      return resp.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};
