import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";

const steps = [
  "Download App",
  "Configure the App",
  "Enabled Google Authenticator",
];
type Props = {
  activeStep: number;
};

const StepperGA = ({ activeStep }: Props) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperGA;
