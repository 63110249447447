import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { GrInsecure } from "react-icons/gr";

type Props = {
  secret: string;
  button_text: string;
  switch: "enable" | "disable" | "unset";
  message?: string;
};

export default NiceModal.create((props: Props) => {
  const modal = useModal();

  const [code, setCode] = useState<string>("");
  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <div className="h-8"></div>
        <div className="flex flex-col gap-2 items-center max-w-sm mx-auto">
          <div className="mb-8">
            {props.switch === "enable" && <AiFillLock size={100} />}
            {props.switch === "disable" && <AiFillUnlock size={100} />}
            {props.switch === "unset" && <GrInsecure size={100} />}
          </div>
          {props.message && (
            <Typography align="center" sx={{ mb: 3 }}>
              {props.message}
            </Typography>
          )}

          <TextField
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value.replace(/[a-zA-Z,.]/g, ""))}
          />

          <Button
            sx={{ mt: 3 }}
            variant="outlined"
            disabled={code.length !== 6}
            onClick={() => {
              modal.resolve(code);
              modal.hide();
            }}
          >
            {props?.button_text ?? "Ok"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});
