import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RankChip from "components/chips/RankChip";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import { RouteLinks } from "constants/routeLinks";
import { TransactionWithdraw } from "interfaces/agent/transaction";
import _ from "lodash";
import { useWithdrawRequestsQuery } from "queries/agent/withdraw_query";
import queryString from "query-string";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";

const InProgressHistory = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("&status=inEscrow"); //Only inEscrow items needs to be fetched
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate]);

  const { data, isLoading, isError, error } = useWithdrawRequestsQuery({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="In Progress">
        <div className="flex gap-2 items-center">
          <DatePicker
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>

      <div className="h-4" />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TxID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={7} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((transaction, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defaultDateFormat(transaction.createdAt)}
                    </TableCell>
                    <TableCell>{transaction.txid}</TableCell>
                    <TableCell>{transaction.user.username}</TableCell>
                    <TransactionValueCell
                      type={transaction.transactionType}
                      data={transaction}
                    />
                    <TableCell>{transaction.paymentMethod?.title}</TableCell>
                    <TableCell align="center">
                      <RankChip rank={transaction.user.rank} />
                    </TableCell>
                    <TableCell align="center">
                      <Actions transaction={transaction} />
                    </TableCell>
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={7} />}
            {isError && <TableError colSpan={7} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default InProgressHistory;

type ActionProps = {
  transaction: TransactionWithdraw;
};

const Actions: FC<ActionProps> = ({ transaction }) => {
  // TODO: Add onclick
  return (
    <Link
      to={`/agent/${RouteLinks.AGENT_PAYMENT_DETAILS.link}/${transaction._id}`}
    >
      <Chip clickable label="View" color="primary" />
    </Link>
  );
};
