import { useState } from "react"
import { Card } from "@mui/material"
import { IoIosClose } from "react-icons/io"
import { AiOutlineCloudUpload } from "react-icons/ai"

type PropsTypes = {
    error?: boolean,
    defaultFile?: string,
    onChange: (data: any) => void,
    onDelete: () => void
}

/* File uploader */
export const FileUploader = (props: PropsTypes) => {
    const [selected, setSelected] = useState<any>(props.defaultFile || null)

    const handleFileChange = (data: any) => {
        const file = URL.createObjectURL(data)
        setSelected(file)
        props.onChange(data)
    }

    return (
        <div className="flex justify-center items-center w-full sm:w-[300px] overflow-hidden">
            {selected ?
                <div className="w-full h-40 rounded-lg overflow-hidden relative">
                    <img
                        src={selected}
                        alt="..."
                        className="w-full h-40"
                    />

                    <button
                        type="button"
                        className="absolute top-1 right-1 p-1 rounded-full bg-white text-red-400"
                        onClick={() => {
                            setSelected(null)
                            props.onDelete()
                        }}
                    >
                        <IoIosClose size={20} />
                    </button>
                </div>
                :
                <Card elevation={0} sx={{ width: "100%" }}>
                    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-40 rounded-lg border border-gray-300 border-dashed cursor-pointer">
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            <AiOutlineCloudUpload size={40} className="mb-3 w-10 h-10 text-gray-400" />
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Upload banner image</span></p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">Size 300X200. Max Size 6 MB</p>
                        </div>
                        <input
                            id="dropzone-file"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={event => handleFileChange(event.target.files![0])}
                        />
                    </label>
                </Card>
            }
        </div>
    );
}