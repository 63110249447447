import { Button, Card, Skeleton, Typography } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewTotalGCProps = {
  isLoading?: boolean;
  name?: string;
  balance?: number | undefined;
  equivalentAmount?: number | undefined;
};

const OverviewTotalGC: FC<OverviewTotalGCProps> = ({
  isLoading,
  name = "",
  balance = 0,
  equivalentAmount = 0,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Card
      elevation={10}
      className="relative flex flex-col gap-4 !bg-gradient-to-br from-[#6354E1] to-[#270C5C]"
      sx={{ color: "white", p: 3 }}
    >
      <Typography variant="subtitle1">{name}</Typography>

      {isLoading ? (
        <Typography variant="h4">
          <Skeleton variant="text" />
        </Typography>
      ) : (
        <>
          <Typography variant="h4">
            {currencyFormatter({
              value: user.data?.country === "PH" ? equivalentAmount : balance,
              decimalPlaces: 2,
            }) + (user.data?.country === "PH" ? " PHP" : " USD")}
          </Typography>
        </>
      )}

      <div className="flex gap-4">
        <Link to={"/user/" + RouteLinks.GAME_CREDIT_REQUEST.link}>
          <Button className="self-end" variant="outlined" color="secondary">
            Buy
          </Button>
        </Link>
        <Link to={RouteLinks.WITHDRAW_REQUEST.link + "/GC"}>
          <Button className="self-end" variant="outlined" color="secondary">
            Withdraw
          </Button>
        </Link>
      </div>

      <Link to={"/user/" + RouteLinks.GAME_CREDIT_REQUEST.link}>
        Purchase History
      </Link>
    </Card>
  );
};

export default OverviewTotalGC;
