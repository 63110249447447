import Logo from "assets/icons/logo-dark.png";

interface Props {
  children: React.ReactNode;
  title: string;
}

const AuthLayout = ({ title, children }: Props) => {
  return (
    <div className="bg-black min-h-screen flex">
      <div className="p-3 w-full m-auto max-w-[450px] overflow-hidden flex items-center justify-center">
        <div className="px-4 lg:px-8 py-4 lg:pt-5 lg:pb-12 bg-[#2c2e2c] rounded-2.5xl w-full xl:w-[600px]">
          <div className="mb-5 lg:mb-10 text-center">
            <img
              className="inline-block mb-7 mt-3"
              width={150}
              src={Logo}
              alt="logo"
            />
            <h1 className="text-xl md:text-2xl text-white font-semibold">
              {title}
            </h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
