import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface ITransactionDetails {
  txid: string;
  hash: any;
  address: any;
  paymentDetails: string;
  currencyCode: string;
  pairUser: any;
  user: {
    name: string;
    username: string;
    photo: string;
    role: string;
    _id: string;
    email: string;
  };
  issuer: {
    name: string;
    username: string;
    photo: string;
    role: string;
    _id: string;
    email: string;
  };
  platform: string;
  transactableId: any;
  note: string;
  transactionMedia: string;
  criteria: string;
  flag: string;
  transactionType: string;
  amount: number;
  equivalentAmount: number;
  paymentMethod: {
    title: string;
    slug: string;
    description: string;
    user: string;
    type: string;
    timeWindow: number;
    fiatCurrency: any;
    cryptoCurrency: any;
    currency: string;
    cryptoNetwork: any;
    status: string;
    _id: string;
    __v: number;
  };
  isRolledBack: boolean;
  externalTransactionStatus: string;
  externalTransactionPaidAt: any;
  status: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export const useWithdrawDetailsQuery = (id?: string) => {
  return useQuery<ITransactionDetails, Error>(
    QUERY_KEYS.withdrawDetails,
    async () => {
      try {
        const resp = await privateRequest.get(
          API_ENDPOINTS_AGENT.withdraw.details + id ?? ""
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

interface IMarkAsPaidPaylod {
  id: string;
}

interface IMarkAsPaidResp {
  status: boolean;
  message: string;
}

export const useMarkAsPaidMutation = () => {
  return useMutation<IMarkAsPaidResp, Error, IMarkAsPaidPaylod>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AGENT.withdraw.markAsPaid + paylaod.id
          // paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
      },
    }
  );
};
