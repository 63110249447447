import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useChangeEmailVerify } from "queries/security";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
// import toast from "react-hot-toast";

interface IChangeEmailFormFields {
  traceId: string;
  emailVerifyCode: string;
}

type IProps = {
  traceId: string;
  email: string;
};

export default NiceModal.create(({ traceId, email }: IProps) => {
  const modal = useModal();
  const useChangeEmailVerifyMutation = useChangeEmailVerify();
  const { control, handleSubmit } = useForm<IChangeEmailFormFields>({
    defaultValues: {
      traceId: traceId,
    },
  });

  const formSubmit = (data: IChangeEmailFormFields) => {
    toast.promise(useChangeEmailVerifyMutation.mutateAsync(data), {
      loading: "Verifying...",
      error: (err) => {
        modal.remove();
        return err.toString();
      },
      success: (res) => {
        modal.remove();
        return res.message ?? "Operation successful";
      },
    });
  };
  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography color="primary" fontSize={18} fontWeight={500}>
          Change Email Verification
        </Typography>
        <div className="h-2"></div>
        <Typography>
          We send a 6 digit OTP to {email?.substring(0, 3)}*****
          {email?.split("@")[1]}
        </Typography>
        <div className="h-8"></div>
        <form onSubmit={handleSubmit(formSubmit)}>
          <FormLabel sx={{ color: "secondary.contrastText" }}>
            Enter OTP
          </FormLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="emailVerifyCode"
            rules={{ required: "Code is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  placeholder="Enter 6 Digit OTP"
                  error={invalid}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
          <div className="h-8"></div>
          <LoadingButton
            loading={useChangeEmailVerifyMutation.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Verify
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  );
});
