import { colors, createTheme, FilledInput } from "@mui/material";
import responsiveStyle from "./responsiveStyle";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

const borderRadius = 8;

export const COMPONENT_THEME = createTheme({
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true, size: "medium" },
      styleOverrides: {
        contained: {
          color: "primary.contrastText",
          borderRadius: borderRadius,
        },
        containedInherit: {
          color: "#000",
        },
        sizeLarge: { height: 56 },
        sizeMedium: { height: 48 },
        sizeSmall: { height: 36 },
        outlinedSecondary: { color: "#fff" },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: { "&.MuiButton-root": { defaultProps: { size: "small" } } },
      },
    },

    MuiDialogTitle: { defaultProps: { color: "primary.main" } },

    MuiDialogActions: {
      styleOverrides: { root: { "& .MuiButton-sizeMedium": { height: 36 } } },
    },

    MuiChip: {
      styleOverrides: {
        root: { textTransform: "capitalize" },
        filled: {
          fontWeight: 500,
          minWidth: "96px",
          "&.MuiChip-colorSuccess": {
            color: colors.green[800],
            backgroundColor: colors.green[100],
          },
          "&.MuiChip-colorError": {
            color: colors.red[800],
            backgroundColor: colors.red[100],
          },
          "&.MuiChip-colorWarning": {
            color: colors.yellow[800],
            backgroundColor: colors.yellow[100],
          },
          "&.MuiChip-colorInfo": {
            color: colors.lightBlue[800],
            backgroundColor: colors.lightBlue[100],
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: { sizeMedium: { height: 36, width: 36 } },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "p",
          body2: "p",
          button: "button",
          caption: "p",
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          inherit: "p",
          overline: "p",
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      styleOverrides: {
        h2: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 30, 20),
        }),
        h3: ({ theme }) => ({
          fontSize: 20,
          color: theme.palette.primary.main,
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
          },
        }),
        h4: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 30, 25),
        }),
        subtitle1: ({ theme }) => ({
          fontWeight: 500,
          ...responsiveStyle(theme, 17, 15),
        }),
      },
    },

    MuiAppBar: { defaultProps: { color: "inherit" } },
    MuiToolbar: { styleOverrides: { root: { paddingLeft: "24px" } } },

    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor:
            theme.palette.mode === "dark"
              ? colors.grey[700]
              : theme.palette.grey[300],
        }),
      },
    },

    MuiTableRow: {
      styleOverrides: {
        head: ({ theme }) => ({ backgroundColor: theme.palette.primary.main }),
        root: { "&:last-child td": { borderBottom: "none" } },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: { padding: "8px 16px", height: "56px" },
        stickyHeader: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
        head: ({ theme }) => ({
          padding: "16px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }),
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: "filled",
        input: <FilledInput hiddenLabel disableUnderline />,
        displayEmpty: true,
      },
      styleOverrides: { filled: { borderRadius: borderRadius } },
    },

    MuiTextField: {
      defaultProps: {
        variant: "filled",
        hiddenLabel: true,
        InputProps: { disableUnderline: true },
        size: "medium",
      },
      styleOverrides: {
        root: {
          "& .MuiFormHelperText-root": {
            textTransform: "capitalize",
          },
        },
      },
    },

    // Don't even dare to touch this code 😠
    MuiFilledInput: {
      defaultProps: { hiddenLabel: true },
      variants: [
        {
          props: { size: "large" },
          style: {
            height: 56,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          minHeight: 36,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 6,
          paddingRight: 6,
        },
        input: {
          height: 24,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
        },
        inputSizeSmall: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 6,
          paddingRight: 6,
        },
        inputMultiline: { paddingTop: 6, paddingLeft: 6 },
      },
    },

    MuiInputLabel: { styleOverrides: { root: { marginTop: "-5px" } } },

    MuiCard: {
      defaultProps: { elevation: 8 },
      styleOverrides: { root: { boxShadow: "0px 1px 23px rgba(0,0,0,0.2)" } },
    },

    MuiListItemButton: {
      styleOverrides: { root: { borderRadius: borderRadius } },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: { fontWeight: 500 },
        secondary: { fontWeight: 400 },
      },
    },

    MuiSwitch: {
      defaultProps: { color: "primary" },
      styleOverrides: {
        root: { padding: "6px" },
        switchBase: ({ theme }) => ({
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.light,
            },
          },
        }),
        track: { borderRadius: "16px" },
      },
    },

    MuiTabs: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        indicator: {
          top: 4,
          height: "48px",
          borderRadius: 8,
        },
        flexContainer: {
          gap: "4px",
          padding: "4px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "48px",
          borderRadius: "8px",
          "&.Mui-selected": {
            color: "white",
            zIndex: 100,
            "&.MuiButtonBase-root": {
              color: "white",
              backgroundColor: theme.palette.primary.main,
            },
          },
          "&.MuiButtonBase-root": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.action.hover,
          },
        }),
      },
    },
  },
  shape: { borderRadius: borderRadius },
});

// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
// export const MUI_ =
