import { Card, Grow, Skeleton, Typography } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";

type OverviewCurrentCommissionsProps = {
  isLoading: boolean;
  cw?: number;
  cl?: number;
  lastCutOffDate?: Date;
  nextCutOffDate?: Date;
};

const OverviewCurrentCommissions: FC<OverviewCurrentCommissionsProps> = ({
  isLoading,
  cw = 0,
  cl = 0,
  lastCutOffDate,
  nextCutOffDate,
}) => {
  const { user } = useContext(AuthContext);
  const currentCommission = cl - cw;
  const lastCutOffDateformated = lastCutOffDate
    ? moment.utc(lastCutOffDate).format("DD-MM-YYYY")
    : "...";
  const nextCutOffDateformated = nextCutOffDate
    ? moment.utc(nextCutOffDate).format("DD-MM-YYYY")
    : "...";
  return (
    <Grow in>
      <Card
        elevation={10}
        className="max-w-sm md:max-w-full flex flex-col gap-4 !bg-gradient-to-br from-[#1FCE24] to-[#17661F]"
        sx={{ color: "white", p: 3 }}
      >
        <div className="flex justify-between items-center">
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
          >
            Current Commission <span>(-CW + CL)</span>
          </Typography>
          <Link to={"/agent/" + RouteLinks.MY_COMMISSION.link}>
            Transaction History
          </Link>
        </div>

        {isLoading ? (
          <Typography variant="h4">
            <Skeleton variant="text" />
          </Typography>
        ) : (
          <Typography variant="h4" fontWeight={500}>
            {currentCommission.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{" "}
            {user.data?.country === "PH" && " PHP"}
          </Typography>
        )}

        <div className="flex-1" />

        <div className="flex justify-between">
          <Typography>
            Last Cut off Date
            <br />
            {isLoading ? <Skeleton variant="text" /> : lastCutOffDateformated}
          </Typography>
          <Typography>
            Next Cut off Date
            <br />
            {isLoading ? <Skeleton variant="text" /> : nextCutOffDateformated}
          </Typography>
        </div>
      </Card>
    </Grow>
  );
};

export default OverviewCurrentCommissions;
