import { LoadingButton } from "@mui/lab";
import { IconButton, TextField, Typography, useTheme } from "@mui/material";
import { AuthContext } from "context/AuthContext";
import copy from "copy-to-clipboard";
import { useInviteMutation } from "queries/auth";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";
import QRCode from "react-qr-code";
import { dashboardAppUrl } from "utils/urls";
type Props = {};

const InviteSection = (props: Props) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const useInvite = useInviteMutation(() => setEmail(""));

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (!email) {
      return toast.error("Pleave provide valid email address!");
    }
    toast.promise(useInvite.mutateAsync({ email }), {
      loading: "Loading",
      error: (err) => err.toString(),
      success: (res) => res.message ?? "Operation successful",
    });
  };

  const refLink =
    dashboardAppUrl + "signup?ref=" + (user?.data?.referralId ?? "");
  return (
    <>
      <Typography variant="h5">Invite your friend & Play together</Typography>
      <Typography>
        Enjoy the opportunity to invite your friends and play together while
        earning a commission! Playing alone can be fun, but playing with friends
        is better. All you have to do is to enter their email address and press
        Invite.
      </Typography>

      <div className="h-4" />

      <div
        className="flex flex-col items-center rounded-lg p-6"
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <div className="bg-white p-4">
          <QRCode
            value={refLink}
            size={200}
            fgColor={"black"}
            bgColor={"white"}
          />
        </div>
        <div className="h-4" />
        <Typography
          sx={{ display: "flex", gap: 2 }}
          variant="subtitle1"
          alignItems="center"
          justifyContent="center"
        >
          Scan QR CODE{" "}
        </Typography>

        <div className="w-full flex items-center gap-2">
          <TextField fullWidth value={refLink} />
          <IconButton
            onClick={() => {
              copy(refLink);
              toast.success("copied to your clipboard");
            }}
          >
            <FiCopy />
          </IconButton>
        </div>
      </div>

      <div className="h-4" />

      <form onSubmit={submitHandler}>
        <TextField
          fullWidth
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <LoadingButton
                loading={useInvite.isLoading}
                type="submit"
                size="small"
                variant="contained"
              >
                Invite
              </LoadingButton>
            ),
          }}
        />
      </form>
    </>
  );
};

export default InviteSection;
