import { Alert, IconButton, Skeleton, TextField } from "@mui/material";
import { SectionTitle } from "components/title";
import GameCard from "components/uiStyle/gameCard";
import SpaceBar from "components/uiStyle/spaceBar";
import { AuthContext } from "context/AuthContext";
import _ from "lodash";
import { useGamesAll } from "queries/games";
import React, { ChangeEvent, FC, useContext, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";

type GameSectionProps = {
  query: string;
  title: string;
};
const GameSection: FC<GameSectionProps> = ({ query, title }) => {
  const { user } = useContext(AuthContext);
  const [search, setSearch] = useState<string>("");

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  const {
    data,
    isError,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGamesAll(query + `&search=${search ?? ""}&limit=25&random=yes`);

  const gamesData = useMemo(
    () =>
      data?.pages
        .map((page) => page.docs)
        .map((data) => data)
        .flat(),
    [data]
  );
  const dataLength = useMemo(() => {
    return data ? data?.pages?.length * data?.pages?.[0]?.limit : 0;
  }, [data]);

  if (isError) {
    return <Alert severity="error">No Data Found</Alert>;
  }

  return (
    <>
      <SectionTitle.Default title={title}>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          onChange={debounceChangeSearch}
        />
      </SectionTitle.Default>
      <Alert severity="info" sx={{ mt: 2 }}>
        When restrictions have been imposed on a game, users are encouraged to
        use a VPN (Virtual Private Network) as it helps provide a useful
        workaround to country specific restrictions.
      </Alert>
      <SpaceBar.TitleGap />
      {error && <Alert severity="error">Error occured</Alert>}

      {isFetching && !isFetchingNextPage ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {[...Array(5)].map((_, i) => (
            <div key={i}>
              <Skeleton height={195} variant="rectangular" />
              <div className="h-3"></div>
              <Skeleton height={30} width={150} variant="text" />
              <Skeleton height={30} width={50} variant="text" />
              <div className="h-6"></div>
              <Skeleton height={45} width={100} variant="rectangular" />
            </div>
          ))}
        </div>
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={Boolean(hasNextPage)}
          loader={<></>}
          endMessage={
            <div style={{ textAlign: "center", paddingBottom: "10px" }}>
              {gamesData && gamesData?.length >= 1 ? (
                <b>You have seen all</b>
              ) : (
                <p>No Data Found</p>
              )}
            </div>
          }
        >
          <div className="overflow-y-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {data &&
              data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.docs.map((casinoGame, i2) => (
                    <GameCard user={user?.data} key={i2} data={casinoGame} />
                  ))}
                </React.Fragment>
              ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default GameSection;
