import { useDashboardInformation } from "../../../queries/agent/dashboard.query";
import OverviewCurrentCommissions from "./components/OverviewCurrentCommissions";
import OverviewGcPurchaseRequest from "./components/OverviewGcPurchaseRequest";
import OverviewKryptobetWallet from "./components/OverviewKryptobetWallet";
import OverviewTotalAgents from "./components/OverviewTotalAgents";
import OverviewTotalGcPurchased from "./components/OverviewTotalGcPurchased";
import RecentTransactions from "./components/RecentTransactions";

const AgentDashbaordPage = () => {
  const { data, isLoading } = useDashboardInformation();

  return (
    <>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <OverviewKryptobetWallet
          isLoading={isLoading}
          balance={Number((Number(data?.balances?.GC) ?? 0)?.toFixed(2))} //bug here
          totalWithdrawals={Number(data?.totalWithdraw?.toFixed(2))}
        />

        {/* <OverviewInPlayBalance
          isLoading={isLoading}
          balance={Number(data?.balances.inPlay?.toFixed(2))}
        /> */}

        <OverviewTotalGcPurchased
          isLoading={isLoading}
          balance={Number(data?.totalGCPurchase?.toFixed(2))}
        />

        {/* <OverviewTotalGcLosers
          isLoading={isLoading}
          balance={Number(data?.gcLosses?.toFixed(2))}
          aeBalance={133000.0}
          playersBalance={133000.0}
        /> */}

        <OverviewCurrentCommissions
          isLoading={isLoading}
          cl={data?.commissionCutoffData?.cl}
          cw={data?.commissionCutoffData?.cw}
          lastCutOffDate={data?.commissionCutoffData?.lastCutOff}
          nextCutOffDate={data?.commissionCutoffData?.nextCutOff}
        />

        <OverviewTotalAgents
          isLoading={isLoading}
          totalAgents={Number(data?.agents.total)}
          eam={data?.agents.eam}
          jam={data?.agents.jam}
          sam={data?.agents.sam}
          ae={data?.agents.ae}
          players={Number(data?.agents.player)}
        />

        {/* <OverviewAgentUpgradeRequest
          isLoading={isLoading}
          requests={Number(data?.agentRequest.toFixed(2))}
        /> */}

        <OverviewGcPurchaseRequest
          isLoading={isLoading}
          requests={Number(data?.gcPurchaseRequest?.toFixed(2))}
        />
      </div>

      <div className="h-8" />

      <RecentTransactions />
    </>
  );
};

export default AgentDashbaordPage;
