import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionValueCell from "components/table/TransactionValueCell";
import SpaceBar from "components/uiStyle/spaceBar";
import _ from "lodash";
import { useMyCommissionHistoryQuery } from "queries/agent/my_commission_query";
import queryString from "query-string";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getColorSchema } from "utils";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";

import Tabs, { Tab } from "./Tabs";

type CommissionHistoryProps = {
  tab: Tab;
  onChangeTab: (tab: Tab) => void;
};

const CommissionHistory: FC<CommissionHistoryProps> = ({
  tab,
  onChangeTab,
}) => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("&criteria=commission"); //Only Commission Criteria needs to be fetched
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate]);

  const { data, isLoading, isError, error } = useMyCommissionHistoryQuery({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <div className="flex flex-wrap gap-4">
        <Tabs tab={tab} onChangeTab={onChangeTab} />
        <div className="grid auto-cols-auto gap-2 md:flex md:flex-wrap md:justify-end">
          <div className="flex gap-2 items-center md:col-span-2 xl:col-span-1">
            <DatePicker
              name="fromDate"
              placeholder="From: mm/dd/yyyy"
              value={fromDate}
              onChange={(date) => setFromDate(date)}
            />
            {" - "}
            <DatePicker
              name="toDate"
              placeholder="To: mm/dd/yyyy"
              value={toDate}
              onChange={(date) => setToDate(date)}
            />
          </div>
          <TextField
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <IconButton>
                  <BiSearch />
                </IconButton>
              ),
            }}
            placeholder="Search"
            name="search"
            defaultValue={""}
            onChange={debounceChangeSearch}
            type="search"
          />
        </div>
      </div>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TxID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Perchantage%</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={8} rows={10} />}

            {data?.pages?.map((page) =>
              page?.docs.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {defaultDateFormat(transaction.createdAt)}
                  </TableCell>
                  <TableCell>{transaction.txid}</TableCell>
                  <TableCell>{transaction.pairUser?.username}</TableCell>
                  <TableCell>{transaction.rate}%</TableCell>
                  <TableCell>
                    {transaction.type === "debit" ? "CW" : "CL"}
                  </TableCell>
                  <TransactionValueCell
                    type={transaction.type}
                    data={transaction}
                  />
                  <TableCell align="center">
                    <Chip
                      color={getColorSchema(transaction?.status)}
                      label={transaction?.status}
                    />
                  </TableCell>
                  <TableCell>{transaction.note}</TableCell>
                </TableRow>
              ))
            )}

            {hasNoData && <NoTransactions colSpan={8} />}
            {isError && <TableError colSpan={8} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default CommissionHistory;
