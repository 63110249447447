import { TextField } from "@mui/material";
import { FileUploader } from "components/file-uploader";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

type PropsTypes = {
  loading: boolean;
  data?: IRToolListResponse;
  buttonName: string;
  onSubmit: (data: any) => void;
};

export const ToolForm = (props: PropsTypes) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [banner, setBanner] = useState<any>(null);

  useEffect(() => {
    if (props.data && props.data.banner) {
      setBanner(props.data.banner);
    }
  }, [props.data]);

  const onSubmit = (data: any) => {
    if (!banner) {
      return setError("banner", {
        type: "custom",
        message: "Banner is required.",
      });
    }

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("banner", banner);

    props.onSubmit(formData);
  };

  return (
    <div>
      <form
        className="flex flex-col overflow-hidden gap-3 lg:gap-6 w-full select-none"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* File */}
        <div className="mb-4">
          <div className="mb-3">
            {errors.banner && errors.banner.message ? (
              <p className="text-sm font-medium text-red-400">
                {errors.banner.message}
              </p>
            ) : (
              <p className="text-sm font-medium">Banner image</p>
            )}
          </div>

          <FileUploader
            error={false}
            defaultFile={props?.data?.banner}
            onChange={(data) => {
              clearErrors("banner");
              setBanner(data);
            }}
            onDelete={() => {
              setBanner(null);
              setError("banner", {
                type: "custom",
                message: "Banner image is required.",
              });
            }}
          />
        </div>

        {/* Title */}
        <div className="mb-4">
          <div className="mb-3">
            {errors.title && errors.title.message ? (
              <p className="text-sm font-medium text-red-400">
                {errors.title.message}
              </p>
            ) : (
              <p className="text-sm font-medium">Title</p>
            )}
          </div>

          <Controller
            name="title"
            control={control}
            defaultValue={props.data?.title}
            render={({ field }) => (
              <TextField fullWidth placeholder="Title" {...field} />
            )}
            rules={{ required: "Title is required." }}
          />
        </div>

        {/* Description */}
        <div className="mb-2">
          <div className="mb-3">
            {errors.description && errors.description.message ? (
              <p className="text-sm font-medium text-red-400">
                {errors.description.message}
              </p>
            ) : (
              <p className="text-sm font-medium">Description</p>
            )}
          </div>
          <Controller
            name="description"
            control={control}
            defaultValue={props.data?.description}
            render={({ field }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Description"
                {...field}
              />
            )}
            rules={{ required: "Description is required." }}
          />
        </div>

        <button
          type="submit"
          className="px-9 py-3 ml-auto rounded text-sm text-white bg-primary disabled:bg-mute"
          disabled={props.loading}
        >
          {props.loading ? "Loading..." : props.buttonName}
        </button>
      </form>
    </div>
  );
};
