import { useWalletInformation } from "../../../queries/agent/wallet.query";
import GcManagement from "./components/GcManagement";
import OverviewAvailableGC from "./components/OverviewAvailableGC";

const AgentGcManagementPage = () => {
  const { data, isLoading } = useWalletInformation();

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <OverviewAvailableGC
          isLoading={isLoading}
          balance={Number(
            data?.find((x) => x.currencyCode === "GC")?.available || 0
          )}
          equivalentAmount={Number(
            data?.find((x) => x.currencyCode === "GC")?.equivalentAmount || 0
          )}
        />
      </div>

      <div className="h-8" />

      <GcManagement />
    </>
  );
};

export default AgentGcManagementPage;
