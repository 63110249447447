import moment from "moment";

const defaultDateFormat = (date: string | Date): string => {
  return moment.utc(date).format("YYYY-MM-DD hh:mm A");
};

export default defaultDateFormat;

export const dateFormatForSearch = (date: Date): string => {
  return moment(date).format("YYYY-MM-DD");
};
