import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { CardUI } from "components/card";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { ISupportPayload, useSupport } from "queries/support";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

const SupportPage = () => {
  const { control, handleSubmit, reset } = useForm<ISupportPayload>({
    resolver: yupResolver(schema),
  });
  const useSupportMutation = useSupport();
  const formSubmit = (data: ISupportPayload) => {
    reset({
      email: "",
      message: "",
      subject: "",
    });
    toast.promise(useSupportMutation.mutateAsync(data), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => res.message ?? "Operation successful",
    });
  };
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CardUI>
          <SectionTitle.Default title="Support" />
          <SpaceBar.TitleGap />
          <Typography>
            Further information regarding Kryptobet can be found on Kryptobet
            FAQs.
          </Typography>
          <br />
          <Typography>
            However, if you wish to know more about any of our services or
            features please do not hesitate to submit an inquiry below. Upon
            receiving your inquiry our support team will get back to you
            shortly.
          </Typography>
          <SpaceBar.SectionGap />
          <form
            onSubmit={handleSubmit(formSubmit)}
            className="flex flex-col gap-4"
          >
            <InputLabel>Email</InputLabel>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  fullWidth
                  type="email"
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <div className="h-2" />
            <InputLabel>Subject</InputLabel>
            <Controller
              control={control}
              name="subject"
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  fullWidth
                  type="text"
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <div className="h-2" />
            <InputLabel>Message</InputLabel>
            <Controller
              control={control}
              name="message"
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  fullWidth
                  type="text"
                  hiddenLabel
                  multiline
                  rows={10}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <div className="h-2" />
            <LoadingButton
              loading={useSupportMutation.isLoading}
              type="submit"
              variant="contained"
            >
              Send
            </LoadingButton>
          </form>
        </CardUI>
      </Grid>
    </Grid>
  );
};

export default SupportPage;
