import { Alert } from "@mui/material";
import DetailsCard from "components/PaymentDetailsCard";
import TransactionDetailsHook, {
  ITransactionDetailsSocketState,
} from "hooks/transactionDetailsHook";
import ChatCard from "routes/agent/payment_details/components/ChatCard";

type Props = {
  children(props: ITransactionDetailsSocketState): JSX.Element;
  id: string;
};

export default function TransactionHistoryDetailsWraper({
  children,
  id,
}: Props) {
  const hookState = TransactionDetailsHook(id);

  const { transactionDetails, isLoading } = hookState;

  const shouldShowChat =
    transactionDetails?.status !== "declined" &&
    transactionDetails?.status !== "confirmed";

  if (
    hookState?.isFetched &&
    !hookState?.isLoading &&
    !hookState?.transactionDetails
  ) {
    return <Alert severity="error">Something went wrong</Alert>;
  }

  return (
    <>
      <div className="min-h-[84vh] h-full flex flex-col gap-4 md:flex-row ">
        <div className="w-full flex flex-col gap-4">
          <DetailsCard isLoading={isLoading} details={transactionDetails} />

          {/* passing socket details on children render props */}
          {children(hookState)}
        </div>

        {shouldShowChat && <ChatCard id={id} hookState={hookState} />}
      </div>
    </>
  );
}
