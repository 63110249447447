import { ToolForm } from "components/form/tool.form";
import { useNavigate } from "react-router-dom";
import { useToolCreateMutation } from "../../../queries/agent/tools.query";

const Store = () => {
  let navigate = useNavigate();
  const { isLoading, mutate: storeTool, isSuccess } = useToolCreateMutation();

  /* Handle submit */
  const handleSubmit = (data: any) => storeTool(data);

  if (isSuccess) navigate(`/agent/tools`, { replace: true });

  return (
    <div>
      <p className="text-lg font-medium mb-6 text-primary">Tools</p>
      <ToolForm
        loading={isLoading}
        buttonName="Submit"
        onSubmit={(data) => handleSubmit(data)}
      />
    </div>
  );
};

export default Store;
