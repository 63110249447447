import { Typography } from "@mui/material";
import React from "react";

type TitleProps = {
  title: string;
  children?: React.ReactNode;
};

function DefaultTitle({ title, children }: TitleProps) {
  return (
    <div className="flex flex-wrap flex-col items-stretch gap-4 md:flex-row md:justify-end md:items-center">
      <Typography
        variant="h3"
        maxWidth="32ch"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {title}
      </Typography>

      <div className="flex-grow"></div>

      <div className="grid auto-cols-auto gap-2 md:flex md:flex-wrap md:justify-end">
        {children}
      </div>
    </div>
  );
}

type SimpleTitleProps = {
  title: string;
  alignment?: "left" | "center" | "right";
};

function SimpleTitle({ title, alignment = "center" }: SimpleTitleProps) {
  return (
    <Typography
      className="font-secondary text-primary-mute text-3xl font-normal"
      component={"h3"}
      variant="h3"
      textAlign={alignment}
      textTransform="capitalize"
    >
      {title}
    </Typography>
  );
}

const SectionTitle = {
  Default: DefaultTitle,
  Simple: SimpleTitle,
};

export default SectionTitle;
