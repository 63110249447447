import { Alert, Button, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { OverviewCard } from "components/card";
import gradientColor from "constants/gradientColor";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useDashboardAnalytics } from "queries/dashboard";
import { FC, useContext } from "react";
import { FiUpload } from "react-icons/fi";
import { Link } from "react-router-dom";

const Overviews: FC = () => {
  const { user } = useContext(AuthContext);
  const { data, isError, isLoading, error } = useDashboardAnalytics();
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(1)].map((_, i) => (
          <Box
            key={i}
            className="rounded-lg shadow-card"
            sx={{
              px: 1.5,
              py: 2,
              background: `linear-gradient(135deg, ${
                gradientColor()[i].colors[0]
              } 0%, ${gradientColor()[i].colors[1]} 100%);"`,
            }}
          >
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
              style={{ marginBottom: 4 }}
            >
              <Skeleton width={150} />
            </Typography>
            <Typography
              sx={{ color: "primary.contrastText" }}
              textAlign="right"
              variant="h2"
            >
              <Skeleton sx={{ ml: "auto" }} width={150} />
            </Typography>
            <div className="h-4"></div>
            <div className="flex">
              <Skeleton width={150} />
              <Skeleton sx={{ ml: "auto" }} width={150} />
            </div>
            <Skeleton height={80} width={150} />
          </Box>
        ))}
      </div>
    );
  }
  if (isError) {
    return (
      <Alert severity="error">{error?.message ?? "Something went wrong"}</Alert>
    );
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <OverviewCard
        index={0}
        title="Available Balance"
        number={
          (Number(data?.balances?.GC) ?? 0)?.toFixed(2) +
          (user.data?.country === "PH" ? " PHP" : " USD")
        }
      >
        <ul className="mb-6 overflow-hidden">
          <li>
            <Typography
              component="p"
              sx={{ color: "primary.contrastText" }}
              variant="subtitle1"
            >
              Total Withdrawals
              <span className="float-right">
                {`${data?.totalWithdraw?.toFixed(2)}`}{" "}
                {user.data?.country === "PH" ? "PHP" : "USD"}
              </span>
            </Typography>
          </li>
        </ul>
        <Link to={`${RouteLinks.WITHDRAW_REQUEST.link}/GC`}>
          <Button
            size="medium"
            sx={{ borderRadius: 30, px: 1.2 }}
            color="secondary"
            variant="outlined"
          >
            <FiUpload className="mr-3 !h-6 !w-6" />
            Withdraw
          </Button>
        </Link>
      </OverviewCard>
      {/* <OverviewCard
        index={1}
        title="In-Play Balance"
        number={`${data?.balances?.inPlay?.toFixed(2)}`}
      />
      <OverviewCard
        index={2}
        title="Total Game Credits Purchased"
        number={`${data?.totalGCPurchase?.toFixed(2)}`}
      >
        <Link
          to={"/user/" + RouteLinks.GAME_CREDIT_REQUEST.link}
          className="text-white hover:text-primary"
        >
          Transaction History
        </Link>
      </OverviewCard>
      <OverviewCard
        index={3}
        title="Total Wins"
        number={`${data?.totalWinning?.toFixed(2)}`}
      /> */}
    </div>
  );
};
export default Overviews;
