import {
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { TransactionHistoryData } from "interfaces/agent/transaction";
import _ from "lodash";
import queryString from "query-string";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";
import { useTransactionHistory } from "../../../../queries/agent/wallet.query";

type Criteria = "" | "withdraw" | "commission" | "transfer" | "deposit";

const RecentTransactions = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");

  const [criteria, setCriteria] = useState<Criteria>("");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    parsed.criteria = criteria;

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate, criteria]);

  const { data, isLoading, isError, error } = useTransactionHistory({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Recent Transactions">
        <Select
          value={criteria}
          label="Status"
          onChange={(event: SelectChangeEvent<Criteria>) => {
            setCriteria(event.target.value as Criteria);
          }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="withdraw">Withdraw</MenuItem>
          <MenuItem value="commission">Commission</MenuItem>
          <MenuItem value="transfer">Transfer</MenuItem>
          <MenuItem value="deposit">Deposit</MenuItem>
        </Select>
        <div className="flex gap-2 items-center ">
          <DatePicker
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={6} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defaultDateFormat(item.createdAt.toString())}
                    </TableCell>
                    <TableCell>{item.txid}</TableCell>
                    <TableCell align="center">
                      <Chip label={item.criteria} />
                    </TableCell>
                    <TransactionValueCell
                      type={item.transactionType}
                      data={item}
                    />
                    <TableCell align="center">
                      <StatusChips transaction={item} />
                    </TableCell>
                    <TableCell>
                      {item?.criteria === "withdraw" ? (
                        <>
                          {item?.paymentMethod?.type?.toUpperCase()} (
                          <span className="text-primary px-1">
                            {item?.paymentMethod?.currency?.toUpperCase()}
                          </span>
                          ){" "}
                          {item?.paymentMethod?.type === "crypto"
                            ? item?.address
                            : item?.paymentDetails}
                        </>
                      ) : (
                        item?.note
                      )}
                    </TableCell>
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default RecentTransactions;

type StatusChipsProps = {
  transaction: TransactionHistoryData;
};

const StatusChips: FC<StatusChipsProps> = ({ transaction }) => {
  switch (transaction.status) {
    case "approved":
      return <Chip label="Approved" color="success" />;
    case "confirmed":
      return <Chip label="Confirmed" color="success" />;
    case "declined":
      return <Chip label="Declined" color="error" />;
    case "rejected":
      return <Chip label="Rejected" color="error" />;
    case "pending":
      return <Chip label="Pending" color="warning" />;
    default:
      return <Chip label={transaction.status} />;
  }
};
