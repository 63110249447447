import { colors, TableCell, TableRow, Typography } from "@mui/material";
import React, { FC } from "react";

type TableErrorProps = {
  colSpan: number;
  message?: string | undefined;
};

const TableError: FC<TableErrorProps> = ({
  colSpan,
  message = "Something Went Wrong! 🤔",
}) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={colSpan}>
        <Typography color={colors.red[500]}>{message}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default TableError;
