import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RankChip from "components/chips/RankChip";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import { SectionTitle } from "components/title";
import { RankUpgradeRequestStatus } from "interfaces/agent/rank";
import _ from "lodash";
import { useRankUpgradeRequestHistoryQuery } from "queries/agent/rank_query";
import queryString from "query-string";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";

const AgentUpgradeHistoryPage = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate]);

  const { data, isLoading, isError, error } = useRankUpgradeRequestHistoryQuery(
    {
      query: query,
      page: page,
    }
  );

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Agent Upgrade History">
        {/* <Select fullWidth value="30" label="Age" onChange={() => {}}>
            <MenuItem value={30}>Rank</MenuItem>
          </Select> */}
        <div className="flex gap-2 items-center">
          <DatePicker
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>

      <div className="h-4" />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Current Rank</TableCell>
              <TableCell align="center">Previous Rank</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={7} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((rankData, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defaultDateFormat(rankData.createdAt)}
                    </TableCell>
                    <TableCell>{rankData.user.username}</TableCell>
                    <TableCell>{rankData.user.email}</TableCell>
                    <TableCell align="center">
                      <RankChip rank={rankData.user.rank} />
                    </TableCell>
                    <TableCell align="center">
                      <RankChip rank={rankData.previousRank ?? undefined} />
                    </TableCell>
                    <TableCell align="center">
                      <Status status={rankData.status} />
                    </TableCell>
                    <TableCell sx={{ color: "error.main" }}>
                      {rankData.note ?? "Null"}
                    </TableCell>
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={7} />}
            {isError && <TableError colSpan={7} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default AgentUpgradeHistoryPage;

type StatusProps = {
  status: RankUpgradeRequestStatus;
};

const Status: FC<StatusProps> = ({ status }) => {
  switch (status) {
    case "approved":
      return <Chip label="Approved" color="success" />;
    case "pending":
      return <Chip label="Pending" color="warning" />;
    case "declined":
      return <Chip label="Declined" color="error" />;
    default:
      return <Chip label={status} color="default" />;
  }
};
