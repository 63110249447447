import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";

type PropsTypes = {
  title: string;
  description: string;
  mode:
    | "error"
    | "inherit"
    | "secondary"
    | "info"
    | "primary"
    | "success"
    | "warning"
    | undefined;
  submitButtonTitle: string;
};

export const ConfirmationModal = NiceModal.create((props: PropsTypes) => {
  const modal = useModal();

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{ onExited: () => modal.remove() }}
    >
      <DialogContent sx={{ p: 5 }}>
        <Typography fontSize={18} fontWeight={500}>
          {props.title}
        </Typography>

        {props?.description && (
          <Typography sx={{ mt: 1 }}>{props.description}</Typography>
        )}

        <div className="gap-6 flex items-stretch mt-10">
          <Button
            size="small"
            fullWidth
            variant="contained"
            color="inherit"
            onClick={() => modal.hide()}
          >
            <p>Cancel</p>
          </Button>
          <Button
            size="small"
            fullWidth
            variant="contained"
            color={props.mode}
            onClick={() => {
              modal.resolve();
              modal.remove();
            }}
          >
            <p>{props.submitButtonTitle || "Okay"}</p>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});
