import { privateRequest } from "config/axios.config";
import { RouteGroups, RouteLinks } from "constants/routeLinks";
import { RankType } from "queries/rank";
import React, { FC, useState } from "react";
import {
  getTokenFromCache,
  removeTokenFromCache,
  setTokenToLocalStorage,
  setTokenToSessionStorage,
} from "utils/localStorage";

export enum USER_STATUS {
  "a" = "A",
}

export type UserStatusTypes = "activated" | "pending" | "blocked";

export type UserRoleTypes = "user" | "agent";

export interface IUser {
  gamingID: string;
  gamingPassword: string;
  gamingUsername: string;
  gamingCurrency: string;
  accountVerifiedAt: null | Date;
  country: string;
  createdAt: Date;
  deletedAt: null | Date;
  dialCode: null | string;
  email: string;
  googleAuthentication: "enabled" | "disabled" | "unset";
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  name: string;
  username: string;
  phone: null | string;
  photo: string;
  rank: RankType;
  referralId: string;
  role: UserRoleTypes;
  sponsor: {
    name: string;
    username: string;
    photo: null | string;
    _id: string;
    email: string;
  };
  status: UserStatusTypes;
  _id: string;
  usdToPhp: number;
}

interface IInitialState {
  token: string | null;
  user: {
    isLoading: boolean;
    data: null | IUser;
  };
}

interface IAuth extends IInitialState {
  loginStart: () => void;
  loginFinish: (
    token: string,
    keepLogedin: boolean,
    role: UserRoleTypes
  ) => void;
  logout: (cb?: Function) => void;
  setUser: (user: IUser) => void;
  centralAmount: Function;
}

const intialState = {} as IAuth;

export const AuthContext = React.createContext<IAuth>(intialState);

interface Props {
  children: React.ReactElement;
}

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState<IInitialState>({
    token: getTokenFromCache(),
    user: {
      isLoading: false,
      data: null,
    },
  });

  const loginFinish = (
    token: string,
    keepLogedin: boolean = true,
    role: UserRoleTypes
  ) => {
    if (keepLogedin) {
      setTokenToLocalStorage(token);
    } else {
      setTokenToSessionStorage(token);
    }
    if (role === "agent") {
      window.location.replace(
        "/" + RouteGroups.AGENT.link + "/" + RouteLinks.DASHBOARD.link
      );
    } else {
      setAuth({ ...auth, token: token });
    }
  };

  const loginStart = () => {
    setAuth({ ...auth });
  };

  const logout = (cb: Function = () => {}) => {
    privateRequest.delete("auth/logout").finally(() => removeTokenFromCache());

    setAuth({
      token: null,
      user: {
        data: null,
        isLoading: false,
      },
    });
    cb && cb(); //call the callback function if passed on
  };

  const setUser = (user: IUser) => {
    let newUser = {
      isLoading: false,
      data: user,
    };
    setAuth({ ...auth, user: newUser });
  };

  // centralAmount function to return amount with symbol based on user country location
  const centralAmount = (data: any) => {
    const amount = `${data?.amount?.toFixed(2)} USD`;
    const equivalentAmount = `${data?.equivalentAmount?.toFixed(2)} PHP`;

    // return GC amount ⚫ if user not from philippines 🇵🇭
    if (auth?.user?.data?.country !== "PH") return amount;

    if (!isNaN(data?.equivalentAmount)) {
      // return equivalentAmount amount for philippines 🇵🇭
      return equivalentAmount;
    } else {
      // return default amount while equivalentAmount does not exist
      return amount;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        loginStart,
        loginFinish,
        logout,
        setUser,
        centralAmount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
