import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export type GameReportResp = {
  docs: IGameReportGame[];
  totalPnl: number;
  totalTurnOver: number;
  totalUserCount: number;
};

export interface IGameReportGame {
  _id: number;
  purchaseCount: number;
  grossTurnOver: number;
  totalPlayed: number;
  totalWin: number;
  totalLoss: number;
  users: Array<string>;
  name: string;
  pnl: number;
  userCount: number;
}

export const useGameReportQuery = ({ query = "" }) => {
  return useQuery<GameReportResp, Error>(
    [QUERY_KEYS.gameReport, query],
    async () => {
      try {
        const resp = await privateRequest.get(
          API_ENDPOINTS_AGENT.gameReport + "?" + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};
