import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Avatar, InputLabel, TextField, Typography } from "@mui/material";
import { CardUI } from "components/card";
import { AuthContext } from "context/AuthContext";
import { PurchaseRequestPayload, usePurchaseRequest } from "queries/games";
import { FC, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

type RequestGcProps = {
  query: string;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("GC Amount is Required")
    .min(0.00000001, "Amount must be greater than or equal to 0.00000001"),
  note: yup.string().required("Note is Required"),
});

const RequestGC: FC<RequestGcProps> = ({ query }) => {
  const { control, handleSubmit } = useForm<PurchaseRequestPayload>({
    defaultValues: {
      amount: 0,
    },
    resolver: yupResolver(schema),
  });
  const { user } = useContext(AuthContext);

  const usePurchaseRequestMutation = usePurchaseRequest(query);

  const formSubmit = (data: PurchaseRequestPayload) => {
    toast.promise(usePurchaseRequestMutation.mutateAsync(data), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => res.message ?? "Operation successful",
    });
  };
  return (
    <CardUI className="md:max-w-lg" padding="0px" borderRadius="8px">
      <div className="px-6 py-2 flex justify-between items-center border-2 rounded-lg border-primary">
        <Typography fontWeight={500}>Agent's username</Typography>

        <div className="flex gap-4 items-center">
          {user?.data?.sponsor?.photo ? (
            <img
              src={user?.data?.sponsor?.photo}
              className="!w-10 !h-10 rounded-full"
              alt="agent"
            />
          ) : (
            <Avatar className="!w-10 !h-10" />
          )}
          <Typography color="primary.main" fontWeight={500}>
            {user?.data?.sponsor?.username}
          </Typography>
        </div>
      </div>

      <div className="h-4" />

      <form
        onSubmit={handleSubmit(formSubmit)}
        className="p-6 flex flex-col gap-2"
      >
        <InputLabel sx={{ display: "flex", justifyContent: "space-between" }}>
          {user?.data?.country === "PH" ? "PHP" : "USD"} Amount
          <span>
            1 GC =
            {user?.data?.country === "PH"
              ? ` ${user?.data?.usdToPhp} PHP`
              : " $1.00"}
          </span>
        </InputLabel>
        <Controller
          control={control}
          name="amount"
          render={({ field, fieldState: { error, invalid } }) => {
            console.log(field.value);

            return (
              <>
                <TextField
                  value={field.value}
                  onChange={(e) =>
                    field.onChange({
                      target: {
                        value: e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*)\./g, "$1"),
                        name: e.target.name,
                      },
                    })
                  }
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                />

                <InputLabel>
                  ≈{" "}
                  {user?.data?.country === "PH"
                    ? (field.value / (user?.data?.usdToPhp ?? 0)).toFixed(2)
                    : field.value}{" "}
                  GC
                </InputLabel>
              </>
            );
          }}
        />

        <div className="h-2" />

        <InputLabel>Note</InputLabel>

        <Controller
          control={control}
          name="note"
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              value={field.value}
              onChange={field.onChange}
              type="text"
              error={invalid}
              helperText={error?.message}
              hiddenLabel
              multiline
              rows={3}
            />
          )}
        />

        <div className="h-2" />

        <LoadingButton
          loading={usePurchaseRequestMutation.isLoading}
          type="submit"
          variant="contained"
        >
          Request GC
        </LoadingButton>
      </form>
    </CardUI>
  );
};

export default RequestGC;
