import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import {
  CRYPTO_CURRENCIES,
  CRYPTO_NETWORKS,
  IPayloadCreatePaymentMethodCrypto,
  IPayloadCreatePaymentMethodFiat,
  useCreatePaymentMethodMutation,
  useFiatCurrencyListQuery,
} from "queries/agent/payment_method_query";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BiCoin } from "react-icons/bi";
import * as yup from "yup";

type ModalProps = {};

export default NiceModal.create<ModalProps>(() => {
  const modal = useModal();

  const [currencyType, setCurrencyType] = useState<PaymentMethodType>("crypto");

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle color="primary.main">Add New Payment Method</DialogTitle>

      <DialogContent>
        <Select
          fullWidth
          value={currencyType}
          label="Currency Type"
          displayEmpty
          onChange={(event: SelectChangeEvent<PaymentMethodType>) => {
            setCurrencyType(event.target.value as PaymentMethodType);
          }}
        >
          <MenuItem value="crypto">Crypto Currency</MenuItem>
          <MenuItem value="fiat">Fiat Currency</MenuItem>
        </Select>

        <div className="h-4" />

        {currencyType === "crypto" ? (
          <CryptoCurrencyForm onClose={modal.remove} />
        ) : (
          <FiatCurrencyForm onClose={modal.remove} />
        )}
      </DialogContent>
    </Dialog>
  );
});

type CryptoCurrencyFormProps = {
  onClose: () => void;
};

const schemaCrypto = yup.object({
  cryptoNetwork: yup.string().required("Network is required"),
  currency: yup.string().required("Currency is Required"),
  description: yup.string(),
  timeWindow: yup
    .number()
    .min(1, "Must be greater than 0")
    .typeError("Must be a number")
    .required("Duration is required"),
});

const CryptoCurrencyForm: FC<CryptoCurrencyFormProps> = ({ onClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IPayloadCreatePaymentMethodCrypto>({
    defaultValues: {
      type: "crypto",
      cryptoNetwork: "",
      currency: "",
      timeWindow: 1,
      description: "",
    },
    resolver: yupResolver(schemaCrypto),
  });

  const { isLoading, mutateAsync } = useCreatePaymentMethodMutation();

  const onSubmit = async (data: IPayloadCreatePaymentMethodCrypto) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
    onClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <Controller
        control={control}
        name="currency"
        defaultValue=""
        render={({ field }) => (
          <div>
            <Select
              {...field}
              fullWidth
              label="Currency"
              displayEmpty
              error={!!errors.currency}
              renderValue={
                !field.value
                  ? undefined
                  : (value) => (
                      <div className="flex gap-2 items-center">
                        <Avatar sx={{ height: 24, width: 24 }}>
                          <BiCoin />
                        </Avatar>
                        <Typography textTransform="uppercase">
                          {value}
                        </Typography>
                      </div>
                    )
              }
            >
              <MenuItem value="" disabled>
                Currency
              </MenuItem>
              {CRYPTO_CURRENCIES.map((currency, index) => (
                <MenuItem key={index} value={currency} className="flex gap-2">
                  <Avatar>
                    <BiCoin />
                  </Avatar>
                  <Typography textTransform="uppercase">{currency}</Typography>
                </MenuItem>
              ))}
            </Select>

            {errors.currency && (
              <FormHelperText error>{errors.currency?.message}</FormHelperText>
            )}
          </div>
        )}
      />

      <Controller
        control={control}
        name="cryptoNetwork"
        defaultValue=""
        render={({ field }) => (
          <div>
            <Select
              {...field}
              fullWidth
              label="Network"
              displayEmpty
              error={!!errors.cryptoNetwork}
            >
              <MenuItem value="" disabled>
                Network
              </MenuItem>
              {CRYPTO_NETWORKS.map((network, index) => (
                <MenuItem key={index} value={network}>
                  <Typography textTransform="uppercase">{network}</Typography>
                </MenuItem>
              ))}
            </Select>

            {errors.cryptoNetwork && (
              <FormHelperText error>
                {errors.cryptoNetwork?.message}
              </FormHelperText>
            )}
          </div>
        )}
      />

      <Controller
        control={control}
        name="timeWindow"
        defaultValue={1}
        render={({ field }) => (
          <div>
            <InputLabel>Duration</InputLabel>
            <TextField
              {...field}
              fullWidth
              placeholder="Time"
              type="number"
              error={!!errors.timeWindow}
              helperText={errors.timeWindow?.message}
              InputProps={{
                disableUnderline: true,
                inputProps: { min: 1 },
                endAdornment: (
                  <Typography color="primary.main" sx={{ paddingRight: "8px" }}>
                    Mins
                  </Typography>
                ),
              }}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="description"
        defaultValue=""
        render={({ field }) => (
          <div>
            <InputLabel>Description</InputLabel>
            <TextField
              {...field}
              fullWidth
              placeholder="Description"
              type="text"
              error={!!errors.description}
              helperText={errors.description?.message}
              multiline
              rows={3}
            />
          </div>
        )}
      />

      <LoadingButton type="submit" loading={isLoading} variant="contained">
        Save
      </LoadingButton>
    </form>
  );
};

type FiatCurrencyFormProps = { onClose: () => void };

const schemaFiat = yup.object({
  currency: yup.string().required("Currency is required"),
  title: yup.string().required("Method Name is required"),
  timeWindow: yup
    .number()
    .min(1, "Must be greater than 0")
    .typeError("Must be a number")
    .required("Duration is required"),
  description: yup.string(),
});

const FiatCurrencyForm: FC<FiatCurrencyFormProps> = ({ onClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IPayloadCreatePaymentMethodFiat>({
    defaultValues: {
      type: "fiat",
      currency: "",
      title: "",
      timeWindow: 1,
      description: "",
    },
    resolver: yupResolver(schemaFiat),
  });

  const { isLoading: isLoadingCurrency, data: currencies } =
    useFiatCurrencyListQuery();

  const { isLoading, mutateAsync } = useCreatePaymentMethodMutation();

  const onSubmit = async (data: IPayloadCreatePaymentMethodFiat) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
    onClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <Controller
        control={control}
        name="currency"
        defaultValue=""
        render={({ field }) => (
          <Autocomplete
            onChange={(event, newValue) => {
              field.onChange(newValue?.code);
            }}
            fullWidth
            loading={isLoadingCurrency}
            getOptionLabel={(option) => option.code || ""}
            options={currencies ?? []}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Currency"
                error={!!errors.currency}
                helperText={errors.currency?.message}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name="title"
        defaultValue=""
        render={({ field }) => (
          <div>
            <InputLabel>Method Name</InputLabel>
            <TextField
              {...field}
              fullWidth
              placeholder="Ex. Bank Transfer"
              type="text"
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="timeWindow"
        defaultValue={1}
        render={({ field }) => (
          <div>
            <InputLabel>Duration</InputLabel>
            <TextField
              {...field}
              fullWidth
              placeholder="Time"
              type="number"
              error={!!errors.timeWindow}
              helperText={errors.timeWindow?.message}
              InputProps={{
                disableUnderline: true,
                inputProps: { min: 1 },
                endAdornment: (
                  <Typography color="primary.main" sx={{ paddingRight: "8px" }}>
                    Mins
                  </Typography>
                ),
              }}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="description"
        defaultValue=""
        render={({ field }) => (
          <div>
            <InputLabel>Description</InputLabel>
            <TextField
              {...field}
              fullWidth
              placeholder="Description"
              type="text"
              error={!!errors.description}
              helperText={errors.description?.message}
              multiline
              rows={3}
            />
          </div>
        )}
      />

      <LoadingButton type="submit" loading={isLoading} variant="contained">
        Save
      </LoadingButton>
    </form>
  );
};
