import { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
} from "@mui/material";
import { CardUI } from "components/card";
import changeEmail from "components/modals/changeEmail";
import changePassword from "components/modals/changePassword";
import changePhone from "components/modals/changePhone";
import googleAuthenticator from "components/modals/google_authenticator/googleAuthenticator";
import requestToUnsetGoogleAuthenticator from "components/modals/google_authenticator/requestToUnsetGoogleAuthenticator";
import setGoogleAuthenticatorStepOne from "components/modals/google_authenticator/setGoogleAuthenticatorStepOne";
import { SectionTitle } from "components/title";
import { AuthContext } from "context/AuthContext";
import {
  useGoogleAuthenticationSecret,
  useSwitchGoogleAuthenticationMutation,
  useUnset2faMutation,
} from "queries/security";
import React, { FC, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { AiOutlineGoogle, AiOutlineMobile } from "react-icons/ai";
import { MdOutlineMail, MdOutlineSecurity } from "react-icons/md";
import { companyName } from "utils/urls";

type SecurityCardProps = {};

const SecurityCard: FC<SecurityCardProps> = (props) => {
  const changeEmailModal = useModal(changeEmail);
  const changePhoneModal = useModal(changePhone);
  const changePasswordModal = useModal(changePassword);
  const setGoogleAuthenticatorStepOneModal = useModal(
    setGoogleAuthenticatorStepOne
  );
  const googleAuthenticatorModal = useModal(googleAuthenticator);
  const requestToUnsetGoogleAuthenticatorModal = useModal(
    requestToUnsetGoogleAuthenticator
  );
  const { user } = useContext(AuthContext);

  const { data, refetch, isLoading } = useGoogleAuthenticationSecret();
  const useSwitchGoogleAuthentication = useSwitchGoogleAuthenticationMutation();
  const useUnset2fa = useUnset2faMutation();

  let dataForQRcode = `otpauth://totp/${companyName}:${user?.data?.email}?secret=${data?.secret}&issuer=${companyName}&algorithm=SHA1&digits=6&period=30`;
  const isEnableGoogleAuthentication =
    user?.data?.googleAuthentication === "enabled" || false;
  const isGoogleAuthenticationSet =
    user?.data?.googleAuthentication !== "unset";

  useEffect(() => {
    // fetch google 2fa secret if 2fa not set
    if (!isGoogleAuthenticationSet) {
      refetch();
    }
  }, [isGoogleAuthenticationSet, refetch]);

  return (
    <CardUI className="flex flex-col gap-4">
      <SectionTitle.Default title="Security" />

      <List>
        <ListItem>
          <ListItemAvatar>
            <MdOutlineMail size={24} />
          </ListItemAvatar>
          <ListItemText
            primary="Email"
            secondary="Secure your accounts with email verification"
          />
          <Button
            onClick={() => changeEmailModal.show()}
            variant="outlined"
            size="small"
          >
            Change
          </Button>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <AiOutlineMobile size={24} />
          </ListItemAvatar>
          <ListItemText
            primary="Mobile"
            secondary="Protect the security of your account and transactions"
          />
          <Button
            onClick={() => changePhoneModal.show()}
            variant="outlined"
            size="small"
          >
            Change
          </Button>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <AiOutlineGoogle size={24} />
          </ListItemAvatar>
          <ListItemText
            primary="Google Authenticator"
            secondary="Strengthen your accounts security measures"
          />
          {isGoogleAuthenticationSet ? (
            <>
              <Switch
                checked={isEnableGoogleAuthentication}
                color="success"
                onChange={() =>
                  googleAuthenticatorModal
                    .show({
                      switch: isEnableGoogleAuthentication
                        ? "disable"
                        : "enable",
                      button_text: isEnableGoogleAuthentication
                        ? "Disable Google Authenticator"
                        : "Enable Google Authenticator",
                    })
                    .then((code: any) => {
                      toast.promise(
                        useSwitchGoogleAuthentication.mutateAsync({
                          otp: code,
                        }),
                        {
                          loading: "Loading",
                          success: (res) =>
                            res?.message || "Operation successfull",
                          error: (err: any) => `${err.toString()}`,
                        }
                      );
                    })
                }
                readOnly
              />
              <Button
                disabled={isLoading || user?.isLoading}
                size="small"
                onClick={() =>
                  requestToUnsetGoogleAuthenticatorModal
                    .show()
                    .then((traceId) => {
                      googleAuthenticatorModal
                        .show({
                          secret: dataForQRcode,
                          switch: "unset",
                          message:
                            "Verifation code has been sent to your email account.",
                          button_text: "Unset Google Authenticator",
                        })
                        .then((code: any) => {
                          toast.promise(
                            useUnset2fa.mutateAsync({
                              traceId: traceId as string,
                              emailVerifyCode: code,
                            }),
                            {
                              loading: "Loading",
                              error: (err) => err.toString(),
                              success: (res) =>
                                res?.message ?? "operation successful",
                            }
                          );
                        });
                    })
                }
                color="error"
                variant="contained"
              >
                Unset
              </Button>
            </>
          ) : (
            <Button
              disabled={isLoading || user?.isLoading}
              size="small"
              variant="outlined"
              onClick={() => setGoogleAuthenticatorStepOneModal.show()}
            >
              Add
            </Button>
          )}
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <MdOutlineSecurity size={24} />
          </ListItemAvatar>
          <ListItemText
            primary="Password Change"
            secondary="Increase the security of your account with a strong password"
          />
          <Button
            onClick={() => changePasswordModal.show()}
            variant="outlined"
            size="small"
          >
            Change
          </Button>
        </ListItem>
      </List>
    </CardUI>
  );
};

export default SecurityCard;
