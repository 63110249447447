import {
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RankChip from "components/chips/RankChip";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import DefaultTransactionValueCell from "components/table/DefaultTransactionValueCell";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import { SectionTitle } from "components/title";
import { AuthContext } from "context/AuthContext";
import _ from "lodash";
import { useUserGamingActivityQuery } from "queries/agent/user_gaming_activity_query";
import queryString from "query-string";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";

type Type = "" | "win" | "loss";

type Props = {};

const AgentPlayerActivitiesPage = (props: Props) => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [type, setType] = useState<Type>("");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    console.log(search);

    const parsed = queryString.parse(query);

    parsed.type = type;

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);

    return () => debounceChangeSearch.cancel();
  }, [debounceChangeSearch, query, type, search, fromDate, toDate]);

  const { data, isLoading, isError, error } = useUserGamingActivityQuery({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Player Activities">
        <Select
          value={type}
          displayEmpty
          label="Type"
          onChange={(event: SelectChangeEvent<Type>) => {
            setType(event.target.value as Type);
          }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="win">Win</MenuItem>
          <MenuItem value="loss">Loss</MenuItem>
        </Select>
        <div className="flex gap-2 items-center col-span-1 md:col-span-2 ">
          <DatePicker
            fullWidth
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            fullWidth
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: <IconButton children={<BiSearch />} />,
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>

      <div className="h-4" />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TxID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={6} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((activity, index) => {
                const won =
                  user.data?.country === "PH"
                    ? activity.equivalentWonAmount
                    : activity.won;
                const loss =
                  user.data?.country === "PH"
                    ? activity.equivalentLossAmount
                    : activity.loss;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defaultDateFormat(activity.createdAt)}
                    </TableCell>
                    <TableCell>{activity.txid}</TableCell>
                    <TableCell>{activity.user.username}</TableCell>
                    <TableCell align="center">
                      <RankChip rank={activity.user.rank} />
                    </TableCell>
                    <TableCell align="center">
                      <Chip label={won > loss ? "win" : "loss"} />
                    </TableCell>
                    <DefaultTransactionValueCell
                      type={won > loss ? "win" : "loss"}
                      value={won > loss ? won : loss}
                      decimalPlaces={2}
                      currency={user.data?.country === "PH" ? "PHP" : "USD"}
                    />
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default AgentPlayerActivitiesPage;
