
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { ToolForm } from "components/form/tool.form"
import { ToolEditSkeleton } from "components/skeletons/tool.skeleton"
import { useToolUpdateMutation, useToolShowQuery } from "../../../queries/agent/tools.query"

const Edit = () => {
  const { id } = useParams()
  let navigate = useNavigate()
  const { isLoading, data } = useToolShowQuery(id || "")
  const { isSuccess, isLoading: updateLoading, mutate: updateTool } = useToolUpdateMutation()

  /* Handle submit */
  const handleSubmit = (data: any) => {
    data.append("id", id)
    updateTool(data)
  }

  if (isSuccess) navigate(`/agent/tools`, { replace: true })

  return (
    <div>
      <p className="text-lg font-medium mb-6 text-primary">Tools</p>
      {!data && isLoading ? <ToolEditSkeleton /> : null}

      {data && !isLoading ?
        <ToolForm
          data={data}
          loading={updateLoading}
          buttonName="Save Changes"
          onSubmit={data => handleSubmit(data)}
        />
        : null
      }
    </div>
  );
};

export default Edit;