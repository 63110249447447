import { useModal } from "@ebay/nice-modal-react";
import { Button, Divider, Paper, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import currentBadge from "assets/badge.png";
import Logo from "assets/icons/logo-dark.png";
import InviteDialog from "components/dialogs/InviteDialog";
import RankBadge from "components/uiStyle/badge";
import { RouteGroups } from "constants/routeLinks";
import { AuthContext, UserRoleTypes } from "context/AuthContext";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { HiSwitchHorizontal } from "react-icons/hi";
import { RiLink } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { agentDrawerLinks, drawerLinks } from "../../constants/drawerLink";
import { DrawerLinkList } from "../link";
import AppBar from "./AppBar";

const drawerWidth = 300;

export type BackButtonLinkType = string | undefined;

interface IRequireAuthProps {
  children: React.ReactElement;
  role: UserRoleTypes;
  backButtonLink: BackButtonLinkType;
}

export default function ResponsiveDrawer({
  backButtonLink,
  role,
  children,
}: IRequireAuthProps) {
  const inviteDialog = useModal(InviteDialog);
  // const switchToAgentFailedPopup = useModal(switchToAgentFailed);
  let { pathname } = useLocation();
  const isAgent = role === RouteGroups.AGENT.name;
  const isInAgentRoute = pathname.includes(RouteGroups.AGENT.link);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // close drawer when link changes
  React.useEffect(() => {
    setTimeout(() => {
      setMobileOpen(false);
    }, 200);
  }, [pathname]);

  const drawer = (
    <div
      className="h-screen flex flex-col px-3"
      style={{
        background: "linear-gradient(0deg, #02300C, #0D0D0D)",
      }}
    >
      <div className="flex flex-col items-center gap-8 pt-10 pb-6">
        <img width={150} src={Logo} alt="logo" />
        {user?.data?.rank ? (
          <RankBadge rank={user?.data?.rank} className="w-[80px]" />
        ) : (
          <img src={currentBadge} alt="logo" className="w-[100px]" />
        )}
        {isAgent && (
          <Button
            variant="outlined"
            onClick={() => inviteDialog.show()}
            startIcon={<RiLink />}
          >
            Referral Link
          </Button>
        )}
      </div>

      <Scrollbars style={{ height: "100%" }}>
        <List>
          {isInAgentRoute
            ? agentDrawerLinks.map((link, i) => (
                <DrawerLinkList key={i} data={link} />
              ))
            : drawerLinks.map((link, i) => (
                <DrawerLinkList key={i} data={link} />
              ))}
        </List>
      </Scrollbars>
      {isAgent && (
        <div className="block mt-auto mb-4">
          <Button
            sx={{ background: "#35583C!important" }}
            className="!text-primary"
            variant="contained"
            fullWidth
            component={Link}
            to={
              isInAgentRoute
                ? `/${RouteGroups.USER.link}`
                : `/${RouteGroups.AGENT.link}`
            }
            startIcon={<HiSwitchHorizontal />}
          >
            Switch to {isInAgentRoute ? "Player" : "Agent"}
          </Button>
        </div>
      )}
      {/* (
        <div className="block mt-auto mb-4">
          <Button
            sx={{ background: "#35583C!important" }}
            className="!text-primary"
            variant="contained"
            fullWidth
            onClick={() => switchToAgentFailedPopup.show()}
            startIcon={<HiSwitchHorizontal />}
          >
            Switch to Agent
          </Button>
        </div>
      ) */}
    </div>
  );

  return (
    <Paper
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Drawer
        variant={isLargeScreen ? "permanent" : "temporary"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: !isLargeScreen }} // Better open performance on mobile.
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Box flexGrow={1} width="100%">
        <AppBar
          handleDrawerToggle={handleDrawerToggle}
          backButtonLink={backButtonLink}
        />

        <Divider />
        <Box
          sx={{
            my: 3,
            flexGrow: 1,
            width: "100%",
            padding: { xs: "0px 16px", lg: "0px 24px" },
          }}
        >
          {children}
        </Box>
      </Box>
    </Paper>
  );
}
