interface currencyFormatterParams {
  value?: number | undefined;
  decimalPlaces?: number | undefined;
}

export function currencyFormatter({
  value = 0,
  decimalPlaces = 2,
}: currencyFormatterParams): string {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
}
