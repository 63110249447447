import { AiOutlineCloudUpload, AiOutlineHistory } from "react-icons/ai";
import { BiGroup } from "react-icons/bi";
import { BsUpload } from "react-icons/bs";
import { CgGames } from "react-icons/cg";
import { FaTableTennis } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import {
  GiCreditsCurrency,
  GiGamepad,
  GiPokerHand,
  GiReceiveMoney,
  GiSloth,
  GiTripleScratches,
  GiVirtualMarker,
} from "react-icons/gi";
import {
  IoGameControllerOutline,
  IoTicketOutline,
  IoWalletOutline,
} from "react-icons/io5";
import { MdPayment, MdPendingActions, MdSupportAgent } from "react-icons/md";
import {
  RiCoinsLine,
  RiCustomerServiceLine,
  RiDashboardLine,
  RiHistoryLine,
  RiQuestionLine,
  RiSettings3Line,
  RiSwapLine,
} from "react-icons/ri";
import { SiVirtualbox } from "react-icons/si";
import { VscGraphLine } from "react-icons/vsc";
import { ReactComponent as WalletIcon } from "../assets/icons/drawer_icons/wallet.svg";
import { PlayRoutes, RouteLinks } from "./routeLinks";

export interface IDrawerChild {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  link: string;
}

export interface IDrawerLink {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  link?: string;
  child?: IDrawerChild[];
}

export const drawerLinks: IDrawerLink[] = [
  {
    label: RouteLinks.DASHBOARD.label,
    icon: RiDashboardLine,
    link: RouteLinks.DASHBOARD.link,
  },
  {
    label: RouteLinks.WALLETS.label,
    icon: WalletIcon,
    link: RouteLinks.WALLETS.link,
  },
  {
    label: RouteLinks.WITHDRAWALS.label,
    icon: BsUpload,
    link: RouteLinks.WITHDRAWALS.link,
  },
  {
    label: RouteLinks.GAME_CREDIT_REQUEST.label,
    icon: RiCoinsLine,
    link: RouteLinks.GAME_CREDIT_REQUEST.link,
  },
  {
    label: "Play",
    icon: IoGameControllerOutline,
    child: [
      {
        label: PlayRoutes.LIVE_CASINO.label,
        icon: GiGamepad,
        link: PlayRoutes.LIVE_CASINO.link,
      },
      // {
      //   label: PlayRoutes.POKER.label,
      //   icon: BiGame,
      //   link: PlayRoutes.POKER.link,
      // },
      {
        label: PlayRoutes.VIRTUAL_SPORTS.label,
        icon: GiVirtualMarker,
        link: PlayRoutes.VIRTUAL_SPORTS.link,
      },
      // {
      //   label: PlayRoutes.SPORTS_BOOK.label,
      //   icon: BiGame,
      //   link: PlayRoutes.SPORTS_BOOK.link,
      // },
      {
        label: PlayRoutes.LIVE_CASINO_TABLE.label,
        icon: CgGames,
        link: PlayRoutes.LIVE_CASINO_TABLE.link,
      },
      {
        label: PlayRoutes.VIDEO_SLOTS.label,
        icon: GiSloth,
        link: PlayRoutes.VIDEO_SLOTS.link,
      },
      {
        label: PlayRoutes.TABLE_GAMES.label,
        icon: FaTableTennis,
        link: PlayRoutes.TABLE_GAMES.link,
      },
      {
        label: PlayRoutes.VIDEO_POKER.label,
        icon: GiPokerHand,
        link: PlayRoutes.VIDEO_POKER.link,
      },
      {
        label: PlayRoutes.VIRTUAL_GAMES.label,
        icon: SiVirtualbox,
        link: PlayRoutes.VIRTUAL_GAMES.link,
      },
      {
        label: PlayRoutes.SCRATCH_CARDS.label,
        icon: GiTripleScratches,
        link: PlayRoutes.SCRATCH_CARDS.link,
      },
      {
        label: RouteLinks.LOTTERY.label,
        icon: IoTicketOutline,
        link: RouteLinks.LOTTERY.link,
      },
    ],
  },
  {
    label: RouteLinks.TRANSACTION_HISTORY.label,
    link: RouteLinks.TRANSACTION_HISTORY.link,
    icon: RiHistoryLine,
  },
  {
    label: RouteLinks.SWAP.label,
    link: RouteLinks.SWAP.link,
    icon: RiSwapLine,
  },
  // TODO: Rank is disabled for now
  // {
  //   label: RouteLinks.RANK.label,
  //   link: RouteLinks.RANK.link,
  //   icon: GiRank3,
  // },
  {
    label: RouteLinks.TOOLS.label,
    link: RouteLinks.TOOLS.link,
    icon: RiSettings3Line,
  },
  {
    label: RouteLinks.SUPPORT.label,
    link: RouteLinks.SUPPORT.link,
    icon: RiCustomerServiceLine,
  },
  {
    label: RouteLinks.FAQ.label,
    link: RouteLinks.FAQ.link,
    icon: RiQuestionLine,
  },
];

export const agentDrawerLinks: IDrawerLink[] = [
  {
    label: RouteLinks.DASHBOARD.label,
    icon: RiDashboardLine,
    link: RouteLinks.DASHBOARD.link,
  },
  {
    label: RouteLinks.GAME_REPORTS.label,
    icon: VscGraphLine,
    link: RouteLinks.GAME_REPORTS.link,
  },
  {
    label: RouteLinks.WALLETS.label,
    link: RouteLinks.WALLETS.link,
    icon: IoWalletOutline,
  },
  {
    label: RouteLinks.PLAYER_MANAGEMENT.label,
    link: RouteLinks.PLAYER_MANAGEMENT.link,
    icon: BiGroup,
  },
  {
    label: RouteLinks.PLAYER_ACTIVITIES.label,
    link: RouteLinks.PLAYER_ACTIVITIES.link,
    icon: FiActivity,
  },
  {
    label: RouteLinks.AGENT_MANAGEMENT.label,
    link: RouteLinks.PLAYER_MANAGEMENT.link,
    icon: MdSupportAgent,
    child: [
      {
        label: RouteLinks.AGENT_MANAGEMENT.label,
        link: RouteLinks.AGENT_MANAGEMENT.link,
        icon: MdSupportAgent,
      },
      // Temprory disabled now
      // {
      //   label: RouteLinks.AGENT_USERS_ACTIVITY.label,
      //   link: RouteLinks.AGENT_USERS_ACTIVITY.link,
      //   icon: FiActivity,
      // },

      // Temprory disabled now
      // {
      //   label: RouteLinks.AGENT_UPGRADE_REQUESTS.label,
      //   link: RouteLinks.AGENT_UPGRADE_REQUESTS.link,
      //   icon: GiUpgrade,
      // },
      {
        label: RouteLinks.AGENT_UPGRADE_HISTORY.label,
        link: RouteLinks.AGENT_UPGRADE_HISTORY.link,
        icon: AiOutlineHistory,
      },
    ],
  },
  {
    label: RouteLinks.WITHDRAWAL_MANAGEMENT.label,
    link: RouteLinks.WITHDRAWAL_MANAGEMENT.link,
    icon: AiOutlineCloudUpload,
    child: [
      {
        label: RouteLinks.PENDING_WITHDRAWALS.label,
        link: RouteLinks.PENDING_WITHDRAWALS.link,
        icon: MdPendingActions,
      },
      {
        label: RouteLinks.PAYMENT_METHOD.label,
        link: RouteLinks.PAYMENT_METHOD.link,
        icon: MdPayment,
      },
      {
        label: RouteLinks.TRANSACTION_HISTORY.label,
        link: RouteLinks.TRANSACTION_HISTORY.link,
        icon: AiOutlineHistory,
      },
    ],
  },
  {
    label: RouteLinks.TOOLS.label,
    link: RouteLinks.TOOLS.link,
    icon: RiSettings3Line,
  },
  {
    label: RouteLinks.GC_MANAGEMENT.label,
    link: RouteLinks.GC_MANAGEMENT.link,
    icon: GiCreditsCurrency,
  },
  {
    label: RouteLinks.MY_COMMISSION.label,
    link: RouteLinks.MY_COMMISSION.link,
    icon: GiReceiveMoney,
  },
  {
    label: RouteLinks.AGENT_COMMISSION.label,
    link: RouteLinks.AGENT_COMMISSION.link,
    icon: GiReceiveMoney,
  },
];
