import LoginForm from "components/form/loginForm";
import AuthLayout from "components/layout/authLayout";

interface Props {}

const Login = (props: Props) => {
  return (
    <AuthLayout title="Sign in to KryptoBet">
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
