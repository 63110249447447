import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { useThemeContext } from "context/ThemeContext";
import { useCountry } from "queries/country";
import { IChangePhoneVerifyPayload, useChangePhone } from "queries/security";
import { useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Select from "react-select";
import { reactSelectDarkInPopupStyles, reactSelectStyles } from "styles/form";

interface ISelectCountry {
  value: string;
  label: string;
}

export default NiceModal.create(() => {
  const { themeMode } = useThemeContext();
  const modal = useModal();
  const { user } = useContext(AuthContext);
  const [showPass, setShowPass] = useState(false);
  const { control, handleSubmit } = useForm<IChangePhoneVerifyPayload>({
    defaultValues: {
      dialCode: user?.data?.dialCode ?? "",
      phone: user?.data?.phone ?? "",
      password: "",
    },
  });
  const useChangePhoneMutation = useChangePhone();

  const { data: countries, isLoading: isLoadingCountries } = useCountry();
  const countryList: ISelectCountry[] = useMemo(() => {
    const data =
      countries &&
      countries.reduce((t: ISelectCountry[], c) => {
        t.push({ label: c.name, value: c.alpha2 });
        return t;
      }, []);
    return data || [];
  }, [countries]);

  const formSubmit = (data: IChangePhoneVerifyPayload) => {
    toast.promise(useChangePhoneMutation.mutateAsync(data), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => {
        modal.remove();
        return res.message ?? "Operation successful";
      },
    });
  };

  const reactSelectSX =
    themeMode === "dark" ? reactSelectDarkInPopupStyles : reactSelectStyles;

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography color="primary" fontSize={18} fontWeight={500}>
          Change Phone
        </Typography>
        <div className="h-8"></div>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <FormLabel>Dial Code</FormLabel>
              <div className="h-2"></div>
              <Controller
                name="dialCode"
                rules={{ required: "Dial Code is required" }}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    <Select
                      placeholder="Select Dial Code"
                      isLoading={isLoadingCountries}
                      styles={reactSelectSX}
                      instanceId="dial-code"
                      options={countryList}
                      onChange={(e) =>
                        field.onChange({ target: { value: e?.value } })
                      }
                    />
                    <FormHelperText error={invalid}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </div>
            <div>
              <FormLabel>Phone</FormLabel>
              <div className="h-2"></div>
              <Controller
                name="phone"
                rules={{ required: "Phone is required" }}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <FormControl error={invalid} fullWidth>
                    <TextField
                      value={field.value}
                      onChange={(e) =>
                        field.onChange({
                          target: {
                            value: e.target.value.replace(/[^0-9]/g, ""),
                            name: e.target.name,
                          },
                        })
                      }
                      placeholder="Phone"
                      error={invalid}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="h-8"></div>
          <FormLabel>Password</FormLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  type={showPass ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPass ? (
                          <AiFillEye
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        ) : (
                          <AiFillEyeInvisible
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
          />
          <div className="h-20"></div>
          <LoadingButton
            loading={useChangePhoneMutation.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Change
          </LoadingButton>
        </form>
        {useChangePhoneMutation.isError && (
          <Alert sx={{ mt: 3 }} color="error" severity="error">
            {useChangePhoneMutation?.error?.message}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
});
