import { Card, Grow, Skeleton, Typography } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewKryptobetWalletProps = {
  isLoading?: boolean;
  balance: number;
  totalWithdrawals: number;
};

const OverviewKryptobetWallet: FC<OverviewKryptobetWalletProps> = ({
  isLoading,
  balance,
  totalWithdrawals,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Grow in>
      <Card
        elevation={10}
        className="max-w-sm md:max-w-full flex flex-col gap-4 !bg-gradient-to-br from-[#DC4A95] to-[#8D337A]"
        sx={{ color: "white", p: 3 }}
      >
        <div className="flex justify-between items-center">
          <Typography variant="subtitle1">Available Balance</Typography>
          <Link to={"/agent/" + RouteLinks.WALLETS.link}>
            Transaction History
          </Link>
        </div>

        {isLoading ? (
          <Typography variant="h4">
            <Skeleton variant="text" />
          </Typography>
        ) : (
          <>
            <Typography variant="h4" fontWeight={500}>
              {currencyFormatter({ value: balance, decimalPlaces: 2 }) +
                (user.data?.country === "PH" ? " PHP" : " USD")}
            </Typography>
          </>
        )}

        <div className="flex justify-between items-center">
          <Typography variant="subtitle1" fontWeight={400}>
            Total Withdrawals
          </Typography>

          {isLoading ? (
            <Typography variant="subtitle1">
              <Skeleton variant="text" width={100} />
            </Typography>
          ) : (
            <Typography variant="subtitle1" fontWeight={400}>
              {totalWithdrawals.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              {user.data?.country === "PH" ? " PHP" : " USD"}
            </Typography>
          )}
        </div>

        <div className="flex-1" />

        {/* <Typography variant="caption" textAlign="start">
          Note : Total commissions are the losing Game Credits of your players
        </Typography> */}
      </Card>
    </Grow>
  );
};

export default OverviewKryptobetWallet;
