import { LoadingButton } from "@mui/lab";
import { Alert, TextField, Typography } from "@mui/material";
import Logo from "assets/icons/logo-dark.png";
import { IVerifyUserPayload, useVerifyUser } from "queries/auth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {}

interface IRouteState {
  traceId: string;
}
const EmailVerificationPage = (props: Props) => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const routeState = state as IRouteState;
  const useVerifyUserMudation = useVerifyUser({
    onSuccess: (data) => {
      toast.success(data?.message ?? "Operation successful");
      navigate("/");
    },
  });
  const [code, setCode] = useState<number | string>("");
  const onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const payload: IVerifyUserPayload = {
      traceId: routeState?.traceId,
      emailVerifyCode: code,
    };
    useVerifyUserMudation.mutate(payload);
  };

  useEffect(() => {
    if (!routeState?.traceId) {
      navigate("/");
    }
  }, [navigate, routeState?.traceId]);

  if (!routeState?.traceId) {
    return <></>;
  }

  return (
    <div className="flex min-h-screen bg-black">
      <div className="p-3 sm:min-h-screen  w-full m-auto max-w-[450px] overflow-hidden flex items-center justify-center">
        <div className="px-4 lg:px-12 py-4 lg:pt-12 xl:pt-16 lg:pb-12 xl:pb-20 bg-[#2c2e2c] rounded-2.5xl w-full xl:w-[600px]">
          <div className="mb-10 lg:mb-16 text-center">
            <img
              className="inline-block mb-12 mt-3"
              width={150}
              src={Logo}
              alt="logo"
            />
            <h1 className="text-xl md:text-2xl xl:text-3xl text-white font-semibold">
              Verify your Email
            </h1>

            <div className="h-2" />

            <Typography color="white">
              A verification code has been sent to your email
            </Typography>
          </div>
          <form
            className="flex flex-col gap-3 lg:gap-6 w-full select-none"
            onSubmit={onSubmit}
          >
            <TextField
              // label="Verification Code"
              sx={{
                background: "#fff",
                borderRadius: 1,
                input: { color: "black" },
              }}
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value.replace(/[^0-9]/g, ""))}
              placeholder="Verification Code"
            />

            <LoadingButton
              variant="contained"
              disableElevation
              color="primary"
              fullWidth
              type="submit"
              className="!bg-primary"
              loading={useVerifyUserMudation.isLoading}
            >
              Verify Now
            </LoadingButton>

            <div className="h-8" />

            {useVerifyUserMudation.isError && (
              <Alert severity="error">
                {useVerifyUserMudation.error.message}
              </Alert>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
