import { RankStatus } from "queries/rank";
import { TransactionStatusType } from "queries/wallets";

type IColorSchemaProps =
  | "default"
  | "error"
  | "info"
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | undefined;

type ValueType = RankStatus | TransactionStatusType;

const successTtype: ValueType[] = ["approved", "confirmed"];
const warningTtype: ValueType[] = ["pending"];
const errorTtype: ValueType[] = ["declined"];

export const getColorSchema = (value: ValueType | any): IColorSchemaProps => {
  if (successTtype.includes(value)) {
    return "success";
  } else if (warningTtype.includes(value)) {
    return "warning";
  } else if (errorTtype.includes(value)) {
    return "error";
  }
  return "default";
};

enum twoDecimalCoinEnum {
  usdc = "usdc",
  usdt = "usdt",
}

export function getDecimalOnCoin(coin: string) {
  if (!coin) return 0;
  return coin?.toLowerCase() === twoDecimalCoinEnum?.usdc ||
    coin.toLowerCase() === twoDecimalCoinEnum?.usdt
    ? 2
    : 8;
}

const criterias: CriteriaSymbol[] = [
  "withdraw",
  "commission",
  "deposit",
  "purchase",
  "loss",
  "win",
];

export function StringToValidCriteria(criteria: any) {
  if (criterias.includes(criteria)) {
    return criteria;
  }
  return "withdraw";
}

export function getQrStr(companyName: string, email: string, secret: string) {
  if (!secret) return null;
  return `otpauth://totp/${companyName}:${email}?secret=${secret}&issuer=${companyName}&algorithm=SHA1&digits=6&period=30`;
}

/**
 * @name purifyAmount
 * @description get only number in amount formate.
 * @param {string} amount
 *
 * @returns {string} as amount which holds only number.
 */
export function purifyAmount(amount: string) {
  // return back with same data if data is not string
  if (typeof amount !== "string") return amount;
  const purifiedValue = amount
    .replace(/[^0-9.]/g, "")
    .replace(/(\..*)\./g, "$1");
  return purifiedValue;
}
