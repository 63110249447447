import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { TransactionWithdraw } from "interfaces/agent/transaction";
import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface WithdrawRequestsResp extends IPagination {
  docs: TransactionWithdraw[];
}

export const useWithdrawRequestsQuery = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.withdraw.withdrawalRequestList;
  return useInfiniteQuery<WithdrawRequestsResp, Error>(
    [QUERY_KEYS.withdrawRequestList, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

// Approve Withdraw Request mutation:
interface IWithdrawApproveInputs {
  id: string;
}
interface IWithdrawApproveResp {
  status: string;
  message: string;
}

export const useApproveWithdrawRequestMutation = () => {
  return useMutation<IWithdrawApproveResp, Error, IWithdrawApproveInputs>(
    async (payload) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AGENT.withdraw.accept + payload.id,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.withdrawRequestList);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

// Approve Withdraw Request mutation:
export interface IWithdrawDeclinePayload {
  id: string;
  rejection_note: string;
}
interface IWithdrawDeclineResp {
  status: string;
  message: string;
}

export const useDeclineWithdrawRequestMutation = () => {
  return useMutation<IWithdrawDeclineResp, Error, IWithdrawDeclinePayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AGENT.withdraw.decline + payload.id,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.withdrawRequestList);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
