import { Divider, Skeleton, Typography } from "@mui/material";
import { CardUI } from "components/card";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import { FC, useContext } from "react";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewMyCommissionsProps = {
  isLoading: boolean;
  cw?: number;
  cl?: number;
  lastCutOffDate?: Date;
  nextCutOffDate?: Date;
};

const OverviewMyCommissions: FC<OverviewMyCommissionsProps> = ({
  isLoading,
  cw = 0,
  cl = 0,
  lastCutOffDate,
  nextCutOffDate,
}) => {
  const { user } = useContext(AuthContext);
  const currentCommission = cl - cw;
  const lastCutOffDateformated = lastCutOffDate
    ? moment.utc(lastCutOffDate).format("DD-MM-YYYY")
    : "...";
  const nextCutOffDateformated = nextCutOffDate
    ? moment.utc(nextCutOffDate).format("DD-MM-YYYY")
    : "...";
  return (
    <CardUI className="flex flex-col gap-2">
      <Typography>Commission</Typography>

      <div className="flex justify-between gap-4">
        <Typography variant="subtitle1">CW</Typography>
        <Typography variant="subtitle1" color="error.main">
          {isLoading ? (
            <Skeleton variant="text" width={96} />
          ) : (
            "-" +
            currencyFormatter({ value: cw, decimalPlaces: 2 }) +
            (user.data?.country === "PH" ? " PHP" : " USD")
          )}
        </Typography>
      </div>
      <div className="flex justify-between gap-4">
        <Typography variant="subtitle1">CL</Typography>
        <Typography
          variant="subtitle1"
          color={cl < 0 ? "error.main" : "success.main"}
        >
          {isLoading ? (
            <Skeleton variant="text" width={96} />
          ) : (
            currencyFormatter({ value: cl, decimalPlaces: 2 }) +
            (user.data?.country === "PH" ? " PHP" : " USD")
          )}
        </Typography>
      </div>

      <Divider sx={{ borderStyle: "dashed", borderWidth: "1px" }} />

      <div className="flex justify-between gap-4">
        <Typography variant="subtitle1">Current Commission</Typography>
        <Typography
          variant="subtitle1"
          color={currentCommission < 0 ? "error.main" : "success.main"}
        >
          {isLoading ? (
            <Skeleton variant="text" width={96} />
          ) : (
            currencyFormatter({ value: currentCommission, decimalPlaces: 2 }) +
            (user.data?.country === "PH" ? " PHP" : " USD")
          )}
        </Typography>
      </div>

      <div className="h-4" />

      <div className="flex justify-between">
        <Typography>
          Last Cut off Date
          <br />
          {isLoading ? <Skeleton variant="text" /> : lastCutOffDateformated}
        </Typography>
        <Typography>
          Next Cut off Date
          <br />
          {isLoading ? <Skeleton variant="text" /> : nextCutOffDateformated}
        </Typography>
      </div>
    </CardUI>
  );
};

export default OverviewMyCommissions;
