import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import { createContext, FC, useContext, useState } from "react";
import { COMPONENT_THEME } from "../theme/component_themes";
import { PALETTE_DARK, PALETTE_DEFAULT } from "../theme/palettes";

const baseThemeData = createTheme({
  palette: PALETTE_DEFAULT,
  components: { ...COMPONENT_THEME.components },
  typography: {
    fontFamily: "Work Sans, Sofia Pro, Roboto, consolas",
  },
});

const themeDataLight = createTheme({
  palette: PALETTE_DEFAULT,
  components: { ...baseThemeData.components },
  typography: { ...baseThemeData.typography },
});

const themeDataDark = createTheme({
  palette: PALETTE_DARK,
  components: { ...baseThemeData.components },
  typography: { ...baseThemeData.typography },
});

export function useThemeContext() {
  return useContext(ThemeContext);
}

type ThemeMode = "light" | "dark";
interface ThemeInterface {
  themeMode: ThemeMode;
  toggleMode: () => void;
}

export const ThemeContext = createContext({} as ThemeInterface);

function getThemeFromStorage(): ThemeOptions {
  if (localStorage.getItem("theme")) {
    const theme = localStorage.getItem("theme") as ThemeMode;
    if (theme === "dark") {
      return themeDataDark;
    }

    return themeDataLight;
  }

  return themeDataDark;
}

function getThemeModeFromStorage(): ThemeMode {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme") as ThemeMode;
  }

  localStorage.setItem("theme", "dark" as ThemeMode);

  return "dark";
}

const ThemeContextProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<ThemeOptions>(getThemeFromStorage());
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    getThemeModeFromStorage()
  );

  const toggleMode = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    const newTheme = newMode === "light" ? themeDataLight : themeDataDark;

    setThemeMode(newMode);
    setTheme(newTheme);

    localStorage.setItem("theme", newMode);
  };

  return (
    <ThemeContext.Provider
      value={{
        themeMode: themeMode,
        toggleMode: toggleMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
