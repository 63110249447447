import TransactionHistoryDetailsWraper from "components/transactionDetailsSocketWrapper";
import { useParams } from "react-router-dom";
import UserPaymentStatusCard from "./components/UserPaymentStatusCard";

export default function TransactionHistoryDetailsPage() {
  const { id } = useParams();
  return (
    <TransactionHistoryDetailsWraper id={id ?? ""}>
      {({ transactionDetails }) => (
        <UserPaymentStatusCard data={transactionDetails} />
      )}
    </TransactionHistoryDetailsWraper>
  );
}
