export const API_ENDPOINTS_AGENT = {
  agentDownlineManagement: {
    downlineList: "/agent/downlineManagement/userList",
    block: "/agent/downlineManagement/block",
    unblock: "/agent/downlineManagement/unblock",
    upgrade: "/agent/downlineManagement/upgrade",
  },
  activity: { userGamingActivity: "/agent/activity/gameActivity" },
  rankUpgrade: {
    rankUpgradeRequestHistory: "/agent/rank/rankUpgradeRequestHistory",
    approve: "/agent/rank/approve/",
    decline: "/agent/rank/decline/",
  },
  withdraw: {
    withdrawalRequestList: "/agent/withdraw/requestList",
    accept: "/agent/withdraw/accept/",
    decline: "/agent/withdraw/decline/",
    details: "/agent/withdraw/details/",
    markAsPaid: "/agent/withdraw/markAsPaid/",
  },
  gameCredit: {
    gameCreditRequests: "/agent/gameCredit/requestHistory",
    approve: "/agent/gameCredit/approve/",
    decline: "/agent/gameCredit/decline/",
  },
  myCommission: {
    overview: "/agent/commission/overview",
    history: "/agent/commission/history",
    cutoffHistory: "/agent/commission/cutoffHistory",
  },
  wallet: { transactionHistory: "/agent/wallet/transactionHistory" },
  paymentMethod: {
    list: "/agent/paymentMethod/list",
    create: "/agent/paymentMethod/create",
    update: "/agent/paymentMethod/update",
    delete: "/agent/paymentMethod/delete",
  },
  public: { fiatCurrencies: "/public/fiatCurrency" },
  tool: "/agent/tool",
  gameReport: "/agent/report/game",
  agentCommissions: "/agent/report/commission",
};

export const API_ENDPOINTS_USER = {
  countryList: "utils/countryList",
  dashboardAnalytics: "user/dashboard",
  gameList: "public/gameList",
  gameCreditPurchase: "wallet/gameCredit/purchaseRequest",
  gameCreditHistory: "wallet/gameCredit/myHistory",
  companies: "public/companies",
  rankMyHistory: "/rank/myHistory",
  rankList: "/utils/rankList",
  tool: "/user/tool",
  wallet: {
    releaseWithdrawal: "/wallet/withdraw/release/",
    disputeWithdrawal: "/wallet/withdraw/dispute/",
  },
};

export const API_ENDPOINTS_AUTH = {
  resetPassword: {
    createResetPasswordCode: "/resetPassword/resetPasswordRequest",
    verifyPasswordReset: "/resetPassword/verifyPasswordReset",
    submitPasswordReset: "/resetPassword/submitPasswordReset",
  },
  login: "auth/login",
  register: "auth/register/submit",
  verify: "auth/register/verify",
  profile: {
    me: "/profile/me",
    updateInformation: "profile/updateProfileInformation",
    uploadPhoto: "profile/uploadProfilePhoto",
    invite: "profile/invite",
    switchGoogleAuthenticator: "security/switchGoogleTwoFA",
    requestToUnsetGoogleAuthenticator:
      "security/requestToUnsetGoogleAuthenticator",
    unsetGoogleAuthenticator: "security/unsetGoogleAuthenticator",
  },
};
