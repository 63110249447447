import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { useRequestToUnset2faMutation } from "queries/security";
import React from "react";
import toast from "react-hot-toast";

export default NiceModal.create(() => {
  const modal = useModal();
  const useRequestToUnset2fa = useRequestToUnset2faMutation();

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography fontSize={18} color="primary.main" fontWeight={500}>
          Request to unset Google Authenticator
        </Typography>
        <div className="h-8"></div>
        <Typography>
          Verifation code will be sent to your email account. please check you
          email.
        </Typography>
        <div className="text-right">
          <LoadingButton
            onClick={() => {
              const unsetToastId = toast.loading("Requesting to unset...");
              useRequestToUnset2fa
                .mutateAsync()
                .then((res) => {
                  toast.success(res?.message, {
                    id: unsetToastId,
                  });
                  modal.resolve(res?.traceId);
                })
                .catch((ex) => {
                  toast.error(ex.toString(), {
                    id: unsetToastId,
                  });
                })
                .finally(() => {
                  modal.remove();
                });
            }}
            sx={{ mt: 3 }}
            variant="outlined"
            size="small"
            loading={useRequestToUnset2fa.isLoading}
          >
            Reqeuest To Unset
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
});
