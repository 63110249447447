import { Card, Skeleton, Typography } from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewAvailableGCProps = {
  isLoading?: boolean;
  balance?: number | undefined;
  equivalentAmount?: number | undefined;
};

const OverviewAvailableGC: FC<OverviewAvailableGCProps> = ({
  isLoading,
  balance,
  equivalentAmount,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Card
      elevation={10}
      className="flex flex-col gap-4 !bg-gradient-to-br from-[#6354E1] to-[#270C5C]"
      sx={{ color: "white", p: 3 }}
    >
      <Typography variant="subtitle1">Available Game Credits</Typography>

      {isLoading ? (
        <Typography variant="h4">
          <Skeleton variant="text" />
        </Typography>
      ) : (
        <>
          <Typography variant="h4" fontWeight={500}>
            {currencyFormatter({
              value: user.data?.country === "PH" ? equivalentAmount : balance,
            }) + (user.data?.country === "PH" ? " PHP" : " USD")}
          </Typography>
        </>
      )}

      <div className="flex-1" />

      <Link to="">Purchase History </Link>
    </Card>
  );
};

export default OverviewAvailableGC;
