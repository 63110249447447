import { Button, Tooltip, Typography } from "@mui/material";
import { ICompany } from "queries/lottery";

type Props = {
  data: ICompany;
};

export default function LotteryCard({ data }: Props) {
  return (
    <div>
      <div className="mb-5 grid place-items-center p-10 border border-gray-200 border-solid rounded-3xl">
        <img className="w-40" src={data?.logo} alt="game" />
      </div>
      <Typography sx={{ mb: 1 }} variant="h5">
        {data?.name}
      </Typography>
      <Typography component="p">{data?.description}</Typography>
      <Tooltip title="Coming soon">
        <Button variant="outlined" sx={{ mt: 3 }}>
          {/* <a href="www.w.com" className="text-inherit"> */}
          Play Now
          {/* </a> */}
        </Button>
      </Tooltip>
    </div>
  );
}
