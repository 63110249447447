import { useModal } from "@ebay/nice-modal-react";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import gamePlayPopup from "components/modals/gamePlayPopup";
import { MuiToolTip } from "components/tooltip";
import { IUser } from "context/AuthContext";
import { ICasinoGames, IGameResp, useGame } from "queries/games";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import hotIcon from "../../assets/icons/hot.png";

type Props = {
  data: ICasinoGames;
  user: IUser | null;
};

export default function GameCard({ data, user }: Props) {
  const navigate = useNavigate();
  const useGameMutation = useGame({
    onSuccess: (data: IGameResp) =>
      data?.response
        ? gamePlay.show({ gameUrl: data?.response, navigate })
        : toast.error(data?.message ?? "Game Not Found"),
  });
  const gamePlay = useModal(gamePlayPopup);
  // const getGame = async () => {
  //   if (user) {
  //     useGameMutation.mutate({
  //       method: "getGame",
  //       gameid: data?.id,
  //       user_username: user?.gamingUsername,
  //       user_password: user?.gamingPassword,
  //       currency: user?.gamingCurrency,
  //       lang: "en",
  //     });
  //   } else {
  //     toast.error("User coundn't verify please try after refresh!");
  //   }
  // };

  return (
    <div className="grid grid-rows-1 relative border border-primary border-opacity-20 rounded-lg hover:shadow-sm transition-shadow p-3">
      <div>
        {data?.image_preview && (
          <div className="mb-3">
            <img src={data?.image_filled} alt="game" />
          </div>
        )}
        {data?.isHot === "yes" && (
          <img
            width={30}
            className="absolute right-4 top-4"
            src={hotIcon}
            alt="hot-game"
          />
        )}
        <Typography sx={{ mb: 0.5 }} variant="h5">
          {data?.name}
        </Typography>
        <Typography component="p">{data?.category}</Typography>
      </div>
      <MuiToolTip title="Unavailable">
        {/* onClick={getGame} */}
        <Button variant="contained" sx={{ mt: 2 }}>
          Play
        </Button>
      </MuiToolTip>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={useGameMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
