
import { privateRequest } from "config/axios.config"
import { useQuery, useMutation } from "react-query"
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints"
import { axiosErrorHandler } from "utils/errorHandler"
import queryClient from "config/queyClient.config"
import toast from "react-hot-toast"

export const FETCH_AGENT_TOOLS_DATA = "FETCH_AGENT_TOOLS_DATA"
export const FETCH_AGENT_SPECIFIC_TOOLS_DATA = "FETCH_AGENT_SPECIFIC_TOOLS_DATA"
export const DELETE_AGENT_TOOLS_DATA = "DELETE_AGENT_TOOLS_DATA"

/* Tools lists */
export const useToolsRequestsQuery = () => {
  return useQuery<IRToolListResponse[], Error>(FETCH_AGENT_TOOLS_DATA, async () => {
    try {
      const API_URL = API_ENDPOINTS_AGENT.tool + "/list"
      const response = await privateRequest.get(API_URL)
      return response?.data?.data
    } catch (error) {
      axiosErrorHandler(error);
    }
  })
}

/* Create new tools */
interface IRToolsInputs {
  banner: any;
  title: string;
  description: string;
}

interface IRToolsCreateResp {
  status: boolean;
  message: string;
}
export const useToolCreateMutation = () => {
  return useMutation<IRToolsCreateResp, Error, IRToolsInputs>(
    async (payload) => {
      try {
        const API_URL = API_ENDPOINTS_AGENT.tool + "/create"
        const response = await privateRequest.post(API_URL, payload)
        return response?.data
      } catch (error) {
        axiosErrorHandler(error)
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FETCH_AGENT_SPECIFIC_TOOLS_DATA)
        queryClient.invalidateQueries(FETCH_AGENT_TOOLS_DATA)
        toast.success(data.message)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  );
}

/* Show specific tool */
export const useToolShowQuery = (id: string) => {
  return useQuery<IRToolListResponse, Error>(FETCH_AGENT_SPECIFIC_TOOLS_DATA,
    async () => {
      try {
        const API_URL = API_ENDPOINTS_AGENT.tool + "/show/" + id
        const response = await privateRequest.get(API_URL)
        return response?.data?.data
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  )
}

/* Update specific tool */
export const useToolUpdateMutation = () => {
  return useMutation<IRToolsCreateResp, Error, IRToolsInputs>(
    async (payload) => {
      try {
        const API_URL = API_ENDPOINTS_AGENT.tool + "/update"
        const response = await privateRequest.post(API_URL, payload)
        return response?.data
      } catch (error) {
        axiosErrorHandler(error)
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FETCH_AGENT_SPECIFIC_TOOLS_DATA)
        queryClient.invalidateQueries(FETCH_AGENT_TOOLS_DATA)
        toast.success(data.message)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  );
}

/* Destroy specific tool */
interface IRToolsDestroyInputs {
  id: string;
}
interface IRToolsDestroyResp {
  status: string;
  message: string;
}

export const useToolDestroyMutation = () => {
  return useMutation<IRToolsDestroyResp, Error, IRToolsDestroyInputs>(
    async (payload) => {
      try {
        const API_URL = API_ENDPOINTS_AGENT.tool + "/delete/" + payload.id
        const response = await privateRequest.delete(API_URL)
        return response?.data
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_AGENT_SPECIFIC_TOOLS_DATA)
        queryClient.invalidateQueries(FETCH_AGENT_TOOLS_DATA)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  );
};