import { createContext, FC, useContext } from "react";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_BASE_URL || "", {
  path: "/socket",
  autoConnect: false,
});

export const SocketContext = createContext({
  socket,
});

interface Props {}
const SocketContextProvider: FC<Props> = ({ children }) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;

export const useSockets = () => useContext(SocketContext);
