import { InputAdornment, InputLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { FC } from "react";

type DatePickerProps = {
  name?: string;
  fullWidth?: boolean | undefined;
  label?: string;
  placeholder?: string | undefined;
  value?: Date | null;
  onChange?: (date: Date | null) => void;
};

const DatePicker: FC<DatePickerProps> = ({
  name,
  fullWidth = true,
  label,
  placeholder = "mm/dd/yyy",
  value,
  onChange = (date: Date | null) => {
    console.log("");
  },
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <>
              {label && <InputLabel>{label}</InputLabel>}
              <TextField
                type="search"
                name={name}
                fullWidth={fullWidth}
                // label={label}
                inputRef={params.inputRef}
                inputProps={{ ...params.inputProps, placeholder: placeholder }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: "12px" }}>
                      {params.InputProps?.endAdornment}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
