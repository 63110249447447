import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import avatar from "assets/icons/avatar.png";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useThemeContext } from "context/ThemeContext";
import React, { FC } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { MdDarkMode } from "react-icons/md";
import { Link } from "react-router-dom";
import { BackButtonLinkType } from "./Drawer";

interface AppBarProps {
  handleDrawerToggle: () => void;
  backButtonLink: BackButtonLinkType;
}

const AppBar: FC<AppBarProps> = ({ handleDrawerToggle, backButtonLink }) => {
  const themeContext = useThemeContext();

  const { logout, user } = React.useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <MuiAppBar position="sticky" elevation={4}>
      <Toolbar sx={{ py: 2 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: "none" } }}
        >
          <AiOutlineMenu />
        </IconButton>
        {backButtonLink && (
          <IconButton
            component={Link}
            to={"backButtonLink"}
            sx={{
              borderRadius: 1,
              mr: "auto",
              "&:hover": { bgcolor: "transparent" },
            }}
          >
            <HiOutlineArrowSmLeft />
            <span className="ml-2 text-d-black font-semibold text-sm">
              Back
            </span>
          </IconButton>
        )}
        <Box
          sx={{
            flexGrow: 0,
            flexWrap: "wrap",
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <p className="text-primary hidden sm:inline-block">
            {user?.data?.username}
          </p>

          <Tooltip title="Toggle Dark Mode">
            <IconButton
              className="!mx-4"
              onClick={() => {
                themeContext.toggleMode();
              }}
            >
              <MdDarkMode />
            </IconButton>
          </Tooltip>

          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src={user?.data?.photo ?? avatar} />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            classes={{ root: "dropdownRootStyle" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <Link className="!text-[14px]" to={RouteLinks.PROFILE.link}>
                Profile
              </Link>
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} onClick={() => logout()}>
              Log out
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
