import {
  Alert,
  Skeleton,
  // TextField,
  // IconButton
} from "@mui/material";
import { SectionTitle } from "components/title";
import LotteryCard from "components/uiStyle/lotteryCard";
import SpaceBar from "components/uiStyle/spaceBar";
import { useCompany } from "queries/lottery";
// import { BiSearch } from "react-icons/bi";

export default function LotteryPage() {
  const { data, isError, isLoading } = useCompany();
  
  if (isError) return <Alert severity="error">No Data Found</Alert>;

  const companies = (data && data?.length > 0 && data) || [];

  return (
    <>
      <SectionTitle.Default title="Lottery">
        {/* <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          value=""
          onChange={() => { }}
        /> */}
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      {!isLoading && companies?.length <= 0 && (
        <Alert severity="error">No Data Found</Alert>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
        {companies.map((company, i) => (
          <LotteryCard key={i} data={company} />
        ))}
        {isLoading &&
          [...Array(6)].map((_, i) => (
            <div key={i}>
              <div className="mb-5 grid place-items-center p-10 border border-gray-200 border-solid rounded-3xl">
                <Skeleton
                  height={140}
                  width={140}
                  variant="circular"
                ></Skeleton>
              </div>
              <div className="h-3"></div>
              <Skeleton height={30} width={150} variant="text"></Skeleton>
              <div className="h-6"></div>
              <Skeleton
                height={45}
                width={100}
                variant="rectangular"
              ></Skeleton>
            </div>
          ))}
      </div>
    </>
  );
}
