import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RankChip from "components/chips/RankChip";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import { SectionTitle } from "components/title";
import { RankSymbol } from "interfaces/agent/rank";
import _ from "lodash";
import { useAgentCommissionsQuery } from "queries/agent/agent_commissions_query";
import queryString from "query-string";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";

type Props = {};

const AgentCommissionPage = (props: Props) => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [rank, setRank] = useState<RankSymbol | undefined>();
  const [search, setSearch] = useState("");

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    console.log(search);

    const parsed = queryString.parse(query);

    if (rank) parsed.rank = rank;
    else delete parsed.rank;

    if (search) parsed.search = search;
    else delete parsed.search;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    else setQuery("");

    return () => debounceChangeSearch.cancel();
  }, [debounceChangeSearch, query, rank, search]);

  const { data, isLoading, isError, error } = useAgentCommissionsQuery({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Agent Commissions">
        <Select
          value={rank}
          displayEmpty
          label="Rank"
          onChange={(event: SelectChangeEvent<RankSymbol>) => {
            setRank(event.target.value as RankSymbol);
          }}
        >
          <MenuItem value={undefined}>All Ranks</MenuItem>
          <MenuItem value="ae">AE</MenuItem>
          <MenuItem value="jam">JAM</MenuItem>
          <MenuItem value="sam">SAM</MenuItem>
          <MenuItem value="eam">EAM</MenuItem>
        </Select>

        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: <IconButton children={<BiSearch />} />,
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>

      <div className="h-4" />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="right">Balance</TableCell>
              <TableCell align="right">Current Commissions</TableCell>
              <TableCell align="right">Total Commissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={5} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((commission, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{commission.user}</TableCell>
                    <TableCell align="center">
                      <RankChip rank={commission.rank} />
                    </TableCell>
                    <TableCell align="right">
                      {commission.balance.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {commission.currentCommission.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {commission.totalCommission.toFixed(2)}
                    </TableCell>
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={5} />}
            {isError && <TableError colSpan={5} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default AgentCommissionPage;
