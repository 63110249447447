const QUERY_KEYS = {
  agentDownlineList: "agent-downline-list",
  userGamingActivity: "user-gaming-activity",
  rankUpgradeRequestHistory: "rank-upgrade-request-history",
  gameCreditRequests: "game-credit-requests",
  withdrawRequestList: "withdraw-request-list",
  transactionHistory: "transaction-history",
  user_profile: "fetch-user",
  countryList: "fetch-country-list",
  dashboardAnalytics: "fetch-dashboard-analytics",
  casinoGames: "fetch-casino-games",
  allGamesList: "fetch-games-list",
  gameCreditHistory: "fetch-game-credit-history",
  fetchCompany: "fetch-company",
  rankUpgradeHistory: "fetch-rank-upgrade-history",
  rankList: "fetch-rank-list",
  getGoogleTwoFASecret: "get-google-two-fa-secret",
  paymentMethodList: "payment-method-list",
  fiatCurrencyList: "fiat-currency-list",
  myCommissionOverview: "my-commission-overview",
  cutoffHistory: "cut-off-history",
  withdrawDetails: "withdraw-details",
  gameReport: "game-report",
  agentCommissions: "agent-commissions",
};

export default QUERY_KEYS;
