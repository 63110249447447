import {
  Chip,
  colors,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TablePagination from "components/table/TablePagination";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import _ from "lodash";
import queryString from "query-string";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getColorSchema } from "utils";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";
import { useTransactionHistory } from "../../../../queries/agent/wallet.query";

const TransactionHistory = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("&criteria=withdraw"); //Only withdraw items needs to be fetched
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate]);

  const { data, isLoading, isError } = useTransactionHistory({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Recent Transaction">
        <div className="flex gap-2 items-center">
          <DatePicker
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={6} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defaultDateFormat(item.createdAt.toString())}
                    </TableCell>
                    <TableCell>{item.txid}</TableCell>
                    <TableCell align="center">
                      <Chip label={item.criteria} />
                    </TableCell>
                    <TransactionValueCell
                      type={item.transactionType}
                      data={item}
                    />
                    <TableCell>
                      {item?.criteria === "withdraw" ? (
                        <>
                          {item?.paymentMethod?.type?.toUpperCase()} (
                          <span className="text-primary px-1">
                            {item?.paymentMethod?.currency?.toUpperCase()}
                          </span>
                          ){" "}
                          {item?.paymentMethod?.type === "crypto"
                            ? item?.address
                            : item?.paymentDetails}
                        </>
                      ) : (
                        item?.note
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={item?.status}
                        color={getColorSchema(item?.status)}
                      />
                    </TableCell>
                  </TableRow>
                );
              });
            })}
            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <Typography color={colors.red[500]}>Error</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default TransactionHistory;
