import { useModal } from "@ebay/nice-modal-react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ConfirmationModal } from "components/modals/confirmation.modal";
import { ToolListSkeleton } from "components/skeletons/tool.skeleton";
import { AddCard, ToolsCard } from "components/uiStyle/toolsCard";
import {
  useToolDestroyMutation,
  useToolsRequestsQuery,
} from "../../../queries/agent/tools.query";

const AgentToolsPage = () => {
  const confirmationModalHandeller = useModal(ConfirmationModal);
  const { data, isLoading } = useToolsRequestsQuery();
  const { isLoading: destroyLoading, mutateAsync } = useToolDestroyMutation();

  /* Handle modal */
  const handleModal = async (id: string) => {
    confirmationModalHandeller
      .show({
        title: "Are you sure you want to delete this?",
        mode: "error",
        submitButtonTitle: "Delete",
      })
      .then(async () => {
        if (id) {
          await mutateAsync({ id: id });
        }
      });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-7 md:gap-8">
      {isLoading ? <ToolListSkeleton items={3} /> : null}
      <Backdrop open={destroyLoading} sx={{ zIndex: 9999 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      {data && data.length > 0 && !isLoading
        ? data.map((item, i) => (
            <ToolsCard
              key={i}
              data={{
                _id: item._id,
                title: item.title,
                description: item.description,
                banner: item.banner,
                createdAt: item.createdAt,
              }}
              onDelete={(id) => handleModal(id)}
            />
          ))
        : null}

      <AddCard />
    </div>
  );
};

export default AgentToolsPage;
