import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useState } from "react";
import DisputedHistory from "./components/DisputedHistory";
import InProgressHistory from "./components/InProgressHistory";
import PendingWithdrawalHistory from "./components/PendingWithdrawalsHistory";

type Tabs = "pending" | "inProgress" | "disputed";

const AgentPendingWithdrawalsPage = () => {
  const [tab, setTab] = useState<Tabs>("pending");

  return (
    <>
      <TabContext value={tab}>
        <TabList onChange={(event, value) => setTab(value)}>
          <Tab label="Pending" value="pending" />
          <Tab label="In Progress" value="inProgress" />
          <Tab label="Disputed" value="disputed" />
        </TabList>

        <div className="h-4" />

        <TabPanel value="pending" sx={{ padding: 0 }}>
          <PendingWithdrawalHistory />
        </TabPanel>

        <TabPanel value="inProgress" sx={{ padding: 0 }}>
          <InProgressHistory />
        </TabPanel>

        <TabPanel value="disputed" sx={{ padding: 0 }}>
          <DisputedHistory />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AgentPendingWithdrawalsPage;
