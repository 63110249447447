import { publicRequest } from "config/axios.config";
import { API_ENDPOINTS_USER } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface ICompany {
  logo: string;
  lotteries: string[];
  country: string;
  createdAt: string;
  description: string;
  drawDate: string;
  luckyNumber: string;
  name: string;
  nextJackpot: number;
  rank: string;
  rating: string;
  siteUrl: string;
  status: string;
  ticketClass: string;
}

export const useCompany = () => {
  return useQuery<ICompany[], Error>(QUERY_KEYS.fetchCompany, async () => {
    try {
      const resp = await publicRequest.get(API_ENDPOINTS_USER.companies);
      return resp?.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};
