import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import React, { FC } from "react";
import { IoDocumentText } from "react-icons/io5";

type NoTransactionsProps = {
  colSpan: number;
};

const NoTransactions: FC<NoTransactionsProps> = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={colSpan}>
        <Stack
          direction="column"
          alignItems="center"
          color="gray"
          padding="16px"
          gap="8px"
        >
          <IoDocumentText size={48} />
          <Typography>No Transactions Yet!</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default NoTransactions;
