import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AUTH } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { IUser, UserRoleTypes } from "context/AuthContext";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "./../../utils/errorHandler";

// * function name should be uppercased as we are calling hooks inside functions

interface ILoginInputs {
  username: string;
  password: string;
  otp?: string;
  "g-recaptcha-response": string;
}

interface ILoginResp {
  error: {
    otp: string[];
  };
  status: string;
  data: {
    username: string;
    token: string;
    flag: "verify_email";
    google_authenticator: number;
    role: UserRoleTypes;
  };
  message: string;
}

interface IUseLogin {
  onSuccess?: (
    token: string,
    keepLogedin: boolean,
    role: UserRoleTypes
  ) => void;
  keepLogedin: boolean;
  onFail?: Function;
  onOtpReuired?: Function;
}

export const useLogin = (params: IUseLogin) => {
  return useMutation<ILoginResp, Error, ILoginInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AUTH.login,
          paylaod
        );
        return resp.data;
      } catch (error: any) {
        if (error?.response?.data?.status === "otp_required") {
          params?.onOtpReuired && params?.onOtpReuired();
        }
        return axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params.onSuccess &&
          data?.data?.token &&
          params.onSuccess(
            data?.data?.token,
            params.keepLogedin,
            data?.data?.role
          );
      },
    }
  );
};

interface IRegisterResp {
  message: string;
  data: {
    // traceId?: string;
    token: string;
    role: UserRoleTypes;
  };
}
export interface IRegisterPayload {
  email: string;
  password: string;
  countryCode: string;
  username: string;
  name: string;
  sponsor?: string;
  confirm_password?: string;
}

interface IUseRegister {
  onSuccess?: (
    token: string,
    keepLogedin: boolean,
    role: UserRoleTypes
  ) => void;
}

export const useRegister = (params: IUseRegister) => {
  return useMutation<IRegisterResp, Error, IRegisterPayload>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AUTH.register,
          paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params.onSuccess &&
          data?.data?.token &&
          params.onSuccess(data?.data?.token, true, data?.data?.role);
      },
    }
  );
};

interface IUseVerifyUser {
  onSuccess?: (data: IVerifyUserResp) => void;
}
interface IVerifyUserResp {
  message: string;
}

export interface IVerifyUserPayload {
  traceId: string;
  emailVerifyCode: string | number;
}

export const useVerifyUser = (params?: IUseVerifyUser) => {
  return useMutation<IVerifyUserResp, Error, IVerifyUserPayload>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AUTH.verify,
          paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params?.onSuccess && params.onSuccess(data);
      },
    }
  );
};

export const useUser = (onSuccess?: Function) => {
  return useQuery<IUser, Error>(
    QUERY_KEYS.user_profile,
    async () => {
      try {
        const resp = await privateRequest.get(API_ENDPOINTS_AUTH.profile.me);
        return resp.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export interface IUserUpdateInputs {
  name: string;
}

export const useUpdateUser = () => {
  return useMutation<ILoginResp, Error, IUserUpdateInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AUTH.profile.updateInformation,
          paylaod
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};

interface IUploadProfilePhotoResp {
  message: string;
  user: {
    photo: string;
  };
}

export const useUploadProfilePhoto = () => {
  return useMutation<IUploadProfilePhotoResp, Error, FormData>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AUTH.profile.uploadPhoto,
          paylaod
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};

interface IInviteResp {
  message: string;
}
interface IInvitePayload {
  email: string;
}

export const useInviteMutation = (onSuccess?: Function) => {
  return useMutation<IInviteResp, Error, IInvitePayload>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AUTH.profile.invite,
          paylaod
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
    }
  );
};
