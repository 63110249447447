import { Alert, Button, Skeleton, Stack } from "@mui/material";
import { OverviewCard } from "components/card";
import SpaceBar from "components/uiStyle/spaceBar";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useWalletInformation } from "queries/wallets";
import { useContext } from "react";
import { CgSoftwareDownload } from "react-icons/cg";
import { FiUpload } from "react-icons/fi";
import { IoSwapHorizontalOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import WalletTransactionHistory from "./component/walletTransactionHistory";

const UserWalletPage = () => {
  const { user } = useContext(AuthContext);
  const { data, isError, isLoading } = useWalletInformation();
  if (isError) {
    return <Alert>No Wallet Information Found</Alert>;
  }

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {isLoading &&
          [...Array(1)].map((_, i) => (
            <div className="shadow-card rounded-lg py-8 px-6" key={i}>
              <Skeleton height={20} width={80} variant="text" />
              <div className="h-4"></div>
              <Skeleton height={20} width={200} variant="rectangular" />
              <div className="h-3"></div>
              <Stack direction="row" spacing={2}>
                <Skeleton height={30} width={100} variant="rectangular" />
                <Skeleton height={30} width={100} variant="rectangular" />
              </Stack>
            </div>
          ))}
        {!isLoading &&
          data &&
          data?.map((wallet, i) => (
            <OverviewCard
              key={i}
              title={wallet?.name}
              number={
                (user.data?.country === "PH"
                  ? Number(wallet?.equivalentAmount).toFixed(2)
                  : wallet?.available?.toFixed(2)) +
                (user.data?.country === "PH" ? " PHP" : " USD")
              }
              numberAlignMent="left"
              index={i}
            >
              <div className="flex flex-wrap gap-2 mt-3">
                {wallet?.scopes?.deposit?.enabled && (
                  <Button
                    size="medium"
                    sx={{ borderRadius: 30, px: 1.2 }}
                    color="secondary"
                    variant="outlined"
                  >
                    <CgSoftwareDownload className="mr-3 !h-6 !w-6" />
                    DEPOSIT
                  </Button>
                )}
                {wallet?.scopes?.swap?.enabled && (
                  <Button
                    size="medium"
                    sx={{ borderRadius: 30, px: 1.2 }}
                    color="secondary"
                    variant="outlined"
                  >
                    <IoSwapHorizontalOutline className="mr-3 !h-6 !w-6" />
                    SWAP
                  </Button>
                )}
                {wallet?.scopes?.withdraw?.enabled && (
                  <Link
                    to={`${RouteLinks.WITHDRAW_REQUEST.link}/${wallet?.currencyCode}`}
                  >
                    <Button
                      size="medium"
                      sx={{ borderRadius: 30, px: 1.2 }}
                      color="secondary"
                      variant="outlined"
                    >
                      <FiUpload className="mr-3 !h-6 !w-6" />
                      Withdraw
                    </Button>
                  </Link>
                )}
              </div>
            </OverviewCard>
          ))}
      </div>

      <SpaceBar.SectionGap />
      <WalletTransactionHistory />
    </>
  );
};

export default UserWalletPage;
