import { UserRoleTypes } from "context/AuthContext";

export type PlayGameCategoryType =
  | "livecasino"
  | "poker"
  | "virtual-sports"
  | "sportsbook"
  | "live-casino-table"
  | "video-slots"
  | "table-games"
  | "video-poker"
  | "virtual-games"
  | "scratch-cards";

interface IRouteObjectTypes {
  [key: string]: {
    link: string;
    label: string;
  };
}
const AllRoutes = <T extends IRouteObjectTypes>(obj: T) => obj;

interface IRouteGroupObjectTypes {
  [key: string]: {
    link: UserRoleTypes;
    name: UserRoleTypes;
  };
}
interface IPlayRoutesObjectTypes {
  [key: string]: {
    link: PlayGameCategoryType;
    label: string;
  };
}

const RouteGroupsGenerate = <T extends IRouteGroupObjectTypes>(obj: T) => obj;
const PlayRoutesGenerate = <T extends IPlayRoutesObjectTypes>(obj: T) => obj;

export const PlayRoutes = PlayRoutesGenerate({
  LIVE_CASINO: {
    link: "livecasino",
    label: "Live Casino",
  },
  POKER: {
    link: "poker",
    label: "Poker",
  },
  VIRTUAL_SPORTS: {
    link: "virtual-sports",
    label: "Virtual Sports",
  },
  SPORTS_BOOK: {
    link: "sportsbook",
    label: "Sports Book",
  },
  LIVE_CASINO_TABLE: {
    link: "live-casino-table",
    label: "Live Casino Table",
  },
  VIDEO_SLOTS: {
    link: "video-slots",
    label: "Video Slots",
  },
  TABLE_GAMES: {
    link: "table-games",
    label: "Table Games",
  },
  VIDEO_POKER: {
    link: "video-poker",
    label: "Video Poker",
  },
  VIRTUAL_GAMES: {
    link: "virtual-games",
    label: "Virtual Games",
  },
  SCRATCH_CARDS: {
    link: "scratch-cards",
    label: "Scratch Cards",
  },
});

export const RouteGroups = RouteGroupsGenerate({
  USER: {
    name: "user",
    link: "user",
  },
  AGENT: {
    name: "agent",
    link: "agent",
  },
});

export const RouteLinks = AllRoutes({
  DASHBOARD: {
    link: "dashboard",
    label: "Dashboard",
  },
  WALLETS: {
    link: "wallets",
    label: "Wallets",
  },
  WITHDRAWALS: {
    link: "withdrawals",
    label: "Withdrawals",
  },
  GAME_CREDIT_REQUEST: {
    link: "game-credit-request",
    label: "Game Credit Request",
  },
  DEPOSIT: {
    link: "deposit",
    label: "Deposit",
  },
  PROFILE: {
    link: "profile",
    label: "Profile",
  },
  MY_COMMISSION: {
    link: "my-commission",
    label: "My Commission",
  },
  LOGIN: {
    link: "/login",
    label: "Login",
  },
  SIGNUP: {
    link: "/signup",
    label: "Signup",
  },
  RESET_PASSWORD: {
    link: "/reset-password",
    label: "Reset Password",
  },
  RESET_PASSWORD_REQUEST: {
    link: "/reset-password/reset-password-request",
    label: "Reset Password Request",
  },
  VERIFY_RESET_PASSWORD_REQUEST: {
    link: "/reset-password/verify-reset-password-request",
    label: "Verify Reset Password Request",
  },
  SUBMIT_RESET_PASSWORD_REQUEST: {
    link: "/reset-password/submit-reset-password-request",
    label: "Submit Reset Password Request",
  },
  EMAIL_VERIFICATION: {
    link: "/email-verification",
    label: "Email Verification",
  },
  TRANSACTION_HISTORY: {
    link: "transaction-history",
    label: "Transaction History",
  },
  TRANSACTION_HISTORY_DETAILS: {
    link: "transaction-history-details",
    label: "Transaction History Details",
  },
  LOTTERY: {
    link: "lottery",
    label: "Lottery",
  },
  TOOLS: {
    link: "tools",
    label: "Tools",
  },
  TOOLS_CREATE: {
    link: "tools/create",
    label: "Tools",
  },
  TOOLS_EDIT: {
    link: "tools/edit/:id",
    label: "Tools",
  },
  TOOLS_SHOW: {
    link: "tools/show/:id",
    label: "Tools",
  },
  SUPPORT: {
    link: "support",
    label: "Support",
  },
  FAQ: {
    link: "faq",
    label: "FAQ",
  },
  SWAP: {
    link: "swap",
    label: "Swap",
  },
  PLAYER_MANAGEMENT: {
    link: "player-management",
    label: "Player Management",
  },
  PLAYER_ACTIVITIES: {
    link: "player-activities",
    label: "Player Activities",
  },
  AGENT_MANAGEMENT: {
    link: "agent-management",
    label: "Agent Management",
  },
  AGENT_USERS_ACTIVITY: {
    link: "agent-users-activity",
    label: "Agent Users Activity",
  },
  AGENT_UPGRADE_REQUESTS: {
    link: "agent-upgrade-requests",
    label: "Agent Upgrade Request",
  },
  AGENT_UPGRADE_HISTORY: {
    link: "agent-upgrade-history",
    label: "Agent Upgrade History",
  },
  WITHDRAWAL_MANAGEMENT: {
    link: "withdrawal-management",
    label: "Withdrawal Management",
  },
  WITHDRAW_REQUEST: {
    link: "/user/withdraw-request",
    label: "Withdraw Request",
  },
  PENDING_WITHDRAWALS: {
    link: "pending-withdrawals",
    label: "Pending Withdrawals",
  },
  AGENT_PAYMENT_DETAILS: {
    link: "payment-details",
    label: "Payment Details",
  },
  PAYMENT_METHOD: {
    link: "payment-method",
    label: "Payment Method",
  },
  GC_MANAGEMENT: {
    link: "gc-management",
    label: "GC Requests",
  },
  GAME_REPORTS: { link: "game-reports", label: "Game Reports" },
  RANK: {
    link: "rank",
    label: "Rank",
  },
  AGENT_COMMISSION: {
    link: "agent-commission",
    label: "Agent Commission",
  },
});
