import { useModal } from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CircularProgress,
  colors,
  Typography,
  useTheme,
} from "@mui/material";
import { CardUI } from "components/card";
import { ConfirmationModal } from "components/modals/confirmation.modal";
import { ITransactionDetails } from "hooks/transactionDetailsHook";
import moment from "moment";
import {
  useDisputeWithdrawalMutation,
  useReleaseWithdrawalMutation,
} from "queries/wallets";
import { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";

type Props = {
  data: ITransactionDetails | null;
};

const UserPaymentStatusCard = ({ data }: Props) => {
  return (
    <CardUI className="!flex-1">
      {data && (
        <>
          <AgentActions data={data} />
          {data?.externalTransactionStatus === "paid" && (
            <PlayerActions data={data} />
          )}
          <FinalStatus data={data} />
        </>
      )}
    </CardUI>
  );
};

export default UserPaymentStatusCard;

type AgentActionsProps = {
  data: ITransactionDetails | null;
};

const AgentActions = ({ data }: AgentActionsProps) => {
  const theme = useTheme();

  if (data?.status === "declined") {
    return (
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <MdOutlineError
            size={24}
            style={{ color: theme.palette.error.main }}
          />
          <div>
            <Typography variant="h6">
              Agent declined the withdrawal request
            </Typography>
            <Typography variant="body2">
              This transaction is declined by agent
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  if (data?.status === "pending") {
    return (
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <div>
            <CircularProgress size={24} />
          </div>
          <div>
            <Typography variant="h6">
              Waiting for approval from agent
            </Typography>
            <Typography variant="body2">
              This transaction is pending and waiting for approval from agent
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-2 items-center">
        {data?.externalTransactionStatus === "paid" ? (
          <AiFillCheckCircle
            size={24}
            style={{ color: theme.palette.primary.main }}
          />
        ) : (
          <CircularProgress size={24} />
        )}
        <div>
          <Typography variant="h6">
            {data?.externalTransactionStatus === "paid"
              ? "Agent Confirmed the Payment"
              : "Agent is processing the payment. "}
          </Typography>
          <Typography variant="body2">
            {data?.externalTransactionStatus === "paid"
              ? `Please wait some time. It can take upto ${data?.paymentMethod?.timeWindow} mins`
              : " Agent will confirm once the processing is over"}
          </Typography>
        </div>
      </div>

      {data?.externalTransactionStatus === "unpaid" && (
        <div className="mt-4">
          <CoundownTimer
            startTime={data?.withdrawApprovedAt}
            timeWindow={data?.paymentMethod?.timeWindow}
          />
        </div>
      )}

      {data?.externalTransactionStatus === "paid" && (
        <Button
          variant="contained"
          disabled
          sx={{
            mt: "16px",
            ":disabled": { bgcolor: colors.green[100], color: "primary.dark" },
          }}
        >
          Payment confirmed
        </Button>
      )}
    </div>
  );
};

type PlayerActionsProps = {
  data: ITransactionDetails | null;
};

const PlayerActions = ({ data }: PlayerActionsProps) => {
  const theme = useTheme();

  const confirmationModal = useModal(ConfirmationModal);

  const {
    mutateAsync: mutateAsyncRelaseWithdrawal,
    isLoading: isLoadingWithdrawalRelease,
  } = useReleaseWithdrawalMutation();
  const {
    mutateAsync: mutateAsyncDisputeWithdrawal,
    isLoading: isLoadingWithdrawalDispute,
  } = useDisputeWithdrawalMutation();

  const onConfirmRecieved = async () => {
    try {
      if (data) {
        await mutateAsyncRelaseWithdrawal({ id: data._id });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onDispute = async () => {
    try {
      if (data) {
        await mutateAsyncDisputeWithdrawal({ id: data._id });
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (data?.status === "disputed") {
    return (
      <div className="flex flex-col">
        <div className="h-8" />

        <div className="flex gap-2 items-center">
          <MdOutlineError
            size={24}
            style={{ color: theme.palette.error.main }}
          />

          <div>
            <Typography variant="h6">You Disputed this transaction</Typography>
            <Typography variant="body2">
              Transaction disputed. Admin will check and confirm
            </Typography>
          </div>
        </div>

        <div className="h-4" />
        <Button
          variant="contained"
          disabled
          sx={{
            ":disabled": { bgcolor: colors.red[100], color: "error.dark" },
          }}
        >
          Disputed
        </Button>
      </div>
    );
  }
  if (data?.status === "confirmed") {
    return (
      <div className="flex flex-col">
        <div className="h-8" />

        <div className="flex gap-2 items-center">
          <AiFillCheckCircle
            size={24}
            style={{ color: theme.palette.primary.main }}
          />

          <div>
            <Typography variant="h6">
              You Confirmed the payment received
            </Typography>
            <Typography variant="body2">
              Player will confirm after getting the payment
            </Typography>
          </div>
        </div>

        <div className="h-4" />
        <Button
          variant="contained"
          disabled
          sx={{
            ":disabled": { bgcolor: colors.green[100], color: "primary.main" },
          }}
        >
          Confirmed
        </Button>
      </div>
    );
  }
  if (data?.status === "inEscrow") {
    return (
      <div className="flex flex-col">
        <div className="h-8" />

        <div className="flex gap-2 items-center">
          <div>
            {data?.status === "inEscrow" && <CircularProgress size={24} />}
          </div>

          <div>
            <Typography variant="h6">
              After Received the payment, Please Confirm
            </Typography>
            <Typography variant="body2">
              Player will confirm after getting the payment
            </Typography>
          </div>
        </div>

        <div className="my-4" />

        <LoadingButton
          loading={isLoadingWithdrawalRelease}
          onClick={() =>
            confirmationModal
              .show({
                title: "Payment Received?",
                submitButtonTitle: "Received",
                description: "Please Make sure you got the payment",
              })
              .then(() => onConfirmRecieved())
          }
          variant="contained"
        >
          Payment Received
        </LoadingButton>

        <div className="h-2" />

        <LoadingButton
          loading={isLoadingWithdrawalDispute}
          onClick={() =>
            confirmationModal
              .show({
                title: "Are you sure you want to Dispute?",
                submitButtonTitle: "Dispute",
                mode: "error",
                description:
                  "Before dispute, Please recheck whether you have got the payment",
              })
              .then(() => onDispute())
          }
          variant="text"
          color="error"
        >
          I want to Dispute
        </LoadingButton>
      </div>
    );
  }

  return <Typography>Unknown State</Typography>;
};

type FinalStatusProps = {
  data: ITransactionDetails | null;
};

const FinalStatus = ({ data }: FinalStatusProps) => {
  const theme = useTheme();

  if (data?.status === "confirmed") {
    return (
      <div className="flex flex-col">
        <div className="h-8" />

        <div className="flex gap-2 items-center">
          {(true || data?.status === "confirmed") && (
            <AiFillCheckCircle
              size={24}
              style={{ color: theme.palette.primary.main }}
            />
          )}
          <div>
            <Typography variant="h6">
              <span style={{ color: theme.palette.primary.main }}>
                {data?.amount?.toFixed(2)} {data?.currencyCode}
              </span>{" "}
              Withdrawal Successful
            </Typography>
            <Typography variant="body2">
              Withdrawal Successful. {data?.amount?.toFixed(2)}{" "}
              {data?.currencyCode} sent to Agent
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

type CoundownTimerProps = {
  startTime?: string;
  timeWindow?: number;
  additionalTime?: number;
};

const CoundownTimer = ({
  startTime = new Date().toDateString(),
  timeWindow = 0,
  additionalTime = 0,
}: CoundownTimerProps) => {
  const endTime = moment
    .utc(startTime)
    .add(timeWindow + additionalTime, "minutes")
    .toDate();

  const [isTimeOver, setIsTimeOver] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  const padTo2Digits = (num: number) => num.toString().padStart(2, "0");

  const tick = () => {
    const difference = endTime.getTime() - new Date().getTime();

    if (difference < 1) setIsTimeOver(true);

    let _seconds = Math.floor(difference / 1000);
    let _minutes = Math.floor(_seconds / 60);
    let _hours = Math.floor(_minutes / 60);

    _seconds = _seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    // _minutes = _seconds >= 30 ? _minutes + 1 : _minutes;

    _minutes = _minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    _hours = _hours % 24;

    setSeconds(_seconds);
    setMinutes(_minutes);
    setHours(_hours);
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    if (isTimeOver) {
      clearInterval(timerID);
    }
    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeOver]);

  if (isTimeOver) {
    return (
      <Card
        sx={{
          boxShadow: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          padding: "8px",
          bgcolor: "action.hover",
        }}
      >
        <Typography color="error.main">Time is Over</Typography>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        boxShadow: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        padding: "8px",
        bgcolor: "action.hover",
      }}
    >
      <Card
        sx={{
          bgcolor: "primary.main",
          padding: "8px 12px",
          boxShadow: "0",
          color: "primary.contrastText",
        }}
      >
        {padTo2Digits(hours)}
      </Card>
      <Typography color="primary.main">:</Typography>
      <Card
        sx={{
          bgcolor: "primary.main",
          padding: "8px 12px",
          boxShadow: "0",
          color: "primary.contrastText",
        }}
      >
        {padTo2Digits(minutes)}
      </Card>
      <Typography color="primary.main">:</Typography>
      <Card
        sx={{
          bgcolor: "primary.main",
          padding: "8px 12px",
          boxShadow: "0",
          color: "primary.contrastText",
        }}
      >
        {padTo2Digits(seconds)}
      </Card>
    </Card>
  );
};
