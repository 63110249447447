import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Avatar,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { CardUI } from "components/card";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useThemeContext } from "context/ThemeContext";
import {
  IPaymentMethod,
  IWalletBalance,
  useAvailablePaymentMethods,
  useWalletBalance,
  useWithdrawRequestMutation,
  WithdrawRequestCryptoPayload,
  WithdrawRequestFiatPayload,
} from "queries/wallets";
import { FC, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import { reactSelectDarkStyles, reactSelectStyles } from "styles/form";
import { purifyAmount } from "utils";
import { currencyFormatter } from "utils/currency_fromatter";
import * as yup from "yup";

type WithdrawRequestProps = {
  currencyCode: string;
  withdrway_history_query: string;
};

type CurrencyType = "Cryptocurrency" | "Fiat Currency";
const CurrencyTypeList: CurrencyType[] = ["Cryptocurrency", "Fiat Currency"];

const WithdrawRequest: FC<WithdrawRequestProps> = ({
  currencyCode,
  withdrway_history_query,
}) => {
  const { user } = useContext(AuthContext);
  const [isShowWithdrawGuide, setIsShowWithdrawGuide] = useState<boolean>(true);
  const [currencyType, setCurrencyType] =
    useState<CurrencyType>("Cryptocurrency");

  const updateCurrencyType = (type: CurrencyType) => {
    if (type === currencyType) return;
    setCurrencyType(type);
  };

  const {
    data: balance,
    error,
    isLoading: isLoadingBalance,
  } = useWalletBalance(currencyCode);

  if (error) {
    return (
      <Alert severity="error">
        {error?.message ?? "Something went wrong with Currency Code"}
      </Alert>
    );
  }

  if (!currencyCode) {
    return <Navigate to={"/user/" + RouteLinks.WALLETS.link} replace={true} />;
  }

  return (
    <>
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12} md={6}>
          <SectionTitle.Default title="Withdrawal Request" />
          <SpaceBar.SectionGap />
          <CardUI padding="0px" borderRadius="8px">
            <div className="px-6 py-4 flex justify-between items-center border-2 rounded-lg border-primary">
              <Typography fontWeight={500}>Your Agent</Typography>

              <div className="flex gap-4 items-center">
                {user?.data?.sponsor?.photo ? (
                  <img
                    src={user?.data?.sponsor?.photo}
                    className="!w-10 !h-10 rounded-full"
                    alt="agent"
                  />
                ) : (
                  <Avatar className="!w-10 !h-10" />
                )}
                <Typography color="primary.main" fontWeight={500}>
                  {user?.data?.sponsor?.username}
                </Typography>
              </div>
            </div>
            <div className="flex gap-4 m-6">
              {CurrencyTypeList?.map((type) => (
                <Button
                  key={type}
                  variant="contained"
                  sx={
                    currencyType !== type
                      ? {
                          color: "#000!important",
                          background: "#f9f9f9!important",
                        }
                      : {}
                  }
                  onClick={() => updateCurrencyType(type)}
                >
                  {type}
                </Button>
              ))}
            </div>
            {currencyType === "Cryptocurrency" ? (
              <CryptoWithDrawForm
                withdrway_history_query={withdrway_history_query}
                balance={balance}
                currencyCode={currencyCode}
                isLoadingBalance={isLoadingBalance}
              />
            ) : (
              <FiatWithDrawForm
                withdrway_history_query={withdrway_history_query}
                balance={balance}
                currencyCode={currencyCode}
                isLoadingBalance={isLoadingBalance}
              />
            )}
            <div className="h-4" />
          </CardUI>
        </Grid>
        <Grid item xs={12} md={6}>
          {isShowWithdrawGuide && (
            <Alert
              onClose={() => setIsShowWithdrawGuide(false)}
              color="info"
              severity="info"
              sx={{
                position: "relative",
                mb: { lg: 10 },
              }}
              className="!shadow-md"
            >
              <div className="absolute w-2 left-0 top-0 rounded-lg h-full bg-green-500"></div>
              Once submitted, your request for withdrawal will be handled by the
              respective up-line agent.
            </Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default WithdrawRequest;

interface WithdrawRequestCryptoForm {
  currency: string;
  network: string;
  currencyCode: string;
  amount: string;
  address: string;
}

interface SelectOption {
  label: string;
  value: string;
}

const cryptoWithdrawSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is Required")
    .min(0.00000001, "Amount must be greater than or equal to 0.00000001"),
  currency: yup.string().required("Currency is Required"),
  address: yup.string().required("Wallet Address is Required"),
  network: yup.string().required("Network is Required"),
});

function CryptoWithDrawForm({
  withdrway_history_query,
  currencyCode,
  isLoadingBalance,
  balance,
}: {
  withdrway_history_query: string;
  currencyCode: string;
  isLoadingBalance: boolean;
  balance: IWalletBalance | undefined;
}) {
  const { user } = useContext(AuthContext);
  const { themeMode } = useThemeContext();
  const { data, isLoading } = useAvailablePaymentMethods("crypto");

  const useWithdrawRequest = useWithdrawRequestMutation(
    withdrway_history_query
  );
  const { control, handleSubmit, resetField, watch } =
    useForm<WithdrawRequestCryptoForm>({
      defaultValues: {
        currencyCode: currencyCode,
        amount: "",
        address: "",
      },
      resolver: yupResolver(cryptoWithdrawSchema),
    });

  // generate currency list in react-select formate
  const cryptoCurrenciesList: SelectOption[] = useMemo(() => {
    const newdata =
      data &&
      data?.length > 0 &&
      data?.reduce((t: SelectOption[], c) => {
        t.push({ label: c.name?.toUpperCase(), value: c.name });
        return t;
      }, []);
    return newdata || [];
  }, [data]);

  const selectedCurrency: string = watch("currency");

  // generate network list in react-select formate
  const networkList: SelectOption[] = useMemo(() => {
    const methods: IPaymentMethod[] =
      (data &&
        data?.length > 0 &&
        selectedCurrency &&
        data?.filter((el) => el.name === selectedCurrency)?.[0]?.methods) ||
      [];

    const newdata =
      methods &&
      methods?.length > 0 &&
      methods?.reduce((t: SelectOption[], c) => {
        t.push({ label: c.cryptoNetwork?.toUpperCase() ?? "", value: c._id });
        return t;
      }, []);
    return newdata || [];
  }, [data, selectedCurrency]);

  const formSubmit = (data: WithdrawRequestCryptoForm) => {
    const payload: WithdrawRequestCryptoPayload = {
      address: data?.address,
      amount: data?.amount,
      currencyCode: data?.currencyCode,
      paymentMethod: data?.network,
    };
    toast.promise(useWithdrawRequest.mutateAsync(payload), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => {
        resetField("address");
        resetField("amount");
        return res.message ?? "Operation successful";
      },
    });
  };

  const reactSelectSX =
    themeMode === "dark" ? reactSelectDarkStyles : reactSelectStyles;

  return (
    <form
      onSubmit={handleSubmit(formSubmit)}
      className="p-6 flex flex-col gap-2"
    >
      <InputLabel>Currency</InputLabel>
      <Controller
        name="currency"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select
              autoFocus
              isLoading={isLoading}
              styles={reactSelectSX}
              instanceId="currency"
              options={cryptoCurrenciesList}
              onChange={(e) => field.onChange({ target: { value: e?.value } })}
            />
            {invalid && (
              <FormHelperText sx={{ ml: 1.5 }} error>
                {error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
      <div className="h-2" />
      <InputLabel>Network</InputLabel>
      <Controller
        name="network"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select
              isLoading={isLoading}
              styles={reactSelectSX}
              instanceId="network"
              options={networkList}
              onChange={(e) => field.onChange({ target: { value: e?.value } })}
            />
            {invalid && (
              <FormHelperText sx={{ ml: 1.5 }} error>
                {error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
      <div className="h-2" />
      <InputLabel>
        Amount
        <div className="float-right">
          {isLoadingBalance ? (
            <Skeleton width={200} />
          ) : (
            <>
              Available Balance{" "}
              <span className="text-primary">
                {currencyFormatter({
                  value:
                    user.data?.country === "PH"
                      ? balance?.equivalentAmount
                      : balance?.available,
                  decimalPlaces: 2,
                })}{" "}
                {user.data?.country === "PH" ? "PHP" : balance?.currencyCode}
              </span>
            </>
          )}
        </div>
      </InputLabel>
      <Controller
        control={control}
        name="amount"
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            value={field.value}
            onChange={(e) =>
              field.onChange({
                target: {
                  value: purifyAmount(e.target.value),
                  name: e.target.name,
                },
              })
            }
            type="number"
            error={invalid}
            helperText={error?.message}
          />
        )}
      />

      <div className="h-2" />
      <InputLabel>Wallet Address</InputLabel>
      <Controller
        control={control}
        name="address"
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField {...field} error={invalid} helperText={error?.message} />
        )}
      />

      <div className="h-2" />
      <LoadingButton
        loading={useWithdrawRequest.isLoading}
        type="submit"
        variant="contained"
      >
        Request Withdrawal
      </LoadingButton>
    </form>
  );
}

interface WithdrawRequestFiatForm {
  currency: string;
  paymentMethod: string;
  currencyCode: string;
  amount: string;
  paymentDetails: string;
}

const fiatWithdrawSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is Required")
    .min(0.00000001, "Amount must be greater than or equal to 0.00000001"),
  currency: yup.string().required("Currency is Required"),
  paymentDetails: yup.string().required("Payment Details is Required"),
  paymentMethod: yup.string().required("Payment Method is Required"),
});

function FiatWithDrawForm({
  withdrway_history_query,
  currencyCode,
  isLoadingBalance,
  balance,
}: {
  withdrway_history_query: string;
  currencyCode: string;
  isLoadingBalance: boolean;
  balance: IWalletBalance | undefined;
}) {
  const { user } = useContext(AuthContext);
  const { data, isLoading } = useAvailablePaymentMethods("fiat");

  const useWithdrawRequest = useWithdrawRequestMutation(
    withdrway_history_query
  );
  const { control, handleSubmit, resetField, watch } =
    useForm<WithdrawRequestFiatForm>({
      defaultValues: {
        currencyCode: currencyCode,
        currency: "",
        paymentMethod: "",
        amount: "",
        paymentDetails: "",
      },
      resolver: yupResolver(fiatWithdrawSchema),
    });

  // generate currency list in react-select formate
  const cryptoCurrenciesList: SelectOption[] = useMemo(() => {
    const newdata =
      data &&
      data?.length > 0 &&
      data?.reduce((t: SelectOption[], c) => {
        t.push({ label: c.name?.toUpperCase(), value: c.name });
        return t;
      }, []);
    return newdata || [];
  }, [data]);

  const selectedCurrency: string = watch("currency");

  // generate network list in react-select formate
  const paymentMethodList: SelectOption[] = useMemo(() => {
    const methods: IPaymentMethod[] =
      (data &&
        data?.length > 0 &&
        selectedCurrency &&
        data?.filter((el) => el.name === selectedCurrency)?.[0]?.methods) ||
      [];

    const newdata =
      methods &&
      methods?.length > 0 &&
      methods?.reduce((t: SelectOption[], c) => {
        t.push({ label: c.title?.toUpperCase() ?? "", value: c._id });
        return t;
      }, []);
    return newdata || [];
  }, [data, selectedCurrency]);

  const formSubmit = (data: WithdrawRequestFiatForm) => {
    const payload: WithdrawRequestFiatPayload = {
      paymentDetails: data?.paymentDetails,
      amount: data?.amount,
      currencyCode: data?.currencyCode,
      paymentMethod: data?.paymentMethod,
    };
    toast.promise(useWithdrawRequest.mutateAsync(payload), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => {
        resetField("amount");
        resetField("paymentDetails");
        return res.message ?? "Operation successful";
      },
    });
  };
  const { themeMode } = useThemeContext();
  const reactSelectSX =
    themeMode === "dark" ? reactSelectDarkStyles : reactSelectStyles;
  return (
    <form
      onSubmit={handleSubmit(formSubmit)}
      className="p-6 flex flex-col gap-2"
    >
      <InputLabel>Currency</InputLabel>
      <Controller
        name="currency"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select
              autoFocus
              isLoading={isLoading}
              styles={reactSelectSX}
              instanceId="currency"
              options={cryptoCurrenciesList}
              onChange={(e) => field.onChange({ target: { value: e?.value } })}
            />
            {invalid && (
              <FormHelperText sx={{ ml: 1.5 }} error>
                {error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
      <div className="h-2" />
      <InputLabel>Payment Method</InputLabel>
      <Controller
        name="paymentMethod"
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select
              isLoading={isLoading}
              styles={reactSelectSX}
              instanceId="paymentMethod"
              options={paymentMethodList}
              onChange={(e) => field.onChange({ target: { value: e?.value } })}
            />
            {invalid && (
              <FormHelperText sx={{ ml: 1.5 }} error>
                {error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
      <div className="h-2" />
      <InputLabel>
        Amount
        <div className="float-right">
          {isLoadingBalance ? (
            <Skeleton width={200} />
          ) : (
            <>
              Available Balance{" "}
              <span className="text-primary">
                {currencyFormatter({
                  value:
                    user.data?.country === "PH"
                      ? balance?.equivalentAmount
                      : balance?.available,
                  decimalPlaces: 2,
                })}{" "}
                {user.data?.country === "PH" ? "PHP" : balance?.currencyCode}
              </span>
            </>
          )}
        </div>
      </InputLabel>
      <Controller
        control={control}
        name="amount"
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            value={field.value}
            onChange={(e) =>
              field.onChange({
                target: {
                  value: purifyAmount(e.target.value),
                  name: e.target.name,
                },
              })
            }
            type="number"
            error={invalid}
            helperText={error?.message}
          />
        )}
      />

      <div className="h-2" />
      <InputLabel>Payment Details</InputLabel>
      <Controller
        control={control}
        name="paymentDetails"
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField {...field} error={invalid} helperText={error?.message} />
        )}
      />

      <div className="h-2" />
      <LoadingButton
        loading={useWithdrawRequest.isLoading}
        type="submit"
        variant="contained"
      >
        Request Withdrawal
      </LoadingButton>
    </form>
  );
}
