import {
  Chip,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { RouteLinks } from "constants/routeLinks";
import { useTransactionHistory } from "queries/wallets";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getColorSchema } from "utils";
import defaultDateFormat from "utils/date_formatter";

interface IState {
  criteria: string;
}

const TransactionHistory: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [state, setState] = useState<IState>({
    criteria: "win,loss,transfer",
  });

  const changeHandler = ({ target: { value, name } }: any) => {
    if (name === "criteria") {
      navigate("/user/" + RouteLinks.TRANSACTION_HISTORY.link + "/" + value);
    }
    setState({ ...state, [name]: value });
  };

  const updateQuery = () => {
    const parsed = queryString.parse(query);
    if (state.criteria) {
      parsed.criteria = state.criteria;
    } else {
      delete parsed.criteria;
    }

    const uri = queryString.stringify(parsed);
    setQuery("&" + uri);
  };

  useEffect(() => {
    updateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const { data, isLoading, error, isError } = useTransactionHistory(
    query,
    page
  );
  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Transaction History">
        <div className="flex gap-4">
          <Select
            value={state.criteria}
            label="Criteria"
            name="criteria"
            defaultValue=""
            displayEmpty
            onChange={changeHandler}
          >
            <MenuItem value="win,loss,transfer">All Criteria</MenuItem>
            <MenuItem value="loss">Loss</MenuItem>
            <MenuItem value="win">Win</MenuItem>
            <MenuItem value="transfer">Transfer</MenuItem>
          </Select>
        </div>
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Criteria</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...Array(8)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton className="mx-auto" width={90} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={500} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton className="mx-auto" width={90} />
                  </TableCell>
                </TableRow>
              ))}
            {data?.pages?.map((page) =>
              page?.docs.map((transaction) => (
                <TableRow key={transaction?._id}>
                  <TableCell>
                    {defaultDateFormat(transaction?.createdAt)}
                  </TableCell>
                  <TableCell>{transaction?.txid}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.criteria?.toUpperCase()}
                      color="secondary"
                    />
                  </TableCell>
                  <TransactionValueCell
                    type={transaction?.transactionType}
                    data={transaction}
                  />
                  <TableCell>{transaction?.note}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.status?.toUpperCase()}
                      color={getColorSchema(transaction?.status)}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasNoData && (
        <TablePagination
          count={data?.pages[0].totalPages}
          page={page}
          onChange={(newPage) => setPage(newPage)}
        />
      )}
    </>
  );
};

export default TransactionHistory;
