import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { RankSymbol } from "interfaces/agent/rank";
import {
  DownlineAgent,
  useUpgradeAgentMutation,
} from "queries/agent/agent_management_query";
import { useState } from "react";

export default NiceModal.create(({ agent }: { agent: DownlineAgent }) => {
  const modal = useModal();
  const [rank, setRank] = useState<RankSymbol>(agent.upgradableRanks[0]);

  const { isLoading: isUpgradeLoading, mutateAsync } =
    useUpgradeAgentMutation();

  const onConfirm = async () => {
    if (rank) await mutateAsync({ userId: agent._id, rank: rank });
    modal.remove();
  };

  return (
    <Dialog open={modal.visible} onClose={modal.hide} fullWidth>
      <DialogTitle color="success.main">Upgrade Rank</DialogTitle>

      <DialogContent>
        <div className="flex flex-col gap-8 ">
          <div className="flex flex-col gap-2">
            <InputLabel>Rank</InputLabel>

            <Select
              fullWidth
              value={rank}
              label="Rank"
              displayEmpty
              onChange={(event: SelectChangeEvent<RankSymbol>) => {
                setRank(event.target.value as RankSymbol);
              }}
            >
              {agent.upgradableRanks.map((rank, index) => (
                <MenuItem key={index} value={rank}>
                  {rank.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>

          <LoadingButton
            loading={isUpgradeLoading}
            onClick={onConfirm}
            variant="contained"
          >
            Upgrade
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
});
