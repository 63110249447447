export const googleRecaptchaSiteKey =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const landingAppUrl = process.env.REACT_APP_LANDING_APP_URL;
export const dashboardAppUrl = process.env.REACT_APP_APP_URL;

export const companyName =
  process.env.REACT_APP_COMPANY_NAME || "kryptobet.net";

export const gameCurrency = process.env.REACT_APP_GAME_CURRENCY || "USD";
