import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogContent,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { IuseChangePasswordPayload, useChangePassword } from "queries/security";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default NiceModal.create(() => {
  const modal = useModal();
  const [showPass, setShowPass] = useState(false);
  const { control, handleSubmit } = useForm<IuseChangePasswordPayload>({
    defaultValues: {
      password: "",
      oldPassword: "",
    },
  });
  const useChangePasswordMutation = useChangePassword();

  const formSubmit = (data: IuseChangePasswordPayload) => {
    toast.promise(useChangePasswordMutation.mutateAsync(data), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => {
        modal.remove();
        return res.message ?? "Operation successful";
      },
    });
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography color="primary" fontSize={18} fontWeight={500}>
          Change Password
        </Typography>
        <div className="h-8"></div>
        <form onSubmit={handleSubmit(formSubmit)}>
          <FormLabel>Old Password</FormLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="oldPassword"
            rules={{ required: "Old Password is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  type={showPass ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPass ? (
                          <AiFillEye
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        ) : (
                          <AiFillEyeInvisible
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
          />
          <div className="h-8"></div>
          <FormLabel>Password</FormLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  type={showPass ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPass ? (
                          <AiFillEye
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        ) : (
                          <AiFillEyeInvisible
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
          />
          <div className="h-20"></div>
          <LoadingButton
            loading={useChangePasswordMutation.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Change
          </LoadingButton>
        </form>
        {useChangePasswordMutation.isError && (
          <Alert sx={{ mt: 3 }} color="error" severity="error">
            {useChangePasswordMutation?.error?.message}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
});
