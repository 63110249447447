import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import QUERY_KEYS from "constants/query_keys";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";
import { API_ENDPOINTS_AUTH } from "./../../constants/api_endpoints";

export interface RequestToChangeEmailResp {
  message: string;
  traceId: string;
}
interface RequestToChangeEmailPayload {
  newEmail: string;
  password: string;
}

interface IParams {
  onSucces: Function;
}

export const useRequestToChangeEmail = (params: IParams) => {
  return useMutation<
    RequestToChangeEmailResp,
    Error,
    RequestToChangeEmailPayload
  >(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          "security/requestToChangeEmail",
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params.onSucces && params.onSucces(data);
      },
    }
  );
};

interface IChangeEmailVerifyPayload {
  traceId: string;
  emailVerifyCode: string;
}

interface IChangeEmailVerifyResp {
  message: string;
}

export const useChangeEmailVerify = () => {
  return useMutation<IChangeEmailVerifyResp, Error, IChangeEmailVerifyPayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.post("security/changeEmail", payload);
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};

export interface IChangePhoneVerifyPayload {
  dialCode: string;
  phone: string;
  password: string;
}

interface IChangePhoneVerifyResp {
  message: string;
}

export const useChangePhone = () => {
  return useMutation<IChangePhoneVerifyResp, Error, IChangePhoneVerifyPayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          "security/changeMobileNumber",
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};

interface IuseChangePasswordResp {
  message: string;
}
export interface IuseChangePasswordPayload {
  oldPassword: string;
  password: string;
}

export const useChangePassword = () => {
  return useMutation<IuseChangePasswordResp, Error, IuseChangePasswordPayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          "security/changePassword",
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

interface IGoogleAuthenticationSecretResp {
  status: string;
  message: string;
  secret: string;
}

export const useGoogleAuthenticationSecret = () => {
  return useQuery<IGoogleAuthenticationSecretResp, Error>(
    QUERY_KEYS.getGoogleTwoFASecret,
    async () => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AUTH.profile.switchGoogleAuthenticator
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: false,
    }
  );
};

interface ISwitchGoogleAuthenticationResp {
  status: string;
  message: string;
  secret: string;
}
interface ISwitchGoogleAuthenticationInputs {
  otp: string;
}

export const useSwitchGoogleAuthenticationMutation = () => {
  return useMutation<
    ISwitchGoogleAuthenticationResp,
    Error,
    ISwitchGoogleAuthenticationInputs
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post(
          API_ENDPOINTS_AUTH.profile.switchGoogleAuthenticator,
          payload
        );
        return res?.data;
      } catch (error) {
        return axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};

interface IRequestToUnset2faMutationResp {
  status: string;
  message: string;
  traceId: string;
}

export const useRequestToUnset2faMutation = () => {
  return useMutation<IRequestToUnset2faMutationResp, Error>(async () => {
    try {
      const res = await privateRequest.get(
        API_ENDPOINTS_AUTH.profile.requestToUnsetGoogleAuthenticator
      );
      return res?.data;
    } catch (error) {
      return axiosErrorHandler(error);
    }
  });
};

interface IUnset2faResp {
  message: string;
}
interface IUnset2faPayload {
  traceId: string;
  emailVerifyCode: string;
}

export const useUnset2faMutation = () => {
  return useMutation<IUnset2faResp, Error, IUnset2faPayload>(
    async (payload) => {
      try {
        const res = await privateRequest.post(
          API_ENDPOINTS_AUTH.profile.unsetGoogleAuthenticator,
          payload
        );
        return res?.data;
      } catch (error) {
        return axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.user_profile);
      },
    }
  );
};
