import { privateRequest } from "config/axios.config";
import { IChat } from "hooks/transactionDetailsHook";
import { useInfiniteQuery, useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

interface SendMessageResp {
  status: string;
  message: string;
}
export interface SendMessagePayload {
  conversationId: string;
  message: string;
  attachment: File[];
}

export const useSendMessage = () => {
  return useMutation<SendMessageResp, Error, FormData>(async (payload) => {
    try {
      const resp = await privateRequest.post("chat/sendMessage", payload);
      return resp?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};

interface IGetMessages {
  docs: IChat[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
}

interface IGetMessageParams {
  id: string;
  onSuccess?: (data: IChat[]) => void;
  onFailed?: (err: Error) => void;
}

export const useGetMessages = (params: IGetMessageParams) => {
  return useInfiniteQuery<IGetMessages, Error>(
    "fetch-get-messages",
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          "chat/getMessages/" + params.id + "?page=" + pageParam
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: false,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.prevPage,
      onSuccess: (data) => {
        params?.onSuccess &&
          params?.onSuccess(
            data?.pages?.[data?.pageParams?.length - 1 ?? 0]?.docs ?? []
          );
      },
      onError: (err) => {
        params?.onFailed && params?.onFailed(err);
      },
    }
  );
};
