import { Card, Grow, Skeleton, Typography } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewTotalGcPurchasedProps = {
  isLoading?: boolean;
  balance: number;
  // balanceUsd: number;
};

const OverviewTotalGcPurchased: FC<OverviewTotalGcPurchasedProps> = ({
  isLoading,
  balance,
  // balanceUsd,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Grow in>
      <Card
        elevation={10}
        className="max-w-sm md:max-w-full flex flex-col gap-4 !bg-gradient-to-br from-[#6354E1] to-[#270C5C]"
        sx={{ color: "white", p: 3 }}
      >
        <div className="flex justify-between items-center">
          <Typography variant="subtitle1">Total GC Purchased</Typography>
          <Link to={"/user/" + RouteLinks.GAME_CREDIT_REQUEST.link}>
            Transaction History
          </Link>
        </div>

        <section>
          {isLoading ? (
            <Typography variant="h4">
              <Skeleton variant="text" />
            </Typography>
          ) : (
            <>
              <Typography variant="h4" fontWeight={500}>
                {currencyFormatter({ value: balance, decimalPlaces: 2 }) +
                  (user.data?.country === "PH" ? " PHP" : " USD")}
              </Typography>
            </>
          )}

          {/* {isLoading ? (
            <Typography variant="subtitle1">
              <Skeleton variant="text" width={100} />
            </Typography>
          ) : (
            <Typography variant="subtitle1" fontWeight={400}>
              $
              {balanceUsd.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          )} */}
        </section>
      </Card>
    </Grow>
  );
};

export default OverviewTotalGcPurchased;
