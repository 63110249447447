import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { useParams } from "react-router-dom";
import WithdrawHistory from "./components/withdrawHistory";
import WithdrawRequest from "./components/withdrawRequest";

const UserWithdrawaRequestPage = () => {
  const { currencyCode } = useParams();
  const withdrway_history_query = `&criteria=withdraw&currencyCode=${currencyCode}`;
  return (
    <>
      <WithdrawRequest
        withdrway_history_query={withdrway_history_query}
        currencyCode={currencyCode as string}
      />
      <div className="h-20" />
      <SectionTitle.Default title="Withdrawal History" />
      <SpaceBar.TitleGap />
      <WithdrawHistory withdrway_history_query={withdrway_history_query} />
    </>
  );
};

export default UserWithdrawaRequestPage;
