import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useLogin } from "queries/auth";
import { FC, useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { googleRecaptchaSiteKey } from "utils/urls";
import * as yup from "yup";

const schema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
  captcha_token: yup.string(),
});

type CaptchaProps = string | null;
export interface ILoginInputs {
  username: string;
  password: string;
  otp: string;
  captcha_token: CaptchaProps;
  "g-recaptcha-response": string;
}

interface Props {}

const LoginForm: FC<Props> = () => {
  const { loginFinish } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginInputs>({
    resolver: yupResolver(schema),
  });

  const [keepLogedin, setKeepLogedin] = useState<boolean>(true);
  const [isOtpReuired, setIsOtpReuired] = useState<boolean>(false);
  const loginMutation = useLogin({
    onSuccess: loginFinish,
    keepLogedin,
    onOtpReuired: () => setIsOtpReuired(true),
  });

  const [showPass, setShowPass] = useState(false);

  const onSubmit: SubmitHandler<ILoginInputs> = (data: ILoginInputs) => {
    if (!data.captcha_token) {
      toast.error("Please verify first you are not robot!");
    } else {
      const payload = {
        username: data.username,
        password: data.password,
        otp: data?.otp,
        "g-recaptcha-response": data?.captcha_token ?? "",
      };
      setIsOtpReuired(false);
      loginMutation.mutate(payload);
    }
  };

  return (
    <form
      className="flex flex-col overflow-hidden gap-3 lg:gap-6 w-full select-none"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="username"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            fullWidth
            placeholder="Username"
            {...field}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
        )}
      />
      <Controller
        name="password"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <TextField
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            type={showPass ? "text" : "password"}
            fullWidth
            placeholder="Password"
            {...field}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "gray" }}>
                  {showPass ? (
                    <AiFillEye
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setShowPass(!showPass)}
                      size={24}
                      className="cursor-pointer"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {isOtpReuired && (
        <Controller
          name="otp"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ background: "#fff", borderRadius: 1 }}
              fullWidth
              placeholder="OTP"
              value={field.value}
              onChange={(e) =>
                field.onChange({
                  target: {
                    value: e.target.value.replace(/[a-zA-Z,.]/g, ""),
                    name: e.target.name,
                  },
                })
              }
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          )}
        />
      )}

      <Controller
        name="captcha_token"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <ReCAPTCHA
            sitekey={googleRecaptchaSiteKey || ""}
            onChange={(token) => field.onChange(token)}
          />
        )}
      />

      <div className="flex items-center justify-between">
        <FormControlLabel
          sx={{ span: { fontSize: "14px", color: "#fff", fontWeight: 600 } }}
          control={
            <Checkbox
              onChange={(e) => setKeepLogedin(e.target.checked)}
              checked={keepLogedin}
            />
          }
          label="Keep me signed in on this device"
        />
        <Link to={RouteLinks.RESET_PASSWORD.link}>
          <p className="text-primary font-semibold text-sm cursor-pointer">
            Forgot Password?
          </p>
        </Link>
      </div>

      <LoadingButton
        variant="contained"
        disableElevation
        color="primary"
        fullWidth
        className="!bg-primary"
        type="submit"
        loading={loginMutation.isLoading}
      >
        Sign in
      </LoadingButton>
      {loginMutation.error?.message && (
        <Alert severity="error">
          {loginMutation.error?.message ?? "Something went wrong!"}
        </Alert>
      )}

      <p className="text-center text-white font-bold text-sm">
        Don’t have an account?{" "}
        <Link className="text-primary" to={RouteLinks.SIGNUP.link}>
          Sign up
        </Link>
      </p>
    </form>
  );
};

export default LoginForm;
