import { TableCell } from "@mui/material";
import { TransactionType } from "interfaces/agent/transaction";
import { FC } from "react";
import { currencyFormatter } from "utils/currency_fromatter";

type DefaultTransactionValueCellProps = {
  type?: TransactionType | undefined | "win" | "loss";
  value?: number | undefined;
  decimalPlaces?: number | undefined;
  currency?: string | undefined;
  align?: "left" | "center" | "right";
};

const DefaultTransactionValueCell: FC<DefaultTransactionValueCellProps> = ({
  type,
  value = 0,
  decimalPlaces = 2,
  currency,
  align = "right",
}) => {
  return (
    <TableCell
      align={align}
      sx={{
        color:
          type && (type === "credit" || type === "win")
            ? "success.main"
            : type && (type === "debit" || type === "loss")
            ? "error.main"
            : "",
        whiteSpace: "nowrap",
      }}
    >
      {type && type === "credit" && "+"}
      {type && type === "debit" && "-"}{" "}
      {currencyFormatter({
        value: value,
        decimalPlaces: decimalPlaces,
      })}{" "}
      {currency?.toUpperCase()}
    </TableCell>
  );
};

export default DefaultTransactionValueCell;
