import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useApproveWithdrawRequestMutation } from "queries/agent/withdraw_query";

type ModalProps = {
  _id: string;
};

export default NiceModal.create<ModalProps>(({ _id }) => {
  const modal = useModal();

  const { isLoading, mutateAsync } = useApproveWithdrawRequestMutation();

  const onConfirm = async () => {
    try {
      await mutateAsync({ id: _id });
    } catch (error) {
      console.log(error);
    }
    modal.remove();
  };

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle color="success.main">
        Do you want to approve this request?
      </DialogTitle>

      <DialogActions>
        <Button
          disabled={isLoading}
          variant="contained"
          color="warning"
          onClick={modal.remove}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={onConfirm}
          variant="contained"
          color="success"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
