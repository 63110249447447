import { LoadingButton } from "@mui/lab";
import { Avatar, InputLabel, TextField, Typography } from "@mui/material";
import { CardUI } from "components/card";
import { SectionTitle } from "components/title";
import { AuthContext } from "context/AuthContext";
import {
  IUserUpdateInputs,
  useUpdateUser,
  useUploadProfilePhoto,
} from "queries/auth";
import React, { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

const ProfileCard: FC = () => {
  const { user } = useContext(AuthContext);
  const useUpdateUserMutation = useUpdateUser();
  const useUploadProfilePhotoMutation = useUploadProfilePhoto();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(user?.data?.photo ?? "");

  const { control, handleSubmit } = useForm<IUserUpdateInputs>({
    defaultValues: {
      name: user?.data?.name ?? "",
    },
  });

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      upload(selectedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const upload = (file: File) => {
    const formData = new FormData();
    formData.append("photo", file);
    toast.promise(useUploadProfilePhotoMutation.mutateAsync(formData), {
      loading: "Loading...",
      error: (err) => {
        setSelectedImage(null);
        setImageUrl("");
        return err.toString();
      },
      success: (res) => res.message ?? "Operation successful",
    });
  };

  const formSubmit = (data: IUserUpdateInputs) => {
    toast.promise(useUpdateUserMutation.mutateAsync(data), {
      loading: "Loading...",
      error: (err) => err.toString(),
      success: (res) => res.message ?? "Operation successful",
    });
  };

  return (
    <CardUI className="flex flex-col gap-8">
      <div className="flex gap-4 items-center">
        <div className="w-16 h-16 relative overflow-hidden rounded-full">
          <input
            accept="image/*"
            type="file"
            id="select-image"
            className="absolute hidden"
            onChange={(e) => setSelectedImage(e.target!.files![0])}
          />
          <label
            htmlFor="select-image"
            className="block absolute z-10 cursor-pointer left-0 top-0 h-full w-full"
          ></label>
          {user?.data?.photo ? (
            <img
              className={`h-full w-full object-cover ${
                useUploadProfilePhotoMutation.isLoading ? "opacity-50" : ""
              }`}
              src={imageUrl || user?.data?.photo}
              alt="avatar"
            />
          ) : (
            <Avatar sx={{ width: "100%", height: "100%" }} />
          )}
          {imageUrl}
          <Avatar sx={{ width: "100%", height: "100%" }} />
        </div>
        <Typography variant="h6">{user?.data?.username ?? ""}</Typography>
      </div>

      <SectionTitle.Default title="General Info" />

      <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-2">
        <InputLabel>Full Name</InputLabel>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              {...field}
              placeholder="Full Name"
              type="text"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
        <LoadingButton
          loading={useUpdateUserMutation.isLoading}
          sx={{ mt: 4 }}
          type="submit"
          variant="outlined"
        >
          Update
        </LoadingButton>
      </form>
    </CardUI>
  );
};

export default ProfileCard;
