import player from "assets/player.png";
import aeBadge from "assets/ranks/ae.png";
import eamBadge from "assets/ranks/eam.png";
import jamBadge from "assets/ranks/jam.png";
import samBadge from "assets/ranks/sam.png";
import { RankSymbol } from "interfaces/agent/rank";

export const iconByRank = (rank?: RankSymbol | undefined) => {
  switch (rank) {
    case "ae":
      return aeBadge;
    case "eam":
      return eamBadge;
    case "jam":
      return jamBadge;
    case "sam":
      return samBadge;
    default:
      return player;
  }
};
