import {
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { useGameCreditHistory } from "queries/games";
import { FC, useState } from "react";
import { getColorSchema } from "utils";
import defaultDateFormat from "utils/date_formatter";
import { PurchaseHistoryStatusType } from "..";

interface ITransactionHistoryProps {
  query: string;
  setStatus: Function;
  status: PurchaseHistoryStatusType;
}

const TransactionHistory: FC<ITransactionHistoryProps> = ({
  query,
  setStatus,
  status,
}) => {
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, error, isError } = useGameCreditHistory(query, page);
  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="GC Purchase History">
        <div className="flex gap-4">
          <Select
            displayEmpty
            value={status}
            label="Status"
            onChange={(event: SelectChangeEvent<PurchaseHistoryStatusType>) => {
              setStatus(event.target.value as PurchaseHistoryStatusType);
            }}
          >
            <MenuItem value="">All Status</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="declined">Declined</MenuItem>
          </Select>
        </div>
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...Array(8)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="mx-auto" width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={300} />
                  </TableCell>
                </TableRow>
              ))}
            {data?.pages?.map((page) =>
              page?.docs?.map((history) => (
                <TableRow key={history?._id}>
                  <TableCell>{defaultDateFormat(history?.createdAt)}</TableCell>
                  <TableCell>{history?.txid}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={history?.status?.toUpperCase()}
                      color={getColorSchema(history?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    {history?.amount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>{history?.note}</TableCell>
                </TableRow>
              ))
            )}

            {hasNoData && <NoTransactions colSpan={5} />}
            {isError && <TableError colSpan={5} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasNoData && (
        <TablePagination
          count={data?.pages[0].totalPages}
          page={page}
          onChange={(newPage) => setPage(newPage)}
        />
      )}
    </>
  );
};

export default TransactionHistory;
