import { Button, Chip, FormHelperText, TextField } from "@mui/material";
import DatePicker from "components/input/DatePicker";
import { SectionTitle } from "components/title";
import React from "react";

const UiTastePage = () => {
  return (
    <div>
      <SectionTitle.Default title="Goriber Storybook"></SectionTitle.Default>
      <div className="h-16"></div>

      <SectionTitle.Default title="Test Title Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah">
        <TextField placeholder="test" />
      </SectionTitle.Default>

      <div className="h-4"></div>

      <SectionTitle.Default title="Test Title Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah">
        <TextField placeholder="test" />
        <TextField placeholder="test" />
      </SectionTitle.Default>

      <div className="h-4"></div>

      <SectionTitle.Default title="Test Title Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah">
        <TextField placeholder="test" />
        <TextField placeholder="test" />
        <TextField placeholder="test" />
      </SectionTitle.Default>

      <div className="h-4"></div>

      <SectionTitle.Default title="Test Title Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah">
        <TextField fullWidth placeholder="test1" />
        <div className="flex gap-4 items-center col-span-1 md:col-span-2">
          <DatePicker fullWidth />
          {" - "}
          <DatePicker fullWidth />
        </div>
        <TextField fullWidth placeholder="test2" />
        <TextField fullWidth placeholder="test3" />
        <TextField fullWidth placeholder="test4" />
      </SectionTitle.Default>

      <div className="h-4"></div>

      <FormHelperText error>Error text</FormHelperText>
      <Chip label="asdasd" color="info" />

      <div className="flex gap-4">
        <Button variant="contained" size="small">
          small
        </Button>
        <Button variant="contained" size="medium">
          medium
        </Button>
        <Button variant="contained" size="large">
          large
        </Button>
      </div>

      <div className="h-4"></div>

      <div className="flex gap-4">
        <Button variant="outlined" size="small">
          small
        </Button>
        <Button variant="outlined" size="medium">
          medium
        </Button>
        <Button variant="outlined" size="large">
          large
        </Button>
      </div>

      <div className="h-4"></div>

      <TextField
        placeholder="asdasd"
        variant="filled"
        size="small"
        helperText="asdasd"
        error
      />
      <TextField
        placeholder="asdasd"
        variant="filled"
        size="medium"
        helperText="Error asdasdasd"
        error={true}
      />

      <div className="h-4"></div>

      <span>
        <Button variant="contained" size="small">
          small
        </Button>
        <TextField placeholder="asdasd" variant="filled" size="small" />
        <TextField
          placeholder="asdasd"
          variant="filled"
          size="small"
          multiline
          rows={3}
        />
      </span>
      <div className="h-4"></div>
      <span>
        <Button variant="contained" size="medium">
          medium
        </Button>
        <TextField
          placeholder="asdasd"
          autoFocus
          variant="filled"
          size="medium"
        />
        <TextField
          placeholder="asdasd"
          variant="filled"
          size="medium"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Button variant="contained" size="small">
                End
              </Button>
            ),
          }}
        />
        <TextField
          placeholder="asdasd"
          variant="filled"
          size="medium"
          multiline
          rows={3}
        />
      </span>
      <div className="h-4"></div>
      <span>
        <Button variant="contained" size="large">
          large
        </Button>
        <TextField
          placeholder="asdasd"
          variant="filled"
          size="large"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Button variant="contained" size="small">
                End
              </Button>
            ),
          }}
        />
      </span>
    </div>
  );
};

export default UiTastePage;
