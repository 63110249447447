import { CardUI } from "components/card";

type PropsTypes = {
  items: number
}

export const ToolListSkeleton = (props: PropsTypes) => {
  return (
    <>
      {[...Array(props.items)].map((i) => (
        <CardUI className="min-h-[300px] animate-pulse">
          <div className="rounded-md h-[200px] bg-slate-200 w-full mb-3" />
          <div className="rounded-full h-[10px] bg-slate-200 w-full mb-2" />
          <div className="rounded-full h-[8px] bg-slate-200 w-full mb-2" />
          <div className="rounded-full h-[8px] bg-slate-200 w-full" />
        </CardUI>
      ))}
    </>
  );
}

export const ToolEditSkeleton = () => {
  return (
    <div className="min-h-[300px] animate-pulse">
      <div className="rounded-lg h-[50px] bg-slate-200 w-full mb-4" />
      <div className="rounded-lg h-[150px] bg-slate-200 w-full mb-2" />
    </div>
  );
}

export const ToolShowSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="rounded-md h-[200px] bg-slate-200 w-full sm:w-[350px] mb-4" />
      <div className="rounded-md h-[30px] bg-slate-200 w-full sm:w-3/5 mb-4" />
      <div className="rounded-full h-3 bg-slate-200 w-full mb-4" />
      <div className="rounded-full h-3 bg-slate-200 w-full mb-4" />
      <div className="rounded-full h-3 bg-slate-200 w-full mb-4" />
      <div className="rounded-full h-3 bg-slate-200 w-full mb-4" />
      <div className="rounded-full h-3 bg-slate-200 w-full mb-4" />
    </div>
  );
} 