import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import {
  SubmitResetPasswordPayload,
  useSubmitResetPasswordMutation,
} from "queries/auth/reset_password_mutations";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

type Props = {};

const schema = yup.object({
  password: yup.string().required("Password is Required"),
});

const SubmitResetPassword = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = (location.state as { token?: string }).token;

  const { isLoading, mutateAsync, isSuccess, data } =
    useSubmitResetPasswordMutation(token ?? "");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitResetPasswordPayload>({
    defaultValues: { password: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: SubmitResetPasswordPayload) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate(-1);
    }
  }, [navigate, token]);

  useEffect(() => {
    if (isSuccess) {
      navigate(RouteLinks.LOGIN.link, { replace: true });
    }
  }, [data?.token, isSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-white">
      <Controller
        control={control}
        name="password"
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            fullWidth
            placeholder="New Password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        )}
      />

      <div className="h-4" />

      <LoadingButton
        fullWidth
        loading={isLoading}
        type="submit"
        variant="contained"
        sx={{ "&.Mui-disabled": { backgroundColor: "primary.dark" } }}
      >
        Submit
      </LoadingButton>
    </form>
  );
};

export default SubmitResetPassword;
