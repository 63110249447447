import { Card, Skeleton, Typography } from "@mui/material";
import UsdcLogo from "assets/images/usdc_logo.svg";
import { FC } from "react";
import { currencyFormatter } from "utils/currency_fromatter";

type OverviewUsdcProps = {
  isLoading?: boolean;
  name?: string;
  balance?: number | undefined;
  commingSoon?: boolean;
};

const OverviewUsdc: FC<OverviewUsdcProps> = ({
  isLoading,
  name = "",
  balance = 0,
  commingSoon = false,
}) => {
  return (
    <Card
      elevation={10}
      className="relative flex flex-col gap-4 !bg-gradient-to-br from-[#6ABAE6] to-[#1D719F]"
      sx={{ color: "white", p: 3 }}
    >
      <img
        className="absolute z-10 top-0 right-0"
        src={UsdcLogo}
        alt="USDC Logo"
      />
      <div className="flex-1 flex flex-col z-20">
        {commingSoon ? (
          <>
            <Typography variant="subtitle1">{name}</Typography>

            <div className="flex-1" />

            <Typography variant="h5" textAlign="center">
              {isLoading ? <Skeleton variant="text" /> : "Coming Soon"}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle1">{name}</Typography>

            {isLoading ? (
              <Typography variant="h4">
                <Skeleton variant="text" />
              </Typography>
            ) : (
              <Typography variant="h4">
                {currencyFormatter({ value: balance, decimalPlaces: 2 })}
              </Typography>
            )}
          </>
        )}

        <div className="flex-1" />
      </div>
    </Card>
  );
};

export default OverviewUsdc;
