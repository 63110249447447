import {
  Button,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionDescriptionCell from "components/table/TransactionDescriptionCell";
import TransactionValueCell from "components/table/TransactionValueCell";
import { RouteGroups, RouteLinks } from "constants/routeLinks";
import { useTransactionHistory } from "queries/wallets";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { getColorSchema } from "utils";
import defaultDateFormat from "utils/date_formatter";

type WithdrawHistoryProps = {
  withdrway_history_query: string;
};
const WithdrawHistory: FC<WithdrawHistoryProps> = ({
  withdrway_history_query,
}) => {
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, error, isError } = useTransactionHistory(
    withdrway_history_query,
    page
  );

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...Array(8)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={300} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                </TableRow>
              ))}
            {data?.pages?.map((page) =>
              page?.docs.map((transaction) => (
                <TableRow key={transaction?._id}>
                  <TableCell>
                    {defaultDateFormat(transaction?.createdAt)}
                  </TableCell>
                  <TableCell>{transaction?.txid}</TableCell>
                  <TransactionValueCell
                    type={transaction?.transactionType}
                    data={transaction}
                  />
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.status?.toUpperCase()}
                      color={getColorSchema(transaction?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <TransactionDescriptionCell transaction={transaction} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      sx={{ borderRadius: 50 }}
                      size="small"
                      color="primary"
                      disabled={transaction?.status === "declined"}
                      component={Link}
                      to={`/${RouteGroups.USER.link}/${RouteLinks.TRANSACTION_HISTORY_DETAILS.link}/${transaction?._id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}

            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasNoData && (
        <TablePagination
          count={data?.pages[0].totalPages}
          page={page}
          onChange={(newPage) => setPage(newPage)}
        />
      )}
    </>
  );
};

export default WithdrawHistory;
