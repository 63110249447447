import { Skeleton, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";

type SkeletonProps = {
  columns: number;
  rows: number;
};

const TableSkeleton: FC<SkeletonProps> = ({ rows, columns }) => {
  return (
    <>
      {[...Array(rows)].map((row, index) => (
        <TableRow key={index}>
          {[...Array(columns)].map((cell, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
