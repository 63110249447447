import { Alert, Backdrop, CircularProgress } from "@mui/material";
import ResponsiveDrawer, { BackButtonLinkType } from "components/layout/Drawer";
import { RouteGroups } from "constants/routeLinks";
import { AuthContext, IUser } from "context/AuthContext";
import { useUser } from "queries/auth";
import React, { FC, useContext } from "react";
import { Link, Outlet } from "react-router-dom";

type RequireAuthProps = {
  backButtonLink?: BackButtonLinkType;
};

const RequireAuth: FC<RequireAuthProps> = ({ backButtonLink }) => {
  const auth = useContext(AuthContext);
  const user = useUser((data: IUser) => auth.setUser(data));

  if (user.isLoading) {
    return (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (user.isError) {
    return (
      <div className="flex justify-center p-12">
        <Alert sx={{ width: { xs: "100%", lg: "50%" } }}>
          Something went wrong! <Link to="/">Try Again</Link>
        </Alert>
      </div>
    );
  }

  return (
    <ResponsiveDrawer
      role={user?.data?.role ?? RouteGroups.USER.name}
      backButtonLink={backButtonLink}
    >
      <Outlet />
    </ResponsiveDrawer>
  );
};

export default RequireAuth;
