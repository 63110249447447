import {
  Chip,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import TransactionDescriptionCell from "components/table/TransactionDescriptionCell";
import TransactionValueCell from "components/table/TransactionValueCell";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import { TransactionStatusType, useTransactionHistory } from "queries/wallets";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { getColorSchema } from "utils";
import defaultDateFormat from "utils/date_formatter";

interface IState {
  status: TransactionStatusType | "";
  criteria: string;
}

const WalletTransactionHistory: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [state, setState] = useState<IState>({
    criteria: "win,loss,purchase,withdraw,deposit,transfer",
    status: "",
  });

  const changeHandler = ({ target: { value, name } }: any) => {
    setState({ ...state, [name]: value });
  };

  const updateQuery = () => {
    const parsed = queryString.parse(query);
    if (state.status) {
      parsed.status = state.status;
    } else {
      delete parsed.status;
    }
    if (state.criteria) {
      parsed.criteria = state.criteria;
    } else {
      delete parsed.criteria;
    }

    const uri = queryString.stringify(parsed);
    if (uri) {
      return setQuery("&" + uri);
    }
    setQuery("");
  };

  useEffect(() => {
    updateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const { data, isLoading, error, isError } = useTransactionHistory(
    query,
    page
  );
  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Transaction History">
        <div className="flex gap-4">
          <Select
            value={state.criteria}
            label="Criteria"
            name="criteria"
            defaultValue=""
            displayEmpty
            onChange={changeHandler}
          >
            <MenuItem value="win,loss,purchase,withdraw,deposit,transfer">
              All Criteria
            </MenuItem>
            <MenuItem value="loss">Loss</MenuItem>
            <MenuItem value="win">Win</MenuItem>
            <MenuItem value="purchase">Purchase</MenuItem>
            <MenuItem value="withdraw">Withdraw</MenuItem>
            <MenuItem value="deposit">Deposit</MenuItem>
            <MenuItem value="transfer">Transfer</MenuItem>
          </Select>
          <Select
            displayEmpty
            value={state.status}
            label="Status"
            name="status"
            onChange={changeHandler}
          >
            <MenuItem value="">All Status</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="declined">Declined</MenuItem>
          </Select>
        </div>
      </SectionTitle.Default>
      <SpaceBar.TitleGap />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>TXID</TableCell>
              <TableCell align="center">Criteria</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...Array(8)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={150} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={70} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={300} />
                  </TableCell>
                </TableRow>
              ))}
            {data?.pages?.map((page) =>
              page?.docs.map((transaction) => (
                <TableRow key={transaction?._id}>
                  <TableCell>
                    {defaultDateFormat(transaction?.createdAt)}
                  </TableCell>
                  <TableCell>{transaction?.txid}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.criteria?.toUpperCase()}
                      color={
                        transaction?.criteria?.toUpperCase() === "WITHDRAW"
                          ? "secondary"
                          : "info"
                      }
                    />
                  </TableCell>
                  <TransactionValueCell
                    type={transaction?.transactionType}
                    data={transaction}
                  />
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={transaction?.status?.toUpperCase()}
                      color={getColorSchema(transaction?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <TransactionDescriptionCell transaction={transaction} />
                  </TableCell>
                </TableRow>
              ))
            )}

            {hasNoData && <NoTransactions colSpan={6} />}
            {isError && <TableError colSpan={6} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasNoData && (
        <TablePagination
          count={data?.pages[0].totalPages}
          page={page}
          onChange={(newPage) => setPage(newPage)}
        />
      )}
    </>
  );
};

export default WalletTransactionHistory;
