// import OverviewKryptobetWallet from "./components/OverviewKryptobetWallet";
import { useWalletInformation } from "../../../queries/agent/wallet.query";
import OverviewTotalGC from "./components/OverviewTotalGC";
import OverviewUsdc from "./components/OverviewUsdc";
import TransactionHistory from "./components/TransactionHistory";

const AgentWalletPage = () => {
  const { data, isLoading } = useWalletInformation();

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* <OverviewTether isLoading={isLoading} />
        <OverviewUsdc isLoading={isLoading} commingSoon={true} /> */}
        {data && data.length > 0
          ? data.map((item, i) =>
              item.currencyCode === "GC" ? (
                <OverviewTotalGC
                  key={i}
                  isLoading={isLoading}
                  name={item.name}
                  balance={Number(item.available)}
                  equivalentAmount={Number(item.equivalentAmount)}
                />
              ) : item.currencyCode === "WB" ? (
                <OverviewUsdc
                  key={i}
                  isLoading={isLoading}
                  name={item.name}
                  balance={Number(item.available)}
                  commingSoon={false}
                />
              ) : null
            )
          : null}
      </div>

      <div className="h-12" />

      <TransactionHistory />
    </>
  );
};

export default AgentWalletPage;
