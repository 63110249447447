import { useQuery } from "react-query"
import { privateRequest } from "config/axios.config"
import { API_ENDPOINTS_USER } from "constants/api_endpoints"
import { axiosErrorHandler } from "utils/errorHandler"

export const FETCH_USER_TOOLS_DATA = "FETCH_USER_TOOLS_DATA"
export const FETCH_USER_SPECIFIC_TOOLS_DATA = "FETCH_USER_SPECIFIC_TOOLS_DATA"
/* Tools lists */
export const useToolsRequestsQuery = () => {
  return useQuery<IRToolListResponse[], Error>(FETCH_USER_TOOLS_DATA, async () => {
    try {
      const API_URL = API_ENDPOINTS_USER.tool
      const response = await privateRequest.get(API_URL)
      return response?.data?.data
    } catch (error) {
      axiosErrorHandler(error);
    }
  })
}

/* Show specific tool */
export const useToolShowQuery = (id: string) => {
  return useQuery<IRToolListResponse, Error>(FETCH_USER_SPECIFIC_TOOLS_DATA,
    async () => {
      try {
        const API_URL = API_ENDPOINTS_USER.tool + "/show/" + id
        const response = await privateRequest.get(API_URL)
        return response?.data?.data
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  )
}