import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { RankSymbol, RankUpgradeRequestStatus } from "interfaces/agent/rank";
import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface IRank {
  title: string;
  symbol: RankSymbol;
  commissionRate: number;
  _id: string;
}

export const useAvailableRank = (userId: string) => {
  return useQuery<IRank[], Error>("fetch-availableRanks", async () => {
    try {
      const resp = await privateRequest.get(`utils/availableRanks/${userId}`);
      return resp?.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};

export interface RankData {
  _id: string;
  uuid: string;
  user: {
    _id: string;
    name: string;
    username: string;
    photo: string;
    rank: RankSymbol;
    email: string;
  };
  gc_purchase: any;
  rank: RankSymbol;
  previousRank: RankSymbol | null | undefined;
  status: RankUpgradeRequestStatus;
  createdAt: string;
  note: any;
}

export interface RankUpgradeRequestsResp extends IPagination {
  docs: RankData[];
}

export const useRankUpgradeRequestHistoryQuery = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.rankUpgrade.rankUpgradeRequestHistory;
  return useInfiniteQuery<RankUpgradeRequestsResp, Error>(
    [QUERY_KEYS.rankUpgradeRequestHistory, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

// Approve Rank Upgrade Request mutation:
interface IRankUpgradeApprovePayload {
  id: string;
}
interface IRankUpgradeApproveResp {
  status: string;
  message: string;
}

export const useApproveRankUpgradeRequestMutation = () => {
  return useMutation<
    IRankUpgradeApproveResp,
    Error,
    IRankUpgradeApprovePayload
  >(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.rankUpgrade.approve,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.rankUpgradeRequestHistory);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

// Decline Rank Upgrade Request mutation:
export interface IRankUpgradeDeclinePayload {
  id: string;
  rejection_note: string;
}
interface IRankUpgradeDeclineResp {
  status: string;
  message: string;
}

export const useDeclineRankUpgradeRequestMutation = () => {
  return useMutation<
    IRankUpgradeDeclineResp,
    Error,
    IRankUpgradeDeclinePayload
  >(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.rankUpgrade.decline,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.rankUpgradeRequestHistory);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
