import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface IFiatCurrency {
  name: string;
  status: string;
  _id: string;
  symbol: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: string;
  name_plural: string;
}

export const useFiatCurrencyListQuery = () => {
  const url = API_ENDPOINTS_AGENT.public.fiatCurrencies;
  return useQuery<IFiatCurrency[], Error>(
    [QUERY_KEYS.fiatCurrencyList],
    async () => {
      try {
        const resp = await privateRequest.get(url);
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

export interface IPaymentMethod {
  title: string;
  slug: string;
  description: string;
  type: string;
  timeWindow: number;
  status: string;
  _id: string;
  currency: string | null;
  cryptoNetwork: string | null;
  fiatCurrency?: {
    name: string;
    symbol: string;
    symbol_native: string;
    decimal_digits: string;
    rounding: string;
    code: string;
    name_plural: string;
    _id: string;
  };
}

export const usePaymentMethodListQuery = () => {
  const url = API_ENDPOINTS_AGENT.paymentMethod.list;
  return useQuery<IPaymentMethod[], Error>(
    [QUERY_KEYS.paymentMethodList],
    async () => {
      try {
        const resp = await privateRequest.get(url);
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

// Create method mutation:

export const CRYPTO_NETWORKS = ["erc20", "bep20", "trc20"];
export const CRYPTO_CURRENCIES = ["usdt", "usdc"];

export interface IPayloadCreatePaymentMethodCrypto {
  type: "crypto";
  cryptoNetwork: string;
  currency: string;
  timeWindow: number;
  description?: string | undefined;
}
export interface IPayloadCreatePaymentMethodFiat {
  type: "fiat";
  currency: string; //Id of currency
  title: string;
  timeWindow: number;
  description?: string | undefined;
}

interface ICreateMethodResp {
  status: boolean;
  message: string;
}

export const useCreatePaymentMethodMutation = () => {
  return useMutation<
    ICreateMethodResp,
    Error,
    IPayloadCreatePaymentMethodCrypto | IPayloadCreatePaymentMethodFiat
  >(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.paymentMethod.create,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },

    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.paymentMethodList);
        toast.success(data.message);
      },
      onError: (error, newMethod, context: any) => {
        toast.error(error.message);
      },
    }
  );
};

// Delete method mutation:

interface IDeleteMethodResp {
  status: boolean;
  message: string;
}

export const useDeletePaymentMethodMutation = () => {
  return useMutation<IDeleteMethodResp, Error, string>(
    async (id: string) => {
      try {
        const resp = await privateRequest.delete(
          API_ENDPOINTS_AGENT.paymentMethod.delete + "/" + id
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },

    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.paymentMethodList);
        toast.success(data.message);
      },
      onError: (error, id, context: any) => {
        toast.error(error.message);
      },
    }
  );
};
