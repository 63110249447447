import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import FaqItem from "./components/faqItem";
interface IFAQ {
  title: string;
  desc: JSX.Element | string;
}

const data: IFAQ[] = [
  {
    title: "How do I log in to KryptoBet?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a target={"_blank"} href="https://www.kryptobet.io/" rel="noreferrer">
          www.kryptobet.io
        </a>{" "}
        <br />
        Step 2: Enter your credentials and click &apos;LOGIN&apos;.
        <br />
        You will be directed to the KryptoBet dashboard.
      </>
    ),
  },
  {
    title: "How do I get registered to KryptoBet?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a target={"_blank"} href="https://www.kryptobet.io/" rel="noreferrer">
          www.kryptobet.io
        </a>{" "}
        <br />
        Step 2: Enter your personal details.
        <br />
        Step 3: Enter your sponsor&apos;s username if you haven&apos;t used a
        reffaral link.
        <br />
        Step 4: Click the check box if you agree to Terms & Conditions, Privacy
        Policy, Income Disclaimer & Genaral Disclaimer
        <br />
        Step 5: Get Registered by clicking on &apos;Register&apos;.
        <br />
        <br />
        Note : If a friend referred you to the platform use the referral
        link/code provided.
      </>
    ),
  },
  {
    title: "How do I reset my password?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a target={"_blank"} href="https://www.kryptobet.io/" rel="noreferrer">
          www.kryptobet.io
        </a>{" "}
        <br />
        Step 2: Click on &apos;Forgot Password&apos;?
        <br />
        Step 3: You will be redirected to a &apos;Reset Password&apos; page,
        enter your email address to which a 6 digit code will be sent.
        <br />
        Step 4: Enter the 6 digit code on{" "}
        <a target={"_blank"} href="www.user.kryptobet/net/reset-password">
          www.user.kryptobet/net/reset-password
        </a>{" "}
        and click &apos;Next&apos;.
        <br />
        <br />
        You should be able to reset your password.
      </>
    ),
  },
  {
    title: "How do I contact KryptoBet?",
    desc: (
      <>
        If you wish to get in touch with us, please write to us at{" "}
        <a href="mailto:support@kryptobet.io">support@kryptobet.io</a>
      </>
    ),
  },
  {
    title: "Are there any fees to create an account?",
    desc: (
      <>
        No, there are no fees applicable to create your account, it’s an
        absolutely free process.
      </>
    ),
  },
  {
    title: "What can be the reason for login problems?",
    desc: (
      <>
        Sometimes these types of problems can be caused by maintenance work on
        the website. Don`t worry about this, you just need to wait for a few
        minutes and then try to login again. If the problem persists, you may
        contact our support team at{" "}
        <a href="mailto:support@kryptobet.io">support@kryptobet.io</a>
      </>
    ),
  },
  {
    title: "Is there an age restriction for these games?",
    desc: (
      <>
        Yes, you are required to be at least 21 years of age to be eligible to
        play on KryptoBet.
      </>
    ),
  },
  {
    title: "How Game Credit (GC) works?",
    desc: (
      <>
        GC is used to access the games available on KryptoBet. You can GC to
        play and invest in the games or to even swap to UDSC or USDT.
      </>
    ),
  },
  {
    title: "How can I purchase GC?",
    desc: (
      <>
        In order to purchase GC, users are needed to request the agent. Upon
        purchasing GC, the player can request the agent for a rank upgrade.{" "}
        <br />
        This request will be reflected on the (agent) agent dashboard. The agent
        therefore has the permission to accept or reject the request. Based on
        their decision, users should be notified via email or a notification.
      </>
    ),
  },
  {
    title: "How can I request for GC?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>{" "}
        <br />
        Step 2: Log in with your user credentials. <br />
        Step 3: Select Game Credit request from the left side menu of your
        dashboard. <br />
        Step 4: Select the ‘Agents Name’, GC Amount and any notes if you wish.
        Finally press ‘Request to buy’. <br />
        Once your agent approves, the respective amount will be credited into
        your account.
      </>
    ),
  },
  {
    title: "How do I approve a GC request?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>
        <br />
        Step 2: Log in with your user credentials.
        <br />
        Step 3: Select GC Requests from the left side menu of your dashboard.{" "}
        <br />
        Step 4: Check for any pending requests and approve it if everything
        checks out.
        <br />
        <br />
        Once your agent approves, the respective amount will be credited into
        your account.
      </>
    ),
  },
  {
    title: "How can I swap USDT/USDC/ Winning Balance to GC?",
    desc: (
      <>
        You can easily swap USDT/USDC/Winning balance to GC using our Krpytobet
        SWAP feature accessible at{" "}
        <a
          target="_blank"
          href="http://www.krytpobet.io/swap"
          rel="noopener noreferrer"
        >
          www.krytpobet.io/swap
        </a>
      </>
    ),
  },
  {
    title: "What are the lottery games available?",
    desc: (
      <>
        <ul>
          <li>Power Ball</li>
          <li>Euro Millions</li>
          <li>Megalotto</li>
          <li>Mega Millions</li>
          <li>Oz Lotto</li>
          <li>MarkSix Lottery</li>
        </ul>
      </>
    ),
  },
  {
    title: "Do I have to purchase GC to buy a ticket?",
    desc: (
      <>
        Yes, you are required to have the GC equivalent of the ticket amount to
        purchase it.
      </>
    ),
  },
  {
    title: "What is the price of a lottery ticket?",
    desc: (
      <>
        The price of the lottery ticket may vary depending on the type of
        lottery you wish to purchase. Updated prices will be available on{" "}
        <a
          target="_blank"
          href="http://www.krytpobet.io/lottery"
          rel="noopener noreferrer"
        >
          www.kryptobet.io/lottery
        </a>
      </>
    ),
  },
  // {
  //   title: "What is KryptoBet Wallet ?",
  //   desc: (
  //     <>
  //       This wallet consists of Game wins & commissions received from the
  //       player’s losing GC
  //     </>
  //   ),
  // },
  {
    title: "What is In-Play Balance Wallet ?",
    desc: <>This wallet consists of KryptoBet Wallet Balance + Game Credits</>,
  },
  {
    title: "How can I deposit USDT?",
    desc: (
      <>
        You can visit the user dashboard and access your wallet, where you will
        be given the option to ‘Deposit’. Once you click on this you can proceed
        to deposit any amount of USDT you wish. This functionality will be
        available in the 2nd phase of the project.
      </>
    ),
  },
  {
    title: "How can I deposit USDC?",
    desc: (
      <>
        You can visit the user dashboard and access your wallet, where you will
        be given the option to ‘Deposit’. Once you click on this you can proceed
        to deposit any amount of USDC you wish.This functionality will be
        available in the 2nd phase of the project
      </>
    ),
  },
  {
    title: "What are the crypto networks supported ?",
    desc: (
      <>
        As of 19.05.2022 we only support ERC20. However, we are looking to
        broaden our networks in the near future.
      </>
    ),
  },
  // {
  //   title: "What is the player's journey to become an agent?",
  //   desc: (
  //     <>
  //       There are four possible agent ranks available on KryptoBet and they are:
  //       <br />
  //       Player → Account Executive (AE) → Junior Account Manager (JAM) → Senior
  //       Account Manager (SAM) → Elite Account Manager (EAM)
  //       <br />
  //       In order to become an agent, the player is expected to submit a formal
  //       request to their agent in order to be considered for an agent rank
  //     </>
  //   ),
  // },
  {
    title: "How to get promoted as an agent?",
    desc: (
      <>
        If you wish to be promoted, you are required to submit a formal request
        to their agent in order to be considered for any promotion that you may
        be eligible for. The lowest rank being the Elite Account Executive (AE).
        and the highest being the Elite Account Manager (EAM)
      </>
    ),
  },
  // {
  //   title: "What are the commissions based on the agent rank?",
  //   desc: (
  //     <>
  //       The commissions based on agent ranks are as follows:
  //       <br />
  //       <br />
  //       Elite Account Manager
  //       <br />
  //       (EAM) = Earn upto 60% Commissions
  //       <br />
  //       <br />
  //       Senior Account Manager
  //       <br />
  //       (SAM) = Earn upto 50% Commissions
  //       <br />
  //       <br />
  //       Junior Account Manager
  //       <br />
  //       (JAM) = Earn upto 40% Commissions
  //       <br />
  //       <br />
  //       Account Executive
  //       <br />
  //       (AE/Agent) = Earn upto 30% Commissions
  //     </>
  //   ),
  // },
  {
    title: "Is my account & funds secure?",
    desc: "We care about the security of your personal data. KryptoBet team does everything to protect your account and your personal information. We conduct regular security audits of KryptoBet's website. Security is our priority and we do everything possible to provide you with the most secure crypto trading experience. KryptoBet only works with platforms that have a strong reputation for maintaining high security standards",
  },
  {
    title: "What is an In-Play Balance Wallet ?",
    desc: <>This wallet consists of Kryptobet Wallet Balance + Game Credits.</>,
  },
  {
    title: "How does in-Play balance work ?",
    desc: (
      <>
        In play balance consists of Kryptobet wallet balance and Game Credits.
      </>
    ),
  },
  {
    title: "How do I make a withdrawal ?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>
        <br />
        Step 2: Log in with your user credentials. <br />
        Step 3: Select Wallets from the left side menu of your dashboard. <br />
        Step 4: Select the ‘Agents Name’, Amount and enter the Payment Details.
        Finally press ‘Request Withdrawal’. <br />
        Once your agent approves, the respective amount will be credited into
        your account
      </>
    ),
  },
  {
    title: "What are the withdrawal methods ?",
    desc: (
      <>
        You can enter any mode of withdrawal that you are comfortable with. E.g:
        Bank transfer, direct deposit etc. The player then can select any of the
        payment methods listed by the agent.
      </>
    ),
  },
  {
    title: "Does KryptoBet handle withdrawals?",
    desc: (
      <>
        KryptoBet handles withdrawals based on available balances on KryptoBet
        only, and does not hold responsibility for paying out winnings from
        games handled by external parties.
      </>
    ),
  },
  {
    title: "How can I view my commissions?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>{" "}
        <br />
        Step 2: Log in with your user credentials. <br />
        Step 3: Select ‘My Commision’ from the left side menu of your dashboard.{" "}
        <br />
        You can view your Commission history here.
      </>
    ),
  },
  {
    title: "Can I cashout my GC anytime ?",
    desc: <>Yes, you can request to cash out anytime.</>,
  },
  {
    title: "How can I cashout my GC ?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>{" "}
        <br />
        Step 2: Log in with your user credentials. <br />
        Step 3: Click on ‘Wallets' on the Player dashboard and click the
        Withdraw button in the GC Wallet. <br />
        Step 4: Select any method of payment that is suitable for you out of the
        options your agent has provided. <br />
      </>
    ),
  },
  {
    title: "Why do I not have any withdrawal methods?",
    desc: (
      <>
        This means your agent hasn’t added any withdrawal methods from his/her
        agent dashboard. Your referred agent has to add possible cashout methods
        for players.
      </>
    ),
  },
  {
    title: "How do I perform cryptocurrency withdrawals?",
    desc: (
      <>
        Step 1: Visit{" "}
        <a
          className="text-primary"
          target="_blank"
          href="http://www.krytpobet.io"
          rel="noopener noreferrer"
        >
          www.krytpobet.io
        </a>{" "}
        <br />
        Step 2: Log in with your user credentials. <br />
        Step 3: Click on ‘Dashboard’ and select ‘Withdraw’ which is available
        under your balance information. <br />
        Step 4: You can then select your preferred agent, and choose
        Cryptocurrency and enter the type/network. Furthermore, enter the amount
        and wallet address and proceed to ‘Request Withdrawal’.
      </>
    ),
  },
];

export default function FaqPage() {
  return (
    <>
      <SectionTitle.Simple
        alignment="center"
        title="Frequently asked questions"
      />
      <SpaceBar.SectionGap />
      {data.map((faq, i) => (
        <FaqItem key={i} sx={{ mb: 2 }} title={faq.title} desc={faq.desc} />
      ))}
    </>
  );
}
