import { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  colors,
  IconButton,
  Typography,
} from "@mui/material";
import { SectionTitle } from "components/title";
import {
  IPaymentMethod,
  useDeletePaymentMethodMutation,
  usePaymentMethodListQuery,
} from "queries/agent/payment_method_query";
import { FC } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import NewPaymentMethodDialog from "./components/NewPaymentMethodDialog";

type Props = {};

const AgentPaymentMethodPage = (props: Props) => {
  const newPaymentMethodDialog = useModal(NewPaymentMethodDialog);

  const { isLoading, data: methods } = usePaymentMethodListQuery();

  return (
    <div className="max-w-md flex flex-col gap-4">
      <SectionTitle.Default title="Payment Method" />

      <Button variant="contained" onClick={() => newPaymentMethodDialog.show()}>
        Add New
      </Button>

      {isLoading && <CircularProgress className="self-center my-4" />}

      {methods?.map((method, index) => (
        <MethodCard key={index} method={method} />
      ))}
    </div>
  );
};

export default AgentPaymentMethodPage;

type MethodCardProps = {
  method: IPaymentMethod;
};

const MethodCard: FC<MethodCardProps> = ({ method }) => {
  const { isLoading, mutateAsync } = useDeletePaymentMethodMutation();

  return (
    <Card sx={{ padding: "16px", borderRadius: "16px" }}>
      <div className="flex gap-4 justify-between">
        {method.type === "crypto" && (
          <Typography variant="subtitle2">CryptoCurrency</Typography>
        )}
        {method.type === "fiat" && (
          <Typography variant="subtitle2">
            Fiat Currency{" "}
            <span style={{ color: colors.green[500], fontSize: 14 }}>
              ({method.currency})
            </span>
          </Typography>
        )}

        <IconButton
          disabled={isLoading}
          onClick={async () => {
            await mutateAsync(method._id);
          }}
          color="error"
          sx={{ backgroundColor: colors.red[200], borderRadius: "10px" }}
        >
          {isLoading ? (
            <div className="flex items-center">
              <CircularProgress size={24} color="error" />
            </div>
          ) : (
            <RiDeleteBinLine />
          )}
        </IconButton>
      </div>

      {method.type === "crypto" && (
        <Typography variant="h6" textTransform="uppercase">
          {method.currency}{" "}
          {method.cryptoNetwork && (
            <>
              <Chip
                label={`${method.cryptoNetwork}`}
                sx={{ textTransform: "uppercase" }}
                color="success"
                size="small"
              />{" "}
              {method.timeWindow > 0 && (
                <Chip
                  label={`${method.timeWindow} Mins`}
                  color="success"
                  size="small"
                />
              )}
            </>
          )}
        </Typography>
      )}

      {method.type === "fiat" && (
        <Typography variant="h6">
          {method.title}{" "}
          {method.timeWindow && (
            <Chip
              label={`${method.timeWindow} Mins`}
              color="success"
              size="small"
            />
          )}
        </Typography>
      )}

      <div className="h-2" />

      {method.description && (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">{method.description}</Typography>
        </>
      )}
    </Card>
  );
};
