import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { RankSymbol } from "interfaces/agent/rank";
import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface GameCreditRequestsResp {
  docs: GameCreditData[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: any;
  nextPage: any;
}

export interface GameCreditData {
  _id: string;
  txid: string;
  currencyCode: string;
  amount: number;
  status: string;
  createdAt: string;
  id: string;
  user: {
    _id: string;
    name: string;
    username: "user2";
    photo: any;
    rank: RankSymbol;
    email: string;
  };
}

export const useGameCreditRequestsQuery = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.gameCredit.gameCreditRequests;
  return useInfiniteQuery<GameCreditRequestsResp, Error>(
    [QUERY_KEYS.gameCreditRequests, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

// Approve GC Request mutation:
interface IGcApproveInputs {
  id: string;
}
interface IGcApproveResp {
  status: string;
  message: string;
}

export const useApproveGcRequestMutation = () => {
  return useMutation<IGcApproveResp, Error, IGcApproveInputs>(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.gameCredit.approve,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.gameCreditRequests);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

// Approve GC Request mutation:
export interface IGcDeclinePayload {
  id: string;
  rejection_note: string;
}
interface IGcDeclineResp {
  status: string;
  message: string;
}

export const useDeclineGcRequestMutation = () => {
  return useMutation<IGcDeclineResp, Error, IGcDeclinePayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.gameCredit.decline,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.gameCreditRequests);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
