
import { useParams } from "react-router-dom"
import { ToolShowSkeleton } from "components/skeletons/tool.skeleton"
import { useToolShowQuery } from "../../../queries/tools"
import defaultDateFormat from "../../../utils/date_formatter"

const Show = () => {
  const { id } = useParams()
  const { isLoading, data } = useToolShowQuery(id || "")

  return (
    <div>
      {!data && isLoading ? <ToolShowSkeleton /> : null}
      {data && !isLoading ?
        <div>
          <img
            src={data?.banner}
            alt=""
            className="max-w-full sm:max-w-lg h-auto mb-4"
          />

          <p className="text-xl font-bold">{data?.title}</p>
          <small className="mb-3">Posted on: {defaultDateFormat(data.createdAt)}</small>
          <p className="font-normal">{data?.description}</p>
        </div>
        : null}

    </div>
  );
};

export default Show;