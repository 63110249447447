import { privateRequest } from "config/axios.config";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

interface IRDashboard {
  totalWithdraw: number;
  balances: {
    GC: number;
    WB: number;
    inPlay: number;
  };
  totalGCPurchase: number;
  totalCommission: number;
  agents: {
    eam: number;
    sam: number;
    jam: number;
    ae: number;
    player: number;
    total: number;
  };
  agentRequest: number;
  gcPurchaseRequest: number;
  gcLosses: number;
  commissionCutoffData?: {
    cw: number;
    cl: number;
    nextCutOff: Date;
    lastCutOff: Date;
  } | null;
}

export const useDashboardInformation = () => {
  return useQuery<IRDashboard, Error>(
    "fetch-dashboard-information",
    async () => {
      try {
        const resp = await privateRequest.get("agent/dashboard");
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};
