import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Avatar,
  Card,
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CardUI } from "components/card";
import { AuthContext } from "context/AuthContext";
import {
  IChat,
  ITransactionDetailsSocketState,
} from "hooks/transactionDetailsHook";
import {
  SendMessagePayload,
  useGetMessages,
  useSendMessage,
} from "queries/chat";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsChatDots, BsFillFileImageFill } from "react-icons/bs";
import defaultDateFormat from "utils/date_formatter";

type Props = {
  id: string;
  hookState: ITransactionDetailsSocketState;
};

const ChatCard = ({
  hookState: { chat, chatsLoading, chatsError, isNewChatRecieved },
  id,
}: Props) => {
  const { user } = useContext(AuthContext);

  const { hasNextPage, fetchNextPage } = useGetMessages({ id });

  const messagesEndRef = useRef<HTMLHeadingElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo({
      behavior: "smooth",
      top: messagesEndRef.current?.scrollHeight,
      left: 0,
    });
  };

  const scrollTrackFunc = useCallback(() => {
    if (
      messagesEndRef !== null &&
      messagesEndRef?.current?.scrollTop === 0 &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    const ref = messagesEndRef?.current;

    if (ref) {
      ref?.addEventListener("scroll", scrollTrackFunc);

      return () => {
        ref?.removeEventListener("scroll", scrollTrackFunc);
      };
    }
  }, [messagesEndRef, scrollTrackFunc]);

  useLayoutEffect(() => {
    if (isNewChatRecieved) {
      scrollToBottom();
    }
  }, [chat, isNewChatRecieved]);

  const { control, handleSubmit, resetField } = useForm<SendMessagePayload>({
    defaultValues: {
      message: "",
      conversationId: id,
    },
  });

  const useSendMessageMutation = useSendMessage();

  const submitHandler = (data: SendMessagePayload) => {
    const formData = new FormData();
    formData.append("conversationId", data?.conversationId);
    formData.append("message", data?.message);

    for (let i = 0; i < data?.attachment?.length; i++) {
      formData.append("attachment", data?.attachment[i]);
    }

    useSendMessageMutation.mutateAsync(formData).finally(() => {
      resetField("message");
      resetField("attachment");
    });
  };

  return (
    <CardUI className="h-full w-full flex flex-col gap-4">
      <div className="flex gap-4 items-center">
        <BsChatDots size={24} />
        <Typography>Chat</Typography>
      </div>

      <Card
        sx={{
          maxHeight: "300px",
          boxShadow: "0",
          padding: "16px",
          border: "2px solid",
          borderColor: "divider",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          overflow: "auto",
          overflowX: "hidden",
          scrollbarWidth: "none!important",
        }}
        ref={messagesEndRef}
        className="hidescrollbar"
      >
        {chat.map((conversation, index) => (
          <MessageItem
            key={index}
            isMyMessage={conversation.sender?.username === user?.data?.username}
            conversation={conversation}
          />
        ))}
      </Card>

      {/* <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={messages?.pages?.[0]?.totalDocs ?? 0}
          next={() => {
            fetchNextPage();
            console.log(
              "api calling for next",
              messages?.pages?.[0]?.totalDocs
            );
          }}
          inverse={true} //
          hasMore={Boolean(hasNextPage)}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {chat.map((conversation, index) => (
            <MessageItem
              key={index}
              isMyMessage={
                conversation.sender?.username === user?.data?.username
              }
              conversation={conversation}
            />
          ))}
        </InfiniteScroll>
      </div> */}

      {useSendMessageMutation?.error?.message && (
        <Alert severity="error" color="error" sx={{ mt: 1 }}>
          {useSendMessageMutation?.error?.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit(submitHandler)}>
        <Controller
          name="message"
          control={control}
          rules={{ required: "Message is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              placeholder="Write your message here"
              multiline
              rows={4}
              helperText={error?.message}
              error={invalid}
            />
          )}
        />

        <div className="flex items-center gap-4 mt-4">
          <LoadingButton
            disabled={chatsLoading || !!chatsError}
            size="small"
            sx={{ px: 3 }}
            loading={useSendMessageMutation.isLoading}
            type="submit"
            variant="contained"
          >
            Send
          </LoadingButton>

          <Controller
            control={control}
            name="attachment"
            render={({ field }) => (
              <InputLabel className="!flex gap-4 items-center">
                <input
                  onChange={(e) => field?.onChange(e?.target?.files)}
                  multiple
                  type="file"
                  hidden
                />
                <div className="flex gap-1 items-center">
                  <AiOutlinePaperClip /> Attach File{" "}
                  {field?.value?.length > 0 ? (
                    <>
                      <BsFillFileImageFill />
                      {field?.value?.length}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </InputLabel>
            )}
          />
        </div>
      </form>
    </CardUI>
  );
};

export default ChatCard;

type MessageItemProps = {
  isMyMessage: boolean;
  conversation: IChat;
};

const MessageItem = ({ conversation, isMyMessage }: MessageItemProps) => {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const handleClose = () => setIsImageOpen(false);

  return (
    <Stack
      direction={isMyMessage ? "row-reverse" : "row"}
      gap={1}
      p={1.5}
      borderRadius={2}
      border={1}
      borderColor="divider"
    >
      <Dialog
        // fullWidth
        open={isImageOpen}
        onClose={handleClose}
        maxWidth={"xl"}
      >
        <DialogContent sx={{ height: "80vh", display: "flex" }}>
          {conversation?.attachment?.map((file, i) => (
            <img key={i} className="rounded object-contain" src={file} alt="" />
          ))}
        </DialogContent>
      </Dialog>

      {/* Avatar */}
      {conversation?.sender?.photo && (
        <Avatar
          variant="circular"
          sx={{ border: "1px solid", borderColor: "primary.main" }}
          alt="Cindy Baker"
          src={conversation?.sender?.photo}
        />
      )}

      {/* Content */}
      <Stack flex={1} alignItems={isMyMessage ? "end" : "start"}>
        <Stack
          direction={isMyMessage ? "row-reverse" : "row"}
          gap={1}
          alignItems="center"
        >
          <InputLabel>{conversation.sender?.username}</InputLabel>
          <Typography variant="caption" color="gray">
            ({defaultDateFormat(conversation.createdAt)})
          </Typography>
        </Stack>

        <Typography
          sx={{
            wordBreak: "break-all",
            a: { color: "primary.main", fontSize: 16 },
          }}
        >
          <span
            dangerouslySetInnerHTML={{ __html: urlify(conversation.content) }}
          />
        </Typography>

        <div className="flex gap-2 mt-2">
          {conversation?.attachment?.map((file, i) => (
            <img
              key={i}
              onClick={() => setIsImageOpen(true)}
              className="h-32 rounded object-cover cursor-pointer"
              src={file}
              alt=""
            />
          ))}
        </div>
      </Stack>
    </Stack>
  );
};

const urlify = (text: string) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, (url) => {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      '<a className="blue" href="' +
      url +
      '" rel="noopener" target="_blank">' +
      url +
      "</a>"
    );
  });
  // or alternatively
};
