import playerBadge from "assets/badge.png";
import aeBadge from "assets/ranks/ae.png";
import eamBadge from "assets/ranks/eam.png";
import jamBadge from "assets/ranks/jam.png";
import samBadge from "assets/ranks/sam.png";
import { RankType } from "queries/rank";
import React, { FC } from "react";

type Props = {
  className?: string;
  rank?: RankType;
};

const RankBadge: FC<Props> = ({ className, rank }) => {
  return (
    <>
      {rank === "sam" && (
        <img className={className} src={samBadge} alt="player" />
      )}
      {rank === "jam" && (
        <img className={className} src={jamBadge} alt="player" />
      )}
      {rank === "eam" && (
        <img className={className} src={eamBadge} alt="player" />
      )}
      {rank === "ae" && (
        <img className={className} src={aeBadge} alt="player" />
      )}
      {!rank && <img className={className} src={playerBadge} alt="player" />}
    </>
  );
};
export default RankBadge;
