import { SectionTitle } from "components/title";
import { useMyCommissionOverviewQuery } from "queries/agent/my_commission_query";
import { useState } from "react";
import CommissionHistory from "./components/CommissionHistory";
import CutoffHistory from "./components/CutoffHistory";
import OverviewMyCommissions from "./components/OverviewMyCommissions";
import { Tab } from "./components/Tabs";

const UserMyCommissionPage = () => {
  const { isLoading, data } = useMyCommissionOverviewQuery();

  const [tab, setTab] = useState<Tab>("commission");
  const onChangeTab = (tab: Tab) => setTab(tab);

  return (
    <>
      <SectionTitle.Default title="My Commissions" />
      <div className="h-4" />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <OverviewMyCommissions
          isLoading={isLoading}
          cw={data?.cw}
          cl={data?.cl}
          lastCutOffDate={data?.lastCutOff}
          nextCutOffDate={data?.nextCutOff}
        />
      </div>
      <div className="h-8" />
      {tab === "commission" && (
        <CommissionHistory tab={tab} onChangeTab={onChangeTab} />
      )}
      {tab === "cut-off" && (
        <CutoffHistory tab={tab} onChangeTab={onChangeTab} />
      )}
    </>
  );
};

export default UserMyCommissionPage;
