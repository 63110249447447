import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import InviteSection from "routes/agent/my_commission/components/InviteSection";

type ModalProps = {};

export default NiceModal.create<ModalProps>(({}) => {
  const modal = useModal();

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogContent>
        <InviteSection />
      </DialogContent>
    </Dialog>
  );
});
