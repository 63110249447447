import axios from "axios";
import { privateRequest, publicRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_USER } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface ICasinoGames {
  category: string;
  freerounds_supported: boolean;
  gamename: string;
  has_jackpot: boolean;
  id: string;
  id_hash: string;
  id_hash_parent: string;
  id_parent: number;
  image: string;
  image_background: string;
  image_bw: string;
  image_filled: string;
  image_preview: string;
  licence: string;
  mobile: true;
  name: string;
  new: string;
  play_for_fun_supported: boolean;
  plays: string;
  position: string;
  report: string;
  rtp: string;
  subcategory: string;
  system: string;
  type: string;
  isHot?: "yes" | undefined;
}

interface ICasinoGamesResp {
  docs: ICasinoGames[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  offset: number;
  page: number;
  pagingCounter: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export const useCasinoGames = (query: string) => {
  return useQuery<ICasinoGames[], Error>(
    [QUERY_KEYS.casinoGames, query],
    async () => {
      try {
        const resp = await publicRequest.get(
          API_ENDPOINTS_USER.gameList + query
        );
        return resp?.data?.data?.docs;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!query,
    }
  );
};

export const useGamesAll = (query: string) => {
  return useInfiniteQuery<ICasinoGamesResp, Error>(
    [QUERY_KEYS.allGamesList, query],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await publicRequest.get(
          `${API_ENDPOINTS_USER.gameList}?page=${pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      enabled: !!query,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.prevPage,
    }
  );
};

export interface PurchaseRequestPayload {
  amount: number;
  note: string;
}

interface PurchaseRequestResp {
  message: string;
}

export const usePurchaseRequest = (query: string) => {
  return useMutation<PurchaseRequestResp, Error, PurchaseRequestPayload>(
    async (payload) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_USER.gameCreditPurchase,
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.gameCreditHistory);
        queryClient.invalidateQueries("fetch-transaction-history");
      },
    }
  );
};

type IGameCreditHistory = {
  _id: string;
  txid: string;
  currencyCode: string;
  amount: number;
  note: string;
  status: string;
  createdAt: string;
  id: string;
};

interface IGameCreditHistoryResp {
  docs: IGameCreditHistory[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
}

export const useGameCreditHistory = (query?: string, page?: number) => {
  return useInfiniteQuery<IGameCreditHistoryResp, Error>(
    [QUERY_KEYS.gameCreditHistory, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          API_ENDPOINTS_USER.gameCreditHistory +
            `?page=${page ?? pageParam}` +
            query ?? ""
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

export interface IGameResp {
  currency: string;
  error: number;
  gamesession_id: string;
  response: string;
  sessionid: string;
  message?: string;
}
export interface IGamePayload {
  user_id?: string;
  gameid: string;
  method: string;
  homeUrl?: string;
  user_password: string;
  lang: string;
  user_username: string;
  currency: string;
}

interface IParams {
  onSuccess: Function;
}

export const useGame = (params: IParams) => {
  return useMutation<IGameResp, Error, IGamePayload>(
    async (payload) => {
      try {
        const resp = await axios.post(
          process.env.REACT_APP_GAME_URL ?? "",
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        params.onSuccess && params.onSuccess(data);
      },
    }
  );
};

interface ILogoutGamePlayerResp {
  message: string;
}
interface ILogoutGamePlayerPayload {
  currency: string;
  user_id: string;
  method: string;
  user_password: string;
  user_username: string;
}

export const useLogoutGamePlayer = () => {
  return useMutation<ILogoutGamePlayerResp, Error, ILogoutGamePlayerPayload>(
    async (payload) => {
      try {
        const resp = await axios.post(
          process.env.REACT_APP_GAME_URL ?? "",
          payload
        );
        return resp?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};
