import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { TransactionHistoryData } from "interfaces/agent/transaction";
import { useInfiniteQuery, useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

interface IWallet {
  name: string;
  currencyCode: string;
  total: string;
  freeze: string;
  available: string;
  equivalentAmount: string;
  scopes: {
    withdraw: { enabled: boolean };
    deposit: { enabled: boolean };
    swap: { enabled: boolean };
  };
}

interface ITransactionResp {
  docs: TransactionHistoryData[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number;
  nextPage: null | number;
}

export const useWalletInformation = () => {
  return useQuery<IWallet[], Error>("fetch-wallet-information", async () => {
    try {
      const resp = await privateRequest.get("wallet/information");
      return resp?.data?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};

export const useTransactionHistory = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.wallet.transactionHistory;

  return useInfiniteQuery<ITransactionResp, Error>(
    [QUERY_KEYS.transactionHistory, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}${query}`
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};
