import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CardUI } from "components/card";
import { SectionTitle } from "components/title";
import SpaceBar from "components/uiStyle/spaceBar";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";
import { RiCoinLine } from "react-icons/ri";
import QRCode from "react-qr-code";

const UserDepositPage = () => {
  const refLink = "bac";

  return (
    <div>
      <CardUI className="flex flex-col gap-4 lg:max-w-2xl">
        <SectionTitle.Default title="Deposit" />

        <div className="h-8" />

        <InputLabel id="currency">Preferred Currency</InputLabel>

        <FormControl>
          <Select
            defaultValue=""
            displayEmpty
            // value={asset ?? ""}
            // onChange={handleChange}
          >
            <MenuItem value="">
              <div className="flex items-center gap-2 uppercase">
                <RiCoinLine /> None
              </div>
            </MenuItem>
            {["btc", "eth", "usdt"].map((item, index) => (
              <MenuItem key={index} value={item}>
                <div className="flex items-center gap-2 uppercase">
                  <RiCoinLine /> {item}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <SpaceBar.TitleGap />

        <div className="self-center border-2 p-4">
          <QRCode
            value={refLink}
            size={200}
            fgColor={"black"}
            bgColor={"white"}
          />
        </div>

        <InputLabel id="wallet_address">Wallet Address</InputLabel>
        <div className="w-full flex items-center gap-2">
          <TextField fullWidth value={refLink} />
          <IconButton
            onClick={() => {
              copy(refLink);
              toast.success("copied to your clipboard");
            }}
          >
            <FiCopy />
          </IconButton>
        </div>

        <SpaceBar.TitleGap />

        <Button variant="contained">Done</Button>
      </CardUI>
    </div>
  );
};

export default UserDepositPage;
