import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import ga from "assets/icons/ga.png";
import React from "react";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import setGoogleAuthenticatorStepTwo from "./setGoogleAuthenticatorStepTwo";
import StepperGA from "./stepper";

type Props = {
  secret: string;
  button_text: string;
  switch: "enable" | "disable" | "set" | "unset";
};

export default NiceModal.create((props: Props) => {
  const modal = useModal();
  const nextModal = useModal(setGoogleAuthenticatorStepTwo);

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <div className="px-12">
          <div className="flex flex-col items-center mb-4">
            <img className="w-16" src={ga} alt="ga" />
            <Typography
              sx={{ mt: 2 }}
              color="primary"
              fontSize={18}
              fontWeight={500}
            >
              Enable Google Authenticator
            </Typography>
          </div>

          <StepperGA activeStep={0} />

          <Typography align="center" sx={{ my: { xs: 2, lg: 5 } }}>
            Download and install the Google Authenticator app
          </Typography>
          <div className="flex justify-around gap-10">
            <div className="flex flex-col items-center gap-3">
              <AiFillApple size={40} />
              <div className="text-center">
                Download from <br />
                <a
                  href="https://www.apple.com/app-store/"
                  className="text-primary"
                >
                  App Store
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center gap-3">
              <FaGooglePlay size={40} />
              <div className="text-center">
                Download from <br />
                <a
                  href="https://play.google.com/store/games?hl=en&gl=US"
                  className="text-primary"
                >
                  Google Play
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-around gap-10 mt-20">
            <Button
              onClick={() => modal.hide()}
              color="info"
              variant="contained"
              fullWidth
              size="small"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                modal.remove();
                nextModal.show();
              }}
              color="primary"
              variant="contained"
              fullWidth
              size="small"
            >
              Next
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
