import SpaceBar from "components/uiStyle/spaceBar";
import PlayGamePage from "../play";
import Overviews from "./components/overviews";

interface Props {}
const UserDashbaord = (props: Props) => {
  return (
    <>
      <Overviews />
      <SpaceBar.SectionGap />
      <PlayGamePage title="Play" type="" isHot />
      {/* <SpaceBar.SectionGap />
      <RecentTransaction /> */}
    </>
  );
};

export default UserDashbaord;
