import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { TransactionMyCommission } from "interfaces/agent/transaction";
import { useInfiniteQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

import { useQuery } from "react-query";

interface IMyCommissionOverview {
  cw: number;
  cl: number;
  nextCutOff: Date;
  lastCutOff: Date;
}

export const useMyCommissionOverviewQuery = () => {
  return useQuery<IMyCommissionOverview, Error>(
    QUERY_KEYS.myCommissionOverview,
    async () => {
      try {
        const resp = await privateRequest.get(
          API_ENDPOINTS_AGENT.myCommission.overview
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};

interface ITransactionResp {
  docs: TransactionMyCommission[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
}

export const useMyCommissionHistoryQuery = ({
  query = "",
  page,
}: {
  query?: string;
  page?: number;
}) => {
  const url = API_ENDPOINTS_AGENT.myCommission.history;
  return useInfiniteQuery<ITransactionResp, Error>(
    [QUERY_KEYS.transactionHistory, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};
interface ICutOffHistoryResp {
  docs: ICuttOffTransaction[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
}

interface ICuttOffTransaction {
  createdAt: Date;
  _id: string;
  txid: string;
  user: {
    _id: string;
    name: string;
    username: string;
    photo: string;
    rank: string;
    email: string;
  };
  cw: number;
  cl: number;
  carry: number;
  commissionPayout: number;
}

export const useCutOffHistoryQuery = ({
  query = "",
  page,
}: {
  query?: string;
  page?: number;
}) => {
  const url = API_ENDPOINTS_AGENT.myCommission.cutoffHistory;
  return useInfiniteQuery<ICutOffHistoryResp, Error>(
    [QUERY_KEYS.cutoffHistory, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};
