import { publicRequest } from "config/axios.config";
import { API_ENDPOINTS_AUTH } from "constants/api_endpoints";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

// Create Reset Password Request mutation:
export interface CreateResetPasswordPayload {
  email: string;
}
interface CreateResetPasswordResp {
  status: string;
  message: string;
  traceId?: string;
}

export const useCreateResetPasswordMutation = () => {
  return useMutation<
    CreateResetPasswordResp,
    Error,
    CreateResetPasswordPayload
  >(
    async (payload) => {
      try {
        const resp = await publicRequest.post(
          API_ENDPOINTS_AUTH.resetPassword.createResetPasswordCode,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERY_KEYS.rankUpgradeRequestHistory);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
export interface VerifyResetPasswordPayload {
  emailVerifyCode: string;
  traceId: string;
}
interface VerifyResetPasswordResp {
  status: string;
  message: string;
  token?: string;
}

export const useVerifyResetPasswordMutation = () => {
  return useMutation<
    VerifyResetPasswordResp,
    Error,
    VerifyResetPasswordPayload
  >(
    async (payload) => {
      try {
        const resp = await publicRequest.post(
          API_ENDPOINTS_AUTH.resetPassword.verifyPasswordReset,
          payload
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {},
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
export interface SubmitResetPasswordPayload {
  password: string;
}
interface SubmitResetPasswordResp {
  status: string;
  message: string;
  token?: string;
}

export const useSubmitResetPasswordMutation = (token: string) => {
  return useMutation<
    SubmitResetPasswordResp,
    Error,
    SubmitResetPasswordPayload
  >(
    async (payload) => {
      try {
        const resp = await publicRequest.post(
          API_ENDPOINTS_AUTH.resetPassword.submitPasswordReset,
          payload,
          { headers: { Authorization: "Bearer " + token } }
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
