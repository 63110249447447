import { Typography } from "@mui/material";
import Logo from "assets/icons/logo-dark.png";
import { CardUI } from "components/card";
import React from "react";

const UserSwapPage = () => {
  return (
    <div className="w-full">
      <div className="h-0 md:h-32" />

      <CardUI
        className="max-w-xl mx-auto"
        bgColor="linear-gradient(180deg, #0D0D0D 0%, #02300C 92.7%)"
      >
        <div className="mx-auto my-32 flex flex-col w-[270px]">
          <img width={150} src={Logo} alt="logo" />
          <Typography
            textAlign="center"
            variant="h1"
            color="primary"
            fontWeight={500}
            textTransform="uppercase"
          >
            Swap
          </Typography>

          <Typography color="white" className="self-end">
            Coming Soon
          </Typography>
        </div>
      </CardUI>
    </div>
  );
};

export default UserSwapPage;
