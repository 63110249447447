import NiceModal, { useModal } from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Dialog,
  DialogContent,
  FormControl,
  FormLabel as InputLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import {
  RequestToChangeEmailResp,
  useRequestToChangeEmail,
} from "queries/security";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import changeEmailVerify from "./changeEmailVerify";
// import toast from "react-hot-toast";

interface IChangeEmailFormFields {
  newEmail: string;
  password: string;
}

export default NiceModal.create(() => {
  const modal = useModal();
  const changeEmailVerifyModal = useModal(changeEmailVerify);
  const { user } = useContext(AuthContext);
  const [showPass, setShowPass] = useState(false);
  const { control, handleSubmit, getValues } = useForm<IChangeEmailFormFields>({
    defaultValues: {
      newEmail: user?.data?.email,
    },
  });
  const useRequestToChangeEmailMutation = useRequestToChangeEmail({
    onSucces: (data: RequestToChangeEmailResp) => {
      toast.success(data?.message ?? "Operation successful");
      changeEmailVerifyModal.show({
        traceId: data?.traceId,
        email: getValues("newEmail"),
      });
      modal.remove();
    },
  });
  const formSubmit = (data: IChangeEmailFormFields) => {
    useRequestToChangeEmailMutation.mutate(data);
  };
  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <Typography color="primary" fontSize={18} fontWeight={500}>
          Change Email
        </Typography>
        <div className="h-8"></div>
        <form onSubmit={handleSubmit(formSubmit)}>
          <InputLabel>Enter New Email</InputLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="newEmail"
            rules={{ required: "Email is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
          <div className="h-8"></div>
          <InputLabel>Password</InputLabel>
          <div className="h-2"></div>
          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required" }}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl error={invalid} fullWidth>
                <TextField
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  type={showPass ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPass ? (
                          <AiFillEye
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        ) : (
                          <AiFillEyeInvisible
                            onClick={() => setShowPass(!showPass)}
                            size={24}
                            className="cursor-pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
          />
          <div className="h-20"></div>
          <LoadingButton
            loading={useRequestToChangeEmailMutation.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Change
          </LoadingButton>
        </form>
        {useRequestToChangeEmailMutation.isError && (
          <Alert sx={{ mt: 3 }} color="error" severity="error">
            {useRequestToChangeEmailMutation?.error?.message}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
});
