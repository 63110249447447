import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { RankSymbol } from "interfaces/agent/rank";
import { useInfiniteQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface AgentCommissionsResp {
  docs: AgentCommission[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: any;
  nextPage: any;
}

export interface AgentCommission {
  commissionCount: number;
  user: string;
  rank: RankSymbol;
  totalCommission: number;
  currentCommission: number;
  balance: number;
}

export const useAgentCommissionsQuery = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.agentCommissions;
  return useInfiniteQuery<AgentCommissionsResp, Error>(
    [QUERY_KEYS.agentCommissions, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};
