import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import {
  useVerifyResetPasswordMutation,
  VerifyResetPasswordPayload,
} from "queries/auth/reset_password_mutations";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

type Props = {};

const schema = yup.object({
  traceId: yup.string().required("Trace ID is Required"),
  emailVerifyCode: yup.string().required("OTP is Required"),
});

const VerifyPasswordReset = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const traceId = (location.state as { traceId?: string }).traceId;

  const { isLoading, mutateAsync, isSuccess, data } =
    useVerifyResetPasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<VerifyResetPasswordPayload>({
    defaultValues: { traceId: traceId, emailVerifyCode: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: VerifyResetPasswordPayload) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!traceId) {
      navigate(-1);
    }
  }, [navigate, traceId]);

  useEffect(() => {
    if (isSuccess && data?.token) {
      navigate(RouteLinks.SUBMIT_RESET_PASSWORD_REQUEST.link, {
        replace: true,
        state: { token: data.token },
      });
    }
  }, [data?.token, isSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-white">
      <Controller
        control={control}
        name="emailVerifyCode"
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            fullWidth
            placeholder="OTP"
            error={!!errors.emailVerifyCode}
            helperText={errors.emailVerifyCode?.message}
          />
        )}
      />

      <div className="h-4" />

      <LoadingButton
        fullWidth
        loading={isLoading}
        type="submit"
        variant="contained"
        sx={{ "&.Mui-disabled": { backgroundColor: "primary.dark" } }}
      >
        Submit
      </LoadingButton>
    </form>
  );
};

export default VerifyPasswordReset;
