import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import ga from "assets/icons/ga.png";
import { useSwitchGoogleAuthenticationMutation } from "queries/security";
import React, { useState } from "react";
import toast from "react-hot-toast";
import setGoogleAuthenticatorStepTwo from "./setGoogleAuthenticatorStepTwo";
import StepperGA from "./stepper";

type Props = {
  secret: string;
  button_text: string;
  switch: "enable" | "disable" | "set" | "unset";
};

export default NiceModal.create((props: Props) => {
  const modal = useModal();
  const prevModal = useModal(setGoogleAuthenticatorStepTwo);
  const [code, setCode] = useState<string>("");
  const useSwitchGoogleAuthentication = useSwitchGoogleAuthenticationMutation();

  const submitHandler = () => {
    toast.promise(useSwitchGoogleAuthentication.mutateAsync({ otp: code }), {
      loading: "Loading",
      success: (res) => {
        modal.remove();
        return res?.message ?? "Operation successfull";
      },
      error: (ex) => {
        modal.remove();
        return ex.toString() ?? "Something went wrong";
      },
    });
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <div className="px-12">
          <div className="flex flex-col items-center mb-4">
            <img className="w-16" src={ga} alt="ga" />
            <Typography
              sx={{ mt: 2 }}
              color="primary"
              fontSize={18}
              fontWeight={500}
            >
              Enable Google Authenticator
            </Typography>
          </div>

          <StepperGA activeStep={2} />

          <Typography
            align="center"
            fontWeight={500}
            sx={{ my: { xs: 2, lg: 5 } }}
          >
            Finish security verification and enable Google Authenticator
          </Typography>
          <div>
            <FormLabel sx={{ mb: 1, display: "block" }}>
              Enter the 6 Digit Code
            </FormLabel>
            <TextField
              fullWidth
              autoFocus
              value={code}
              onChange={(e) =>
                setCode(e.target.value.replace(/[a-zA-Z,.]/g, ""))
              }
            />
          </div>

          <div className="flex justify-around gap-10 mt-20">
            <Button
              onClick={() => {
                prevModal.show();
                modal.hide();
              }}
              color="info"
              variant="contained"
              fullWidth
              size="small"
            >
              Previous Steps
            </Button>
            <LoadingButton
              loading={useSwitchGoogleAuthentication.isLoading}
              disabled={
                code.length !== 6 || useSwitchGoogleAuthentication.isLoading
              }
              color="primary"
              variant="contained"
              fullWidth
              size="small"
              onClick={submitHandler}
            >
              Enable
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
