import { PaletteOptions } from "@mui/material";

export const PALETTE_DEFAULT: PaletteOptions = {
  mode: "light",
  primary: {
    light: "#4bd74f",
    main: "#1FCE24",
    dark: "#159019",
    contrastText: "#fff",
  },
  secondary: {
    main: "#CABDFF",
    dark: "#8d84b2",
    light: "#d4caff",
    contrastText: "#000",
  },
  background: { default: "#F4F4F4", paper: "#ffffff" },
};
export const PALETTE_DARK: PaletteOptions = {
  mode: "dark",
  primary: {
    light: "#4bd74f",
    main: "#1FCE24",
    dark: "#159019",
    contrastText: "#fff",
  },
  secondary: {
    main: "#CABDFF",
    dark: "#8d84b2",
    light: "#d4caff",
    contrastText: "#000",
  },
  background: {},
};
