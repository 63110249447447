import RequireAuth from "components/auth/RequireAuth";
import { PlayRoutes, RouteGroups, RouteLinks } from "constants/routeLinks";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import Login from "routes/auth/login";
import PageNotFound from "routes/PageNotFound";
import UserDashbaord from "routes/user/dashboard";
import AgentCommissionPage from "./agent/agent_commission";
import AgentManagementPage from "./agent/agent_management";
import AgentToolsPage from "./agent/agent_tools";
import AgentToolsEditPage from "./agent/agent_tools/edit";
import AgentToolsShowPage from "./agent/agent_tools/show";
import AgentToolsStorePage from "./agent/agent_tools/store";
import AgentUpgradeHistoryPage from "./agent/agent_upgrade_history";
import AgentDashbaordPage from "./agent/dashboard";
import GameReportsPage from "./agent/game_reports";
import AgentGcManagementPage from "./agent/gc_management";
import AgentMyCommissionPage from "./agent/my_commission";
import AgentPaymentDetailsPage from "./agent/payment_details";
import AgentPaymentMethodPage from "./agent/payment_method";
import AgentPendingWithdrawalsPage from "./agent/pending_withdrawals";
import AgentPlayerActivitiesPage from "./agent/player_activities";
import AgentPlayerManagementPage from "./agent/player_management";
import AgentTransactionHistoryPage from "./agent/transaction_history";
import AgentWalletPage from "./agent/wallet";
import EmailVerificationPage from "./auth/email_verification";
import Register from "./auth/register";
import ResetPassword from "./auth/reset_password";
import CreateResetPasswordCode from "./auth/reset_password/components/CreateResetPasswordCode";
import SubmitResetPassword from "./auth/reset_password/components/SubmitResetPassword";
import VerifyPasswordReset from "./auth/reset_password/components/VerifyPasswordReset";
import UiTastePage from "./ui_test";
import UserDepositPage from "./user/deposit";
import FaqPage from "./user/faq";
import UserGcRequestPage from "./user/gc_request";
import PlayGamePage from "./user/play";
import LotteryPage from "./user/play/lottery";
import UserProfilePage from "./user/profile";
import SupportPage from "./user/support";
import UserSwapPage from "./user/swap";
import UserToolIndex from "./user/tools";
import UserToolShow from "./user/tools/show";
import TransactionHistoryPage from "./user/transaction_history";
import TransactionHistoryDetailsPage from "./user/transaction_history_details";
import UserWalletPage from "./user/wallet";
import UserWithdrawalsHistory from "./user/withdrawals_history";
import UserWithdrawaRequestPage from "./user/withdraw_request";

interface Props {}

const privateRoute: RouteObject[] = [
  {
    path: "/",
    children: [
      { index: true, element: <Navigate to={RouteGroups.USER.link} /> },
      {
        path: RouteGroups.USER.link,
        element: <RequireAuth />,
        children: [
          { index: true, element: <Navigate to={RouteLinks.DASHBOARD.link} /> },
          { path: RouteLinks.DASHBOARD.link, element: <UserDashbaord /> },
          { path: RouteLinks.WALLETS.link, element: <UserWalletPage /> },
          {
            path: RouteLinks.WITHDRAWALS.link,
            element: <UserWithdrawalsHistory />,
          },
          {
            path: RouteLinks.GAME_CREDIT_REQUEST.link,
            element: <UserGcRequestPage />,
          },
          {
            path: RouteLinks.DEPOSIT.link,
            element: <UserDepositPage />,
            children: [{ path: ":asset", element: <UserDepositPage /> }],
          },
          { path: RouteLinks.PROFILE.link, element: <UserProfilePage /> },
          {
            path: PlayRoutes.LIVE_CASINO.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.LIVE_CASINO.label}
                type={PlayRoutes.LIVE_CASINO.link}
              />
            ),
          },
          {
            path: PlayRoutes.POKER.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.POKER.label}
                type={PlayRoutes.POKER.link}
              />
            ),
          },
          {
            path: PlayRoutes.VIRTUAL_SPORTS.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.VIRTUAL_SPORTS.label}
                type={PlayRoutes.VIRTUAL_SPORTS.link}
              />
            ),
          },
          {
            path: PlayRoutes.SPORTS_BOOK.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.SPORTS_BOOK.label}
                type={PlayRoutes.SPORTS_BOOK.link}
              />
            ),
          },
          {
            path: PlayRoutes.LIVE_CASINO_TABLE.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.LIVE_CASINO_TABLE.label}
                type={PlayRoutes.LIVE_CASINO_TABLE.link}
              />
            ),
          },
          {
            path: PlayRoutes.VIDEO_SLOTS.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.VIDEO_SLOTS.label}
                type={PlayRoutes.VIDEO_SLOTS.link}
              />
            ),
          },
          {
            path: PlayRoutes.TABLE_GAMES.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.TABLE_GAMES.label}
                type={PlayRoutes.TABLE_GAMES.link}
              />
            ),
          },
          {
            path: PlayRoutes.VIDEO_POKER.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.VIDEO_POKER.label}
                type={PlayRoutes.VIDEO_POKER.link}
              />
            ),
          },
          {
            path: PlayRoutes.VIRTUAL_GAMES.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.VIRTUAL_GAMES.label}
                type={PlayRoutes.VIRTUAL_GAMES.link}
              />
            ),
          },
          {
            path: PlayRoutes.SCRATCH_CARDS.link,
            element: (
              <PlayGamePage
                title={PlayRoutes.SCRATCH_CARDS.label}
                type={PlayRoutes.SCRATCH_CARDS.link}
              />
            ),
          },
          { path: RouteLinks.LOTTERY.link, element: <LotteryPage /> },
          { path: RouteLinks.SWAP.link, element: <UserSwapPage /> },
          { path: RouteLinks.FAQ.link, element: <FaqPage /> },
          { path: RouteLinks.TOOLS.link, element: <UserToolIndex /> },
          { path: RouteLinks.TOOLS_SHOW.link, element: <UserToolShow /> },
          { path: RouteLinks.SUPPORT.link, element: <SupportPage /> },
          // TODO: Rank is disabled for now
          // { path: RouteLinks.RANK.link, element: <RankPage /> },
          {
            path: RouteLinks.WITHDRAW_REQUEST.link,
            element: <UserWithdrawaRequestPage />,
            children: [
              { path: ":currencyCode", element: <UserWithdrawaRequestPage /> },
            ],
          },
          {
            path: RouteLinks.TRANSACTION_HISTORY.link,
            element: <TransactionHistoryPage />,
            children: [
              { path: ":criteria", element: <TransactionHistoryPage /> },
            ],
          },
          {
            path: RouteLinks.TRANSACTION_HISTORY_DETAILS.link,
            element: <TransactionHistoryDetailsPage />,
            children: [
              { path: ":id", element: <TransactionHistoryDetailsPage /> },
            ],
          },
          // test page - will be delete later
          { path: "ui-test", element: <UiTastePage /> },
        ],
      },
      {
        path: RouteGroups.AGENT.link,
        element: <RequireAuth />,
        children: [
          { index: true, element: <Navigate to={RouteLinks.DASHBOARD.link} /> },
          { path: RouteLinks.DASHBOARD.link, element: <AgentDashbaordPage /> },
          { index: true, element: <Navigate to={RouteLinks.DASHBOARD.link} /> },
          { path: RouteLinks.DASHBOARD.link, element: <AgentDashbaordPage /> },
          { path: RouteLinks.WALLETS.link, element: <AgentWalletPage /> },
          // { path: RouteLinks.WALLETS.link, element: <UserWalletPage /> },
          {
            path: RouteLinks.PLAYER_MANAGEMENT.link,
            element: <AgentPlayerManagementPage />,
          },
          {
            path: RouteLinks.PLAYER_ACTIVITIES.link,
            element: <AgentPlayerActivitiesPage />,
          },
          {
            path: RouteLinks.AGENT_MANAGEMENT.link,
            element: <AgentManagementPage />,
          },
          // Temprory disabled now
          // {
          //   path: RouteLinks.AGENT_USERS_ACTIVITY.link,
          //   element: <AgentUsersActivityPage />,
          // },

          // Temprory disabled now
          // {
          //   path: RouteLinks.AGENT_UPGRADE_REQUESTS.link,
          //   element: <AgentUpgradeRequestPage />,
          // },
          {
            path: RouteLinks.AGENT_UPGRADE_HISTORY.link,
            element: <AgentUpgradeHistoryPage />,
          },
          {
            path: RouteLinks.PENDING_WITHDRAWALS.link,
            element: <AgentPendingWithdrawalsPage />,
          },
          {
            path: RouteLinks.AGENT_PAYMENT_DETAILS.link,
            element: <AgentPaymentDetailsPage />,
            children: [{ path: ":id", element: <AgentPaymentDetailsPage /> }],
          },
          {
            path: RouteLinks.PAYMENT_METHOD.link,
            element: <AgentPaymentMethodPage />,
          },
          {
            path: RouteLinks.TRANSACTION_HISTORY.link,
            element: <AgentTransactionHistoryPage />,
          },
          {
            path: RouteLinks.GC_MANAGEMENT.link,
            element: <AgentGcManagementPage />,
          },
          {
            path: RouteLinks.MY_COMMISSION.link,
            element: <AgentMyCommissionPage />,
          },
          {
            path: RouteLinks.TOOLS.link,
            element: <AgentToolsPage />,
          },
          {
            path: RouteLinks.TOOLS_CREATE.link,
            element: <AgentToolsStorePage />,
          },
          {
            path: RouteLinks.TOOLS_EDIT.link,
            element: <AgentToolsEditPage />,
          },
          {
            path: RouteLinks.TOOLS_SHOW.link,
            element: <AgentToolsShowPage />,
          },
          { path: RouteLinks.PROFILE.link, element: <UserProfilePage /> },
          { path: RouteLinks.GAME_REPORTS.link, element: <GameReportsPage /> },
          {
            path: RouteLinks.AGENT_COMMISSION.link,
            element: <AgentCommissionPage />,
          },
          // test page - will be delete later
          { path: "ui-test", element: <UiTastePage /> },
        ],
      },
      { path: RouteLinks.LOGIN.link, element: <Navigate to="/" /> },
      { path: RouteLinks.SIGNUP.link, element: <Navigate to="/" /> },
      { path: RouteLinks.RESET_PASSWORD.link, element: <Navigate to="/" /> },
      {
        path: RouteLinks.EMAIL_VERIFICATION.link,
        element: <Navigate to="/" />,
      },

      { path: "*", element: <PageNotFound /> },
    ],
  },
];

const publicRoute: RouteObject[] = [
  { path: RouteLinks.LOGIN.link, element: <Login /> },
  { path: RouteLinks.SIGNUP.link, element: <Register /> },
  {
    path: RouteLinks.RESET_PASSWORD.link,
    element: <ResetPassword />,
    children: [
      {
        index: true,
        element: <Navigate to={RouteLinks.RESET_PASSWORD_REQUEST.link} />,
      },
      {
        path: RouteLinks.RESET_PASSWORD_REQUEST.link,
        element: <CreateResetPasswordCode />,
      },
      {
        path: RouteLinks.VERIFY_RESET_PASSWORD_REQUEST.link,
        element: <VerifyPasswordReset />,
      },
      {
        path: RouteLinks.SUBMIT_RESET_PASSWORD_REQUEST.link,
        element: <SubmitResetPassword />,
      },
    ],
  },
  {
    path: RouteLinks.EMAIL_VERIFICATION.link,
    element: <EmailVerificationPage />,
  },
  { path: "*", element: <Navigate to={RouteLinks.LOGIN.link} replace /> },
];

const Routes = (props: Props) => {
  const auth = useContext(AuthContext);

  const [routes, setRoutes] = useState<RouteObject[]>(
    auth.token ? privateRoute : publicRoute
  );

  // change routes on token state
  useEffect(() => {
    setRoutes(auth.token ? privateRoute : publicRoute);
  }, [auth.token]);

  const appRoutes = useRoutes(routes);

  if (auth.token) {
    return <div>{appRoutes}</div>;
  }

  return <div>{appRoutes}</div>;
};

export default Routes;
