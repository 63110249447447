import TransactionHistoryDetailsWraper from "components/transactionDetailsSocketWrapper";
import { useParams } from "react-router-dom";
import PaymentStatusCard from "routes/agent/payment_details/components/PaymentStatusCard";

export default function AgentPaymentDetailsPage() {
  const { id } = useParams();
  return (
    <TransactionHistoryDetailsWraper id={id ?? ""}>
      {({ transactionDetails }) => (
        <PaymentStatusCard data={transactionDetails} />
      )}
    </TransactionHistoryDetailsWraper>
  );
}
