import { privateRequest } from "config/axios.config";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

interface ISupportResp {
  message: string;
}
export interface ISupportPayload {
  email: string;
  subject: string;
  message: string;
}

export const useSupport = () => {
  return useMutation<ISupportResp, Error, ISupportPayload>(async (payload) => {
    try {
      const resp = await privateRequest.post("contactUs", payload);
      return resp?.data;
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
};
