import { Button } from "@mui/material";
import { FC } from "react";

export type Tab = "commission" | "cut-off";

type TabsProps = {
  tab: Tab;
  onChangeTab: (tab: Tab) => void;
};

const Tabs: FC<TabsProps> = ({ tab, onChangeTab }) => {
  return (
    <div className="flex-1 flex gap-4">
      <Button
        variant="contained"
        sx={{
          color: tab === "commission" ? "" : "primary.main",
          backgroundColor: tab === "commission" ? "" : "background.default",
          ":hover": {
            color: "white",
          },
        }}
        onClick={() => onChangeTab("commission")}
      >
        CW/CL Transaction History
      </Button>
      <Button
        variant="contained"
        sx={{
          color: tab === "cut-off" ? "" : "primary.main",
          backgroundColor: tab === "cut-off" ? "" : "background.default",
          ":hover": {
            color: "white",
          },
        }}
        onClick={() => onChangeTab("cut-off")}
      >
        Cut Off History
      </Button>
    </div>
  );
};

export default Tabs;
