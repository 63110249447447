import queryString from "query-string";
import { useEffect, useState } from "react";
import GcHistory from "./components/GcHistory";
import RequestGC from "./components/RequestGC";

export type PurchaseHistoryStatusType =
  | "pending"
  | "approved"
  | "declined"
  | "";

const UserGcRequestPage = () => {
  const [status, setStatus] = useState<PurchaseHistoryStatusType>("");
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (status) {
      parsed.status = status;
    } else {
      delete parsed.status;
    }

    const uri = queryString.stringify(parsed);
    if (uri) {
      setQuery("&" + uri);
    } else {
      setQuery("");
    }
  }, [query, status]);

  return (
    <>
      <RequestGC query={query} />

      <div className="h-8" />

      <GcHistory query={query} setStatus={setStatus} status={status} />
    </>
  );
};

export default UserGcRequestPage;
