import { TableCell } from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { TransactionType } from "interfaces/agent/transaction";
import { FC, useContext } from "react";

type TransactionValueCellProps = {
  type?: TransactionType | undefined | "win" | "loss";
  data: {
    amount: number;
    equivalentAmount?: number;
    [key: string]: any;
  };
  align?: "left" | "center" | "right";
};

const TransactionValueCell: FC<TransactionValueCellProps> = ({
  type,
  data,
  align = "right",
}) => {
  const { centralAmount } = useContext(AuthContext);
  return (
    <TableCell
      align={align}
      sx={{
        color:
          type && (type === "credit" || type === "win")
            ? "success.main"
            : type && (type === "debit" || type === "loss")
            ? "error.main"
            : "",
        whiteSpace: "nowrap",
      }}
    >
      {type && type === "credit" && "+"}
      {type && type === "debit" && "-"} {centralAmount(data)}
    </TableCell>
  );
};

export default TransactionValueCell;
