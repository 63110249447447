import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import ga from "assets/icons/ga.png";
import { AuthContext } from "context/AuthContext";
import copy from "copy-to-clipboard";
import { useGoogleAuthenticationSecret } from "queries/security";
import React, { useContext } from "react";
import toast from "react-hot-toast";
import { BiCopy } from "react-icons/bi";
import QRCode from "react-qr-code";
import { getQrStr } from "utils";
import { companyName } from "utils/urls";
import setGoogleAuthenticatorStepOne from "./setGoogleAuthenticatorStepOne";
import setGoogleAuthenticatorSwitch from "./setGoogleAuthenticatorSwitch";
import StepperGA from "./stepper";

type Props = {
  secret: string;
  button_text: string;
  switch: "enable" | "disable" | "set" | "unset";
};

export default NiceModal.create((props: Props) => {
  const modal = useModal();
  const { user } = useContext(AuthContext);
  const prevModal = useModal(setGoogleAuthenticatorStepOne);
  const nextModal = useModal(setGoogleAuthenticatorSwitch);
  const { data } = useGoogleAuthenticationSecret();
  let dataForQRcode = getQrStr(
    companyName,
    user?.data?.email ?? "",
    data?.secret ?? ""
  );

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogContent>
        <div className="px-12">
          <div className="flex flex-col items-center mb-4">
            <img className="w-16" src={ga} alt="ga" />
            <Typography
              sx={{ mt: 2 }}
              color="primary"
              fontSize={18}
              fontWeight={500}
            >
              Enable Google Authenticator
            </Typography>
          </div>

          <StepperGA activeStep={1} />

          <Typography
            align="center"
            fontWeight={500}
            sx={{ my: { xs: 2, lg: 5 } }}
          >
            Scan this QR code in the Google Authenticator app
          </Typography>
          {dataForQRcode && (
            <div className="flex flex-col items-center">
              <QRCode size={150} value={dataForQRcode} />
              <Typography
                fontWeight={500}
                align="center"
                sx={{
                  color: "primary.main",
                  mt: 2,
                }}
              >
                {data?.secret}
                <IconButton
                  color="primary"
                  onClick={() => {
                    copy(data?.secret ?? "");
                    toast.success("Successfully copied to your clipboard.");
                  }}
                >
                  <BiCopy />
                </IconButton>
              </Typography>
            </div>
          )}

          <Typography align="center" sx={{ my: { xs: 2, lg: 5 } }}>
            If you are unable to scan the QR code, please enter this code
            manually into the app.
          </Typography>

          <div className="flex justify-around gap-10 mt-20">
            <Button
              onClick={() => {
                prevModal.show();
                modal.hide();
              }}
              color="info"
              variant="contained"
              fullWidth
              size="small"
            >
              Previous Steps
            </Button>
            <Button
              onClick={() => {
                modal.hide();
                nextModal.show();
              }}
              color="primary"
              variant="contained"
              fullWidth
              size="small"
            >
              Next
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
