import { Button, Collapse, Paper, SxProps, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

type Props = {
  title: string;
  desc: React.ReactNode;
  sx?: SxProps;
};

const FaqItem: FC<Props> = ({ title, desc, sx }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Paper variant="outlined" sx={sx}>
      <Button
        variant={isOpen ? "contained" : "outlined"}
        color="primary"
        size="large"
        fullWidth
        onClick={toggleIsOpen}
        sx={{ textTransform: "capitalize" }}
      >
        {title}
        {isOpen ? (
          <BsChevronUp className="ml-auto" />
        ) : (
          <BsChevronDown className="ml-auto" />
        )}
      </Button>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Typography sx={{ p: 3 }}>{desc}</Typography>
      </Collapse>
    </Paper>
  );
};

export default FaqItem;
