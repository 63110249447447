const InputStyle = {
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "45px",
    background: "#F4F4F4",
    borderRadius: "10px",
    overflow: "hidden",
    "~p": {
      ml: 0,
    },
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "10px",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const InputStyleTwo = {
  height: "45px",
  background: "#F4F4F4",
  borderRadius: "10px",
  overflow: "hidden",
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "100%",
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "10px",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const InputStyleThree = {
  height: "45px",
  background: "#FCFCFC",
  borderRadius: "10px",
  overflow: "hidden",
  fieldset: {
    border: "none",
  },
  ">div": {
    height: "100%",
    "&:hover fieldset": {
      border: "none",
    },
  },
  input: {
    height: "100%",
    padding: "0 10px 0 0",
  },
  ".MuiFormHelperText-root": {
    lineHeight: "18px",
    marginTop: 0,
  },
};

const grayButtonStyle = {
  bgcolor: "#6F767E",
  "&:hover": {
    bgcolor: "#777d85",
  },
};

const labelStyleOne = {
  fontSize: "14px",
  color: "#33383F",
  fontWeight: 600,
  display: "block",
  marginBottom: "12px",
};

export const InputStyles = {
  default: InputStyle,
  secondary: InputStyleTwo,
  third: InputStyleThree,
  noArrow: {
    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "input[type=number]": {
      MozAppearance: "textfield",
    },
  },
};

export const buttonStyle = {
  gray: grayButtonStyle,
};

export const labelStyle = {
  default: labelStyleOne,
};

export const errorStyles = {
  default: {
    color: "#eb6755",
    fontSize: "14px",
    marginTop: "3px",
  },
};

const defaultSelectStyle = {
  control: {
    border: 0,
    boxShadow: "none",
    borderRadius: "8px",
    width: "100%",
  },
  valueContainer: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
};

export const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    ...defaultSelectStyle.control,
    backgroundColor: "#f0f0f0",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    ...defaultSelectStyle.valueContainer,
  }),
};

export const reactSelectDarkStyles = {
  control: (provided: any) => ({
    ...provided,
    ...defaultSelectStyle.control,
    backgroundColor: "#272727",
    color: "#fff",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    ...defaultSelectStyle.valueContainer,
    color: "#fff",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#fff",
    background: "#171717",
    border: "1px solid #222",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    background: state.isSelected ? "#171717" : "#222",
    ":hover": {
      background: "#333",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    background: "rgba(255,255,255,0.1)",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#fff",
  }),
};

export const reactSelectDarkInPopupStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#4a4a4a",
    ...defaultSelectStyle.control,
    color: "#fff",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    ...defaultSelectStyle.valueContainer,
    color: "#fff",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#fff",
    background: "#171717",
    border: "1px solid #222",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    background: state.isSelected ? "#171717" : "#222",
    ":hover": {
      background: "#333",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    background: "rgba(255,255,255,0.1)",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#fff",
  }),
};
