import { Box, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import gradientColor from "constants/gradientColor";
import React from "react";

interface Props {
  isLoading?: boolean;
  title: string;
  number: string;
  numberAlignMent?: "right" | "left";
  children?: React.ReactNode;
  index?: number;
}

export default function OverviewCard({
  isLoading = false,
  children,
  title,
  number,
  index,
  numberAlignMent = "right",
}: Props) {
  if (isLoading) {
    return (
      <Skeleton className="mb-4 lg:mb-6 2xl:mb-9" width={40} height={40} />
    );
  }

  return (
    <Box
      className="rounded-lg shadow-card"
      sx={{
        px: 1.5,
        py: 2,
        background: `linear-gradient(135deg, ${
          gradientColor()[index ?? 0].colors[0]
        } 0%, ${gradientColor()[index ?? 0].colors[1]} 100%);"`,
      }}
    >
      <Typography
        component="p"
        sx={{ color: "primary.contrastText" }}
        variant="subtitle1"
        style={{ marginBottom: 8 }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "primary.contrastText",
          textAlign: numberAlignMent,
          wordBreak: "break-all",
        }}
        variant="h2"
      >
        {number}
      </Typography>
      <div className="h-2"></div>
      {children}
    </Box>
  );
}
