import { Card, Grow, Skeleton, Typography } from "@mui/material";
import { FC } from "react";

type OverviewTotalAgentsProps = {
  isLoading?: boolean;
  totalAgents: number;
  eam?: number;
  sam?: number;
  jam?: number;
  ae?: number;
  players: number;
};

const OverviewTotalAgents: FC<OverviewTotalAgentsProps> = ({
  isLoading,
  totalAgents,
  eam = 0,
  sam = 0,
  jam = 0,
  ae = 0,
  players,
}) => {
  return (
    <Grow in>
      <Card
        elevation={10}
        className="max-w-sm md:max-w-full flex flex-col gap-4 !bg-gradient-to-br from-[#BE6AE6] to-[#1D719F]"
        sx={{ color: "white", p: 3 }}
      >
        <Typography variant="subtitle1">Total Gamers</Typography>

        {isLoading ? (
          <Typography variant="h4">
            <Skeleton variant="text" />
          </Typography>
        ) : (
          <Typography variant="h4" fontWeight={500}>
            {totalAgents}
          </Typography>
        )}

        <section>
          <div className="flex justify-between items-center">
            <Typography variant="subtitle1" fontWeight={400}>
              EAM
            </Typography>

            {isLoading ? (
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={100} />
              </Typography>
            ) : (
              <Typography variant="subtitle1" fontWeight={400}>
                {eam}
              </Typography>
            )}
          </div>
          <div className="flex justify-between items-center">
            <Typography variant="subtitle1" fontWeight={400}>
              SAM
            </Typography>

            {isLoading ? (
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={100} />
              </Typography>
            ) : (
              <Typography variant="subtitle1" fontWeight={400}>
                {sam}
              </Typography>
            )}
          </div>
          <div className="flex justify-between items-center">
            <Typography variant="subtitle1" fontWeight={400}>
              JAM
            </Typography>

            {isLoading ? (
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={100} />
              </Typography>
            ) : (
              <Typography variant="subtitle1" fontWeight={400}>
                {jam}
              </Typography>
            )}
          </div>
          <div className="flex justify-between items-center">
            <Typography variant="subtitle1" fontWeight={400}>
              AE
            </Typography>

            {isLoading ? (
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={100} />
              </Typography>
            ) : (
              <Typography variant="subtitle1" fontWeight={400}>
                {ae}
              </Typography>
            )}
          </div>
          <div className="flex justify-between items-center">
            <Typography variant="subtitle1" fontWeight={400}>
              Players
            </Typography>

            {isLoading ? (
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={100} />
              </Typography>
            ) : (
              <Typography variant="subtitle1" fontWeight={400}>
                {players}
              </Typography>
            )}
          </div>
        </section>
      </Card>
    </Grow>
  );
};

export default OverviewTotalAgents;
