
import { CardUI } from "components/card"
import { useNavigate } from "react-router-dom"
import { useToolsRequestsQuery } from "../../../queries/tools"
import { ToolListSkeleton } from "components/skeletons/tool.skeleton"
import defaultDateFormat from "../../../utils/date_formatter"
import noTools from "assets/no-tools.svg";

const Index = () => {
  let navigate = useNavigate()
  const { data, isLoading } = useToolsRequestsQuery()

  return (
    <div>
      {isLoading ?
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-7 md:gap-8">
          <ToolListSkeleton items={4} />
        </div>
        : null
      }

      {!isLoading && data && !data.length ?
        <div className="text-center w-full">
          <img
            src={noTools}
            alt=""
            className="mx-auto"
          />
        </div>
        : null
      }

      {!isLoading && data && data.length > 0 ?
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-7 md:gap-8">
          {data.map((item, i) =>
            <CardUI
              key={i}
              className="group min-h-[300px] overflow-hidden relative cursor-pointer"
              onClick={() => navigate(`/user/tools/show/${item._id}`, { replace: true })}
            >
              <div className="rounded-md overflow-hidden h-[200px] mb-3">
                <img
                  src={item.banner}
                  alt=""
                  className="w-full min-h-[200px]"
                />
              </div>
              <p className="text-md font-bold">
                {item.title && item.title.length > 30 ? item.title.slice(0, 30) + "..." : item.title}
              </p>
              <small className="mb-2">Posted on: {defaultDateFormat(item.createdAt)}</small>
              <p className="text-sm font-normal">
                {item.description && item.description.length > 30 ? item.description.slice(0, 80) + "..." : item.description}
              </p>
            </CardUI>
          )}
        </div>
        : null
      }
    </div>
  );
};

export default Index;

/* <Grid container>
  <Grid item xs={12} md={6}>
    <CardUI>
      <SectionTitle.Default title="Tools" />
      <div className="flex flex-wrap justify-around py-20 gap-10">
        <div className="flex flex-col items-center">
          <img src={PdfImg} alt="pdf" />
          <Typography>Tools 101 05/03/2022</Typography>
          <Typography>ValoMotive Studios | Electronic </Typography>
        </div>
        <div className="flex flex-col items-center">
          <img src={DocImg} alt="doc" />
          <Typography>Tools 101 05/03/2022</Typography>
          <Typography>ValoMotive Studios | Electronic </Typography>
        </div>
      </div>
    </CardUI>
  </Grid>
</Grid> */
