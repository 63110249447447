import { ITransaction } from "queries/wallets";

type Props = {
  transaction: ITransaction;
};

export default function TransactionDescriptionCell({ transaction }: Props) {
  return (
    <>
      <>
        {transaction?.paymentMethod?.type?.toUpperCase()}
        {transaction?.paymentMethod?.currency && (
          <>
            (
            <span className="text-primary px-1">
              {transaction?.paymentMethod?.currency?.toUpperCase()}
            </span>
            )
          </>
        )}{" "}
        {transaction?.paymentMethod?.type === "crypto"
          ? transaction?.address
          : transaction?.paymentDetails}
      </>
      <br />
      {transaction?.note && `Note: ${transaction?.note}`}
    </>
  );
}
