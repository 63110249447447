import { useModal } from "@ebay/nice-modal-react";
import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DatePicker from "components/input/DatePicker";
import TableSkeleton from "components/skeletons/TableSkeleton";
import NoTransactions from "components/table/NoTransactions";
import TableError from "components/table/TableError";
import TablePagination from "components/table/TablePagination";
import { SectionTitle } from "components/title";
import _ from "lodash";
import {
  DownlineAgent,
  useAgentDownlineListQuery,
} from "queries/agent/agent_management_query";
import queryString from "query-string";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import defaultDateFormat, { dateFormatForSearch } from "utils/date_formatter";
import BlockDialog from "./components/BlockDialog";
import UnblockDialog from "./components/UnblockDialog";
import UpgradeDialog from "./components/UpgradeDialog";

const AgentPlayerManagementPage = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("&role=player"); //Only Players needs to be fetched
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setSearch(event.target.value);

  const debounceChangeSearch = useMemo(() => {
    return _.debounce(onChangeSearch, 500);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query);

    if (search) parsed.search = search;
    else delete parsed.search;

    if (fromDate) parsed.fromDate = dateFormatForSearch(fromDate);
    else delete parsed.fromDate;

    if (toDate) parsed.toDate = dateFormatForSearch(toDate);
    else delete parsed.toDate;

    const uri = queryString.stringify(parsed);
    if (uri) setQuery("&" + uri);
    return () => {
      debounceChangeSearch.cancel();
    };
  }, [debounceChangeSearch, query, search, fromDate, toDate]);

  const { data, isLoading, isError, error } = useAgentDownlineListQuery({
    query: query,
    page: page,
  });

  const hasNoData = data && data?.pages?.[0]?.docs?.length <= 0;

  return (
    <>
      <SectionTitle.Default title="Player Management">
        <div className="flex gap-2 items-center">
          <DatePicker
            name="fromDate"
            placeholder="From: mm/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {" - "}
          <DatePicker
            name="toDate"
            placeholder="To: mm/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
          />
        </div>
        <TextField
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton>
                <BiSearch />
              </IconButton>
            ),
          }}
          placeholder="Search"
          name="search"
          defaultValue={""}
          onChange={debounceChangeSearch}
          type="search"
        />
      </SectionTitle.Default>

      <div className="h-4" />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Joined</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableSkeleton columns={4} rows={10} />}
            {data?.pages.map((page) => {
              return page.docs.map((agent, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{defaultDateFormat(agent.createdAt)}</TableCell>
                    <TableCell>{agent.username}</TableCell>
                    <TableCell>{agent.email}</TableCell>
                    <TableCell align="center">
                      <AgentDownlineActions agent={agent} />
                    </TableCell>
                  </TableRow>
                );
              });
            })}

            {hasNoData && <NoTransactions colSpan={4} />}
            {isError && <TableError colSpan={4} message={error?.message} />}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        count={data?.pages[0].totalPages}
        page={page}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default AgentPlayerManagementPage;

type AgentDownlineActionsProps = {
  agent: DownlineAgent;
};

const AgentDownlineActions: FC<AgentDownlineActionsProps> = ({ agent }) => {
  return (
    <>
      <ActivationAction agent={agent} />
      <span> </span>
      <UpgradeAction agent={agent} />
    </>
  );
};

const UpgradeAction: FC<AgentDownlineActionsProps> = ({ agent }) => {
  const upgradeDialog = useModal(UpgradeDialog);
  return (
    <Chip
      label="Upgrade"
      disabled={agent.upgradableRanks.length < 1}
      clickable
      onClick={() => {
        upgradeDialog.show({ agent: agent });
      }}
      sx={{ backgroundColor: "primary.main", color: "white" }}
    />
  );
};

const ActivationAction: FC<AgentDownlineActionsProps> = ({ agent }) => {
  const blockDialog = useModal(BlockDialog);
  const unblockDialog = useModal(UnblockDialog);
  switch (agent.status) {
    case "activated":
      return (
        <Chip
          clickable
          onClick={() => blockDialog.show({ agent: agent })}
          label="Block"
          color="error"
        />
      );
    case "blocked":
      return (
        <Chip
          clickable
          onClick={() => unblockDialog.show({ agent: agent })}
          label="Unblock"
          color="success"
        />
      );
    case "pending":
      return <Chip label="Pending" color="info" />;
    default:
      return <Chip label={agent.status} color="default" />;
  }
};
