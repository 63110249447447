import { privateRequest } from "config/axios.config";
import { API_ENDPOINTS_USER } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { useQuery } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface IDashboardAnalytics {
  totalWinning: number;
  balances: {
    GC: number;
    WB: number;
    inPlay: number;
  };
  totalGCPurchase: number;
  totalWithdraw: number;
}

export const useDashboardAnalytics = () => {
  return useQuery<IDashboardAnalytics, Error>(
    QUERY_KEYS.dashboardAnalytics,
    async () => {
      try {
        const resp = await privateRequest.get(
          API_ENDPOINTS_USER.dashboardAnalytics
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  );
};
