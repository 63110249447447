import { privateRequest } from "config/axios.config";
import queryClient from "config/queyClient.config";
import { API_ENDPOINTS_AGENT } from "constants/api_endpoints";
import QUERY_KEYS from "constants/query_keys";
import { RankSymbol } from "interfaces/agent/rank";
import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation } from "react-query";
import { axiosErrorHandler } from "utils/errorHandler";

export interface AgentDownlineListResp {
  docs: DownlineAgent[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: any;
  nextPage: any;
}

export interface DownlineAgent {
  _id: string;
  name: string;
  username: string;
  country: string;
  photo: any;
  role: string;
  primaryContact: string;
  isEmailVerified: boolean;
  phone: any;
  dialCode: any;
  isPhoneVerified: boolean;
  phoneVerification: boolean;
  emailVerification: boolean;
  googleAuthentication: boolean;
  status: string;
  sponsor: string;
  email: string;
  referralId: string;
  createdAt: string;
  rank: RankSymbol;
  upgradableRanks: RankSymbol[];
}

export const useAgentDownlineListQuery = ({
  query = "",
  page,
}: { query?: string; page?: number } = {}) => {
  const url = API_ENDPOINTS_AGENT.agentDownlineManagement.downlineList;
  return useInfiniteQuery<AgentDownlineListResp, Error>(
    [QUERY_KEYS.agentDownlineList, query, page],
    async ({ pageParam = 1 }) => {
      try {
        const resp = await privateRequest.get(
          `${url}?page=${page ?? pageParam}` + query
        );
        return resp?.data?.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage,
      getPreviousPageParam: (firstPage) => firstPage.hasPrevPage,
    }
  );
};

// Block agent mutation:
interface IBlockAgentInputs {
  userId: string;
}

interface IBlockAgentResp {
  status: boolean;
  message: string;
}

export const useBlockAgentMutation = () => {
  return useMutation<IBlockAgentResp, Error, IBlockAgentInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.agentDownlineManagement.block,
          paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
      },
    }
  );
};

// Unblock agent mutation:
interface IUnblockAgentInputs {
  userId: string;
}

interface IUnblockAgentResp {
  status: boolean;
  message: string;
}

export const useUnblockAgentMutation = () => {
  return useMutation<IUnblockAgentResp, Error, IUnblockAgentInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.post(
          API_ENDPOINTS_AGENT.agentDownlineManagement.unblock,
          paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
      },
    }
  );
};

// Upgrade agent mutation:
interface IUpgradeAgentInputs {
  userId: string;
  rank: string;
}

interface IUpgradeAgentResp {
  status: boolean;
  message: string;
}

export const useUpgradeAgentMutation = () => {
  return useMutation<IUpgradeAgentResp, Error, IUpgradeAgentInputs>(
    async (paylaod) => {
      try {
        const resp = await privateRequest.patch(
          API_ENDPOINTS_AGENT.agentDownlineManagement.upgrade,
          paylaod
        );
        return resp.data;
      } catch (error) {
        axiosErrorHandler(error);
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.agentDownlineList);
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
