import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { RouteLinks } from "constants/routeLinks";
import {
  CreateResetPasswordPayload,
  useCreateResetPasswordMutation,
} from "queries/auth/reset_password_mutations";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

type Props = {};

const schema = yup.object({
  email: yup.string().email("Invalid Email").required("Email is Required"),
});

const CreateResetPasswordCode = (props: Props) => {
  const navigate = useNavigate();
  const { isLoading, mutateAsync, isSuccess, data } =
    useCreateResetPasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateResetPasswordPayload>({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: CreateResetPasswordPayload) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccess && data?.traceId) {
      navigate(RouteLinks.VERIFY_RESET_PASSWORD_REQUEST.link, {
        replace: true,
        state: { traceId: data.traceId },
      });
    }
  }, [data?.traceId, isSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-white">
      <Controller
        control={control}
        name="email"
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            sx={{
              background: "#fff",
              borderRadius: 1,
              input: { color: "black" },
            }}
            fullWidth
            placeholder="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}
      />

      <div className="h-4" />

      <LoadingButton
        fullWidth
        loading={isLoading}
        type="submit"
        variant="contained"
        sx={{ "&.Mui-disabled": { backgroundColor: "primary.dark" } }}
      >
        Submit
      </LoadingButton>
    </form>
  );
};

export default CreateResetPasswordCode;
